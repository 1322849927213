import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import courseicons from '../../images/photoshop.png'
import Header from '../elements/header'
import enroleImg from '../../images/enroleImg.svg'
import searchIcon from '../../images/searchIcon.svg'
import { StarRating } from '../elements/progressBar'
import { url } from 'inspector'
import Footer from '../elements/Footer'
function FreeTrendingCourse() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [courseDetails] = useState(state)
  const [loadmorebtn,setloadmorebtn] = useState(true)

    const [search, setSearch] = useState('')

    const handletrendCourse = (props: any) => {
        // let url1 = new URL(`/freecoursedetialed/${props.course_name.replace(' ','-')}`)
        navigate(`/free-course-detialed/${props.course_name.replace(/ /g, '-')}`, { state: props })
    }
    const searchresult = () => {
        if (search.length > 0) {
            return courseDetails.filter((obj: any) => obj.course_name.toLowerCase().includes(search.toLowerCase()))
        }
        return courseDetails
    }
    const convertToK = (number: number) => {
        if (number >= 1000) {
            const suffixes = ["", "k", "M", "B", "T"];
            const suffixIndex = Math.floor(Math.log10(number) / 3);
            const abbreviatedNumber = (number / Math.pow(1000, suffixIndex)).toFixed(1);
            return abbreviatedNumber + suffixes[suffixIndex];
        }
        return number.toString();
    }

    const [count, setCount] = useState(9)
    const handleLoad = () => {
        let data_Lenght = courseDetails?.map((item: any) => { return item })
        setCount(data_Lenght.length)
        setloadmorebtn(false)
    }
    useEffect(() => {
    courseDetails .length > count ? setloadmorebtn(true) : setloadmorebtn(false)      
    }, [])
    return (
        <div>
            <Header />
            <p className='mt-5 text-left ml-10 text-[30px] text-[#707070] font-bold'>Trending Free Courses</p>
            <div className='flex mx-auto justify-center items-center'>
                <input placeholder='Search...' className=' mb-5 rounded-[3px] shadow-3xl text-[14px] text-[#036AD1] mt-5 p-2  w-3/6 outline-none placeholder-[#036AD1]' onChange={(e) => setSearch(e.target.value)}></input>
                <img className='bg-[#036AD1] p-2 shadow-3xl  w-[45px] h-[40px]' src={searchIcon} alt='icon' />
            </div>
            {courseDetails?.length <= 0 ? 'No Data' :
                <>
                    <div className='grid grid-cols-3  max-laptop:grid-cols-2 max-mobile:grid-cols-1 p-2'>
                        {searchresult()?.slice(0, count).map((item: any) => {
                            return <div className='' onClick={() => handletrendCourse(item)}>
                                <div className="  items-center my-3  mx-auto  w-[330px] h-[280px] bg-white border border-gray-200 rounded-lg shadow max-mobile:w-[80vw]">
                                    <img className='w-full h-[150px]' src={item.thumbnail.url} alt='course thumbnil' />
                                    <div className='flex px-4 my-3 text-center border-t-2 justify-between rounded-t-3xl'>
                                        <p className=" text-left text-[21px] font-medium text-[#707070] ">{item.course_name}</p>
                                        <p className=' text-[#FFFFFF] mt-2 px-2 text-[11px] p-0.5 h-[20px]  rounded-md bg-[#036AD1]'>Free</p>
                                    </div>
                                    <div className='ml-0 text-left px-4 flex items-center'>
                                        <p className='font-bold text-[12px] text-[#000000]'> {item.star_rating}</p>
                                        <StarRating rating={item.star_rating} />
                                    </div>
                                    <div className='flex justify-between p-3 '>
                                        <p className=' text-[#007AFF] text-[14px] flex'><img className='w-[16px] h-[16px] mr-1' src={enroleImg} alt='enroleicon' /> {convertToK(item.students_enrolled)} Enrolled</p>
                                        <p className=' text-[#007AFF] text-[14px] mr-3'>{item.duration} Hrs</p>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                    {loadmorebtn ? 
                     <button onClick={handleLoad} className="text-white text-center mt-5   w-44  bg-gradient-to-r from-[#E91F00] via-[#E93C00] to-[#E95900]  font-medium rounded-md text-[20px] px-5 py-2.5 mb-2" > 
                     Load More </button> : '' } 
                </>
            }
<Footer/>

        </div>
    )
}

export default FreeTrendingCourse