// import { dateFormat } from "../../Services/CommonFunctions";

import { dateFormat } from "../../../Services/CommonFunctions";

export const for_Login = {
  email: "",
  password: "",
};

export const add_User = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  password: "",
  confirm_password: "",
  // city: "",
  // role_id: "",
  branches: [],
  branch_names: [],
  courses: [],
  course_names: [],
  cities: [],
  city_names: [],
  roles_array: [],
  role_names: [],
  status: "Active",
  course_type: "Paid",
  user_type: "None",
  super_user: false,
};

export const add_branch = {
  ownership: "",
  branch_name: "",
  city: "",
  city_name: "", // additional
  rooms: [""],
  company_name: "",
  company_address: "",
  state: "",
  pincode: "",
  fee_type: "None",
  gstin: "",
  hsn: "",
  igst: "",
  sgst: "",
  cgst: "",
  status: "Active",
};
// type courseMode = any[];

interface Course {
  trending: boolean;
  type: "Paid" | "Free";
  category: null;
  category_name: string;
  city_based_course_fee: Array<{
    city: string[];
    city_id: number[];
    course_fee: string;
    discounted_fee: string;
  }>;
  course_name: string;
  availability: "Instructor Led" | "Self Paced";
  course_mode: ["Classroom" | "Online" | "Hybrid"];
  branches: string[]; // Assuming branches are represented as strings
  branches_name: string[];
  description: string;
  thumbnail: {
    url: string;
    fileName: string;
    key: string;
  };
  duration: string;
  ratings: string;
  students_enrolled: number | null;
  faqs: string[];
  certificate_description: string;
  syllabus: {
    url: string;
    fileName: string;
    key: string;
  };
  material: boolean;
  course_materials: Array<{
    title: string;
    show_percentage: string;
    url: string;
    key: string;
    fileName: string;
  }>;
  videos: Array<{
    title: string;
    link: string;
    duration: string;
    key: string;
    filename: string;
  }>;
  course_features: string[];
  course_feature_names: string[];
  schedule: Array<{
    // Define the properties for the schedule here
  }>; // only for paid
  key?: string; // optional
}

export const add_Course: Course = {
  trending: true,
  type: "Paid",
  category: null,
  category_name: "", //additional
  city_based_course_fee: [
    { city: [], city_id: [], course_fee: "", discounted_fee: "" },
  ],
  // city: "",
  // city_id: "",
  course_name: "",
  // course_fee: "", // only for paid
  // discounted_fee: "", // only for paid
  availability: "Instructor Led", // only for
  course_mode: ["Classroom"],
  // course_mode: ["Online"],
  branches: [], // only for paid
  branches_name: [], // additional
  description: "",
  // thumbnail: "",
  // thumbnail_key: "",
  thumbnail: {
    url: "",
    fileName: "",
    key: "",
  },
  duration: "",
  ratings: "",
  students_enrolled: null,
  faqs: [],
  certificate_description: "",
  syllabus: {
    url: "",
    fileName: "",
    key: "",
  }, // only for paid
  material: true,
  course_materials: [
    { title: "", show_percentage: "", url: "", key: "", fileName: "" },
  ],
  videos: [{ title: "", link: "", duration: "", key: "", filename: "" }],
  course_features: [],
  course_feature_names: [], //additional
  schedule: [], // only for paid
  key: "", // optional
};

export const add_Feature = {
  features: [{ feature: "", icon: "", storage_key: "" }],
};

export const add_hiringCompanies = {
  hiring_companies: [],
};

export const add_pushContent = {
  content_type: "Predefined Push Content",
  sub_type: "External Link",
  title: "",
  technology: [],
  tech_name: [],
  course_completion: "",
  student_status: "",
  branch: [],
  branch_names: [],
  external_link: "",
  thumbnail: {
    url: "",
    key: "",
    fileName: "",
  },
  video: {
    url: "",
    key: "",
    fileName: "",
  },
  text_message: "",
};

export const add_Contact = {
  phone_number: "",
  email: "",
  id: null,
};

// export const add_Post = {
//   title: "",
//   resource_category: [],
//   technology: [],
//   content_type: "External Link",
//   external_link: "",
//   thumbnail: "",
//   video: "",
//   text: "",
// }

export const add_Resource = {
  title: "",
  technologies: [],
  technology_names: [],
  resource_category: null,
  resource_category_name: "",
  video: {
    fileName: "",
    url: "",
    storage_key: "",
  },
  thumbnail: {
    fileName: "",
    url: "",
    storage_key: "",
  },
  external_link: "",
  text_message: "",
  content_type: "External Link",
};

export const add_ResourceCategory = {
  category_name: "",
  thumbnail: "",
  storage_key: "",
  fileName: "",
  order: null,
};

export const add_StudentCourse = {
  created_at: dateFormat(new Date().toISOString().split("T")[0], "YYYY-MM-DD"),
  phone_number: "",
  student_name: "",
  email: "",
  course_id: null,
  course_name: "",
  balance_due: null,
  status: "Unallocated",
  batch_id: null,
  student_id: "",
  course_fee: "",
  duration: null,
  total_fee: null,
  discount: null,
  discount_need: false,
  customization_fee: false,
  cd_comment: "",
  discount_percentage: null,
  comments: "",
  branch_id: null,
  branch_name: "",
  city_id: null,
  city_name: "",
  course_schedule: "",
  course_mode: "",
  expected_start_date: "",
  batch_follow_up: [],
  batch_follow_up_show: [],
  batch_follow_up_date: "",
  placement: true,
  payment_details: [
    {
      payment_type: ["Registration"],
      paid_amount: null,
      payment_date: "",
      mode_of_payment: "RazorPay",
      transaction_id: "",
    },
  ],
  crm_id: "",
  branch_based_GST: {},
  invoice_details: {
    gstin: "",
    name: "",
    address: "",
  },
  number_code: "+91",
  // autoDiscount: false,
};

export const add_Batch = {
  course_id: null, //
  course: "",
  course_mode: "",
  status: "Upcoming", //
  batch_code: "", //
  city_id: null, //
  city: "",
  branch_id: null, //
  branch: "",
  rooms: "", //
  schedule: "", //
  schedule_days: [],
  duration: "", //
  start_date: "", //
  start_time: "", //
  end_time: "", //
  planned_date_of_completion: "", //
  end_date: "", //
  trainer_change_reason: "", // only for edit
  trainer_id: "", //
  trainer_name: "",
  trainer_phone_number: "",
  trainer_email: "",
  current_trainer: true,
  past_trainers: [],
  add_student: [],
  selected_student_list: [],
  batch_payment_status: "", //
  // students: [] //
};

// export const add_Batch = {
//   "course_id": 411,
//   "course": "mobile app",
//   "course_mode": [
//     "Classroom"
//   ],
//   "status": "Upcoming",
//   "batch_code": "B0000006",
//   "city_id": 1,
//   "city": "Chennai",
//   "branch_id": 55,
//   "branch": "Testing_IGST",
//   "rooms": "",
//   "schedule": "Weekday",
//   "schedule_days": [
//     "Monday",
//     "Tuesday",
//     "Wednesday",
//     "Thursday",
//     "Friday"
//   ],
//   "duration": 10,
//   "start_date": "2024-03-14",
//   "start_time": "07:00",
//   "end_time": "08:00",
//   "planned_date_of_completion": "2024-03-27",
//   "end_date": "",
//   "trainer_change_reason": "",
//   "trainer_id": "a55c628e-4969-4658-be26-3ce145867ec8",
//   "trainer_name": "Test Trainer",
//   "trainer_phone_number": "2323232323",
//   "trainer_email": "test_trainer@gmail.com",
//   "current_trainer": true,
//   "past_trainers": [],
//   "add_student": [],
//   "selected_student_list": [],
//   "batch_payment_status": ""
// }

export const add_session = {
  title: "",
  attendees: [],
  trainer_id: "",
  start_time: "",
  end_time: "",
  details: {
    topic: "",
    subtopics: [""],
  },
  session_date: "",
};

export const AddPaymentFollowUp = {
  follow_up_date: "",
  comment: "",
  added_by: "",
  training_id: "",
};

export const performActionData = {
  status: "",
  reason: "",
  nullType: "",
  nullReason: "",
  total_fee: "",
  course: "",
  course_ID: null,
  duration: null, // nly for update
  additional_duration: null,
  fee: null,
  additional_amount: null,
};

export const add_create_job = {
  about_company: "",
  company: "",
  eligibility: "",
  job_description: "",
  key_skills: [],
  last_date_to_apply: "",
  location: "",
  number_of_openings: "",
  position: "",
  salary: "",
  status: "",
  technology: "",
  // updated_at: "",
  // id: "",
  // inserted_at: "",
};

//Shorlisted Job
export const add_shorlisted = {
  interview_details: "",
};

//App Enquiries
export const app_enquiries_status = {
  enquiries_status: "",
};

export const app_enquiries_update = {
  branch_name: "",
};

export const RefundOrAdjustPayment = {
  studentUid: "",
  student: "",
  courseId: null,
  course: "",
  amount: null,
  reason: "",
  mode_of_payment: "",
  // from_student_id: "",
  to_student_uid: "",
  to_student: "",
  // from_course_id: null,
  to_course_id: null,
  to_course: "",
  from_branch_id: null,
  from_branch: "",
  to_branch_id: null,
  to_branch: "",
  from_city_id: null,
  from_city: "",
  to_city_id: null,
  to_city: "",
  from_training_id: null,
  to_training_id: "",
  branch_based_GST: {},
  student_training_id: null
};

export const bulkSessionUpdate = {
  attendees: [],
  trainer_id: "",
  start_time: "",
  end_time: "",
  details: {
    topic: "",
    subtopics: [""],
  },
  session_date: "",
}


