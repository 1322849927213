import React, { useEffect, useState, CSSProperties, useRef } from 'react'
import Upload from "../../images/upload.svg"
import closeicon from '../../images/close-red-icon.svg'
import { deleteFile, uploadFile } from '../../function/Storage/storeage';
import { getAllCertificates, getProfileDetails, uploadResume } from '../../function/Student/Profile/Profile';
// import { uuid } from '../../redux/useReducer';
import eyeIcon from '../../images/eyeIcon.svg'
import ClipLoader from "react-spinners/ClipLoader";
import { url } from 'inspector';
import { SupabaseClient } from '@supabase/supabase-js';
import { SUPABASE_CLIENT } from '../../Services/Supabase';


interface IframeProps {
    src: string;
  }
function Uploadresume() {
    const uuid: any = localStorage.getItem('uid')
    const [submitPop, setSubmitPop] = useState(true);
    const [messagePop, setMessagePop] = useState(false);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [resumeUpload,setResumeUpload] = useState({})
    const [fileNames, setFileName] = useState('');
    const [studentId,setstudentId] =  useState('')

    const [resumepopup, setresumepopup] = useState(true)
    const [resumeurl, setResumeUrl] = useState({}) as any
    let [loading, setLoading] = useState(true);

    const override: CSSProperties = {

        flex: 1,

        marginTop: 240,

        justifyContent: 'center',

        alignItems: 'center'



    };
    const handleDownload = async(url1: any) => {
        const url = url1;
    
        const link = document.createElement('a');
        // link.href = url + "#toolbar=0";
        link.href = url ;

        link.target = '_blank'
          
        link.click();
        // let data_url = await SUPABASE_CLIENT.storage
        // .from('resume')
        // .getPublicUrl(uuid)
        // console.log(data_url,'sd')
        // setViewUrl(data_url? data_url?.data?.publicUrl:'' + "#toolbar=0")
        // setresumepopup(false)

        // console.log(resumeurl,'gggg')

    };

    const handleChange = async (e: any) => {
        setFileName(e.target.files[0].name)
       
        setResumeUpload(e.target.files[0])
        setSubmitPop(false)
        setMessagePop(true)

    }
const [loader,setLoader] =  useState(false)
    const handleSubmit = async () => {
        setLoader(true)
     let file  =resumeUpload
       let fileName:string = `${studentId}/${fileNames}`
        let previouse_file = `${studentId}/${resumedata?.resume?.fileName}`
       await deleteFile(previouse_file, 'resume')
        const resumeData = await uploadFile({ file, fileName }, 'resume')
        if (resumeData != false) {    
            const resumeData = await uploadFile({ file, fileName }, 'resume')
            setResumeUrl(resumeData)
        }
        let key = Math.random().toString(36).substring(3, 7)
        let url = typeof(resumeData)!='boolean'?resumeData.publicUrl:''
        await uploadResume(uuid, { resume: { 'key': key, 'url': url, 'fileName': fileNames } })
        setMessagePop(false)
        
        setResumeUpload({})
        setLoader(false)

    }

    const handleClose = async () => {
        // setuploadPop(true)
        setSubmitPop(true)
        setLoading(true)
        const value = await getProfileDetails(uuid)
        setResumeData(value)
        setLoading(false)
    }

    const handleRemove = async () => {
        await deleteFile(`${studentId}/${fileNames}`, 'resume')
        setSubmitPop(true);
    }
    const [resumedata, setResumeData] = useState({}) as any
    const data = async () => {
        setLoading(true)
        const value:any = await getProfileDetails(uuid)
        setstudentId(value?.id)
        setResumeData(value)
        setLoading(false)
    }

    useEffect(() => {
        data()
    }, [])

    return (
        <div className=' min-h-screen bg-[#FFFFFF]'>
            {/* <iframe src="https://ovkqiugwjoznzsmsaiqw.supabase.co/storage/v1/object/public/resume/363cff93-f04d-4947-b82c-66764482c396" title="W3Schools Free Online Web Tutorials"></iframe> */}

            {loading == false ? <> 
                {submitPop ?    <div className=' pb-20'>

                    <p className=' pt-7 text-[#707070] text-[25px] items-start text-left  px-10'>Upload Resume</p>
                    <hr className='mt-3 items-center mx-10 ' />
                    {resumedata?.resume ? <div className='mt-4 flex items-center  shadow-3xl p-2 mx-auto px-10 rounded-lg w-[85%] justify-between ' onClick={() => handleDownload(resumedata?.resume?.url)}>
                        <a className='  text-[#036AD1] my-5'   >{resumedata?.resume?.fileName}</a>
                        <img className='w-[20px] h-[20px] cursor-pointer' src={eyeIcon} alt='view Icon' />
                    </div> : <div></div>}
                    <div className='flex my-16  align-middle items-center mx-auto text-center relative  w-[519px] max-tablet:w-[510px] max-mobile:w-[80vw]  justify-center p-6 bg-white border border-gray-200 rounded-lg shadow' >
                        <div className='my-24 font-bold'>
                            <p className='text-[#707070] text-[25px] max-tablet:text-[25px] max-mobile:text-[20px]' >Upload your Resume</p>
                            <div className='flex justify-between items-center mx-auto px-6 bg-[#036AD1] w-[200px] max-tablet:w-[200px] max-mobile:w-[150px] py-3 rounded-full mt-5 cursor-pointer' >
                                <label htmlFor="files" className="text-white text-[20px] flex justify-between w-[200px] cursor-pointer"  >Upload
                                    <input id="files" accept='.pdf,.doc' type="file" className=' opacity-0 hidden ' onChange={(e) => handleChange(e)} />
                                    <img src={Upload} className='w-[30px]' alt='upload'></img>
                                </label>
                            </div>

                        </div>
                    </div>
                </div> :
                <div>
                    {
                        messagePop ?
<>
                        {loader ?  <ClipLoader
  color="#036AD1"
  loading={true}
  cssOverride={override}
/> : 
                        <div className=' pb-20'>
                        <p className=' pt-7 text-[#707070] text-[25px] max-tablet:text-[25px] max-mobile:text-[20px] items-start text-left  px-10'>Upload Resume</p>
                        <hr className='mt-3 items-center mx-10 ' />
                        <div className='flex my-20 py-14 align-middle items-center mx-auto text-center relative w-[60%] max-tablet:w-[60%] max-mobile:w-[80%] justify-center p-6 bg-white border border-gray-200 rounded-lg shadow' >
                            <div className='my-20 w-[100%]'>
                                <div className='flex items-center justify-around font-bold'>
                                    <p className='text-[#707070] text-[25px] text-left break-all' >{fileNames}</p>
                                    <img className='cursor-pointer ml-4 w-6 h-6 ' onClick={handleRemove} src={closeicon} alt='closeicon' />
                                </div>
                                <button onClick={handleSubmit} className="bg-[#036AD1] mt-8 rounded-full shadow-[ 0px_3px_6px_#00000029;] text-[#FFFFFF] w-[154px] h-[46px]  text-[25px] max-tablet:text-[25px] max-mobile:text-[20px] py-1 px-4 ">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div> 
                    }
                    </>
                          :
    

                            <div className=' flex h-[100vh] bg-[#F9FCFF] items-center align-middle '>
                                <div className=' align-middle  mx-auto text-center relative  w-[70%] max-tablet:w-[70%] max-mobile:w-[80%] py-20 justify-center p-6 bg-white border border-gray-200 rounded-lg shadow' >
                                    <img className='cursor-pointer  right-5  top-3 absolute mb-5 w-6 h-6 ' onClick={handleClose} src={closeicon} alt='closeicon' />
                                    <p className='text-[#000000] text-[25px] max-tablet:text-[18px] max-mobile:text-[15px] ' >Your resume will be emailed to the HR of the company. If your profile is shortlisted, you will receive a notification in the App. All the best for your Career</p>
                                </div>
                            </div>
                    }
                </div>}
                
                {/* // : <div className='pt-10 w-[75%] mx-auto '>
                //     <div className='flex justify-between w-[75%] mx-auto'>
                //     <p className='font-bold capitalize text-[20px]'>{resumedata?.resume?.fileName}</p>
                //     <img className='cursor-pointer w-6 h-6 ' onClick={()=>setresumepopup(true)} src={closeicon} alt='closeicon' />

                //     </div> */}

                    {/* <iframe ref={iframeRef}  src={viewUrl}  className='!w-[85%] mx-auto flex h-[90vh] overflow-y-auto'/> */}
              
               </> : <><ClipLoader

                color="#036AD1"

                loading={loading}

                cssOverride={override}

            /></>}

        </div>
    )
}

export default Uploadresume