import React from 'react'
import MobileNumberScreen from '../mobileNumber/mobileNumberScreen';
import OtpScreen from '../OtpScreen/otpScreen';
import SignInGoogle from '../signingoogle/signInGoogle';
import BeforeAssessment from '../beforeAssessment/beforeAssessment';
import BeforeAssessment1 from '../beforeAssessment/beforeAssessmentoption';
import SignupFrom from '../signupForm/signupFrom';
import CourseStartPage from '../coursepage/courseStartPage';
import CoursePage from '../coursepage/coursePage';
import {Routes, Route } from 'react-router-dom';
import Coursedescpage from '../coursepage/coursedescpage';
import CourseMaterial from '../courseMaterials/CourseMaterial';
import Notificationpage from '../notification/notificationpage';
import NotificationDetails from '../notification/notificationDetails'
import SearchCourse from '../searchCourse/searchCourse';
import AllcoursePage from '../searchCourse/allcoursePage';
import ProfilePage from '../myProfile/profile'
import Appliedjob from '../applyJob/applyjob';
import AppliedJobDesc from '../applyJob/appliedJobDesc';
import Allocatedcourse from '../beforeAssessment/allocatedcourse';
import Latestjob from '../latestjob/latestjob';
import Latestjobdesc from '../latestjob/latestjobdesc';
import Homepage from '../homepage/homepage';
import VerifyEmail from '../signupForm/verifyEmail';
import ResourcePage from '../Resources/ResourcePage';
import ResourceDetail from '../Resources/ResourceDetail';
import TrendingCourse from '../searchCourse/trendingCourse';
import TechnologiesCourse from '../searchCourse/technologiesCourse'
import CourseDetialed from '../searchCourse/courseDetailes';
import FreeTrendingCourse from '../freeCourse/FreetrendingCourse';
import FreeTechnologiesCourse from '../freeCourse/FreetechnologiesCourse'
import FreeCourseDetialed from '../freeCourse/FreecourseDetailes';
import FreeCoursePage from '../freeCourse/FreeCoursePage';
import AllFreecoursePage from '../freeCourse/allFreecoursePage';
import CourseVideo from '../FreeCourseVideo/courseVideo';
import RequestCertificate from '../FreeCourseVideo/RequestCertificate';
import SingleVideo from '../FreeCourseVideo/singleVideo';
import CourseProgress from '../TrainerModule/pages/CourseProgress';
import { TrainerLogin } from '../TrainerModule/pages/TrainerLogin';
import BatchInformation from '../TrainerModule/pages/BatchInformation';
import AddSession from '../TrainerModule/pages/AddSession';
import BulkUpdate from '../TrainerModule/pages/BulkUpdate';
import { ProgressModal } from '../TrainerModule/pages/ProgressModal';
import TrainerTransaction from '../TrainerModule/pages/TrainerTransaction';
import TrainerLayout from '../TrainerModule/TrainerLayout';


//import PrivateRoute from '../../component/Protected'

function layout(this: any) {

    return (
        <div>
            <Routes>
                <Route path='/' element={<MobileNumberScreen />} />
                <Route path='/otp-screen' element={<OtpScreen />} />
                    {/* <PrivateRoute
        path='/otp-screen'
        isAuthenticated={this.props.state.session.isAuthenticated}
        component={OtpScreen }
    /> */}
  
                <Route path='/trainer/:page' element={<TrainerLayout />}/>
                <Route path='/sign-in-google' element={<SignInGoogle />} />
                <Route path='/signup-from' element={<SignupFrom />} />
                <Route path='/verifyemail' element={<VerifyEmail />} />
                <Route path='/beforeassessment' element={<BeforeAssessment />} />
                <Route path='/beforeassessment1' element={<BeforeAssessment1 />} />
                <Route path='/home' element={<Homepage />} />
                <Route path='/course-start-page' element={<CourseStartPage />} />
                <Route path='/course-page' element={<CoursePage />} />
                <Route path='/course-descpage' element={<Coursedescpage />} />
                <Route path='/course-material' element={<CourseMaterial />} />
                <Route path='/notificationpage' element={<Notificationpage />} />
                <Route path='/notificationdetails' element={<NotificationDetails />} />
                <Route path='/resource' element={<ResourcePage />} />
                <Route path='/resourses/:id' element={<ResourceDetail />} />
                <Route path='/all-coursepage' element={<AllcoursePage />} />
                <Route path='/search-course' element={<SearchCourse />} />
                <Route path='/trending-course' element={<TrendingCourse />} />
                <Route path='/technologies-course/:id' element={<TechnologiesCourse />} />
                <Route path='/course/:id' element={<CourseDetialed />} />
                <Route path='/all-free-coursepage' element={<AllFreecoursePage />} />
                <Route path='/free-Coursepage' element={<FreeCoursePage />} />
                <Route path='/free-trending-course' element={<FreeTrendingCourse />} />
                <Route path='/free-technologies-course/:id' element={<FreeTechnologiesCourse />} />
                <Route path='/free-course-detialed/:id' element={<FreeCourseDetialed />} />
                <Route path='/course-video' element={<CourseVideo/>} />
                <Route path='/request-certificate' element={<RequestCertificate/>}/>
                <Route path='/single-videos' element={<SingleVideo/>} />
                <Route path='/profile' element={<ProfilePage />} />
                <Route path='/applied-job' element={<Appliedjob />} />
                <Route path='/profile/:subroute' element={<ProfilePage />} />
                <Route path='/appliedjobdesc' element={<AppliedJobDesc />} />
                <Route path='/allocatedcourse' element={<Allocatedcourse />} />
                <Route path='/latestjob' element={<Latestjob />} />
                <Route path='/latestjobdesc' element={<Latestjobdesc />} />

            </Routes>
        </div>
    )
}

export default layout