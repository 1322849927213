import moment from "moment";
import { useEffect, useState } from "react";
import dropDownIcon from "../../../images/dropDownIcon.svg";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; 
import { enUS } from 'date-fns/locale';
import { DateRangePicker, Range } from "react-date-range";
export const DateRangeFilter = ({ handleChange, filterJSON }: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedRange, setSelectedRange] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),  //addDays(new Date(), 7),
        key: 'selection'
      }
    ]);
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
  
    useEffect(() => {
      document.addEventListener("click", handlDropOpenClose);
      return () => {
          document.removeEventListener("click", handlDropOpenClose);
      }
      
  },[])
  
  const handlDropOpenClose = (event: any) => {
    if (((event?.target?.localName === "div") &&(event?.target?.dataset?.elementname !== filterJSON.apiKey)) || ((event?.target?.localName ==="input")&&(event?.target?.id !== filterJSON.apiKey))) {
        setIsOpen(false);
    }
  }
  
    const changeDateRange = (value: any) => {
      setStartDate(moment(value.startDate).format("DD MMM YYYY"))
      setEndDate(moment(value.endDate).format("DD MMM YYYY"))
      setSelectedRange([value])
      handleChange(value.startDate, value.endDate, filterJSON.apiKey);
      //setIsOpen(false);
    };
  
    return (
      <div
        className={`relative ${filterJSON.className} mr-4 rounded-[2px] p-[2px] bg-[#F8F8F8]`}
        data-elementname={filterJSON.apiKey}
      >
        <div
          className="flex justify-between px-2 items-center w-full select-none cursor-pointer text-[#707070]"
          onClick={() => setIsOpen(!isOpen)}
          data-elementname={filterJSON.apiKey}
        >
          <p
            className="shrinkToTwoLines  shrinkToOneLine"
            data-elementname={filterJSON.apiKey}
          >
            {startDate && endDate ? `${startDate} - ${endDate}` : filterJSON.name}
          </p>
          <img
            src={dropDownIcon}
            data-elementname={filterJSON.apiKey}
            className={`${isOpen ? "rotate-180" : ""} transition`}
            alt="dropDownIcon"
          />
        </div>
        {isOpen && (
          <div
            data-elementname={filterJSON.apiKey}
            className="absolute shadow-lg top-11 right-2 z-[2] bg-white"
          >
          <DateRangePicker
            onChange={(item:any) => changeDateRange(item.selection)}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={selectedRange}
            locale={enUS}
            direction="horizontal"
          />
          </div>
        )}
      </div>
    );
  };