import { TableNames } from "../../../function/config/Tables";
import { FetchData, InsertData, UpdateData } from "../../../function/crud";
import { calculatePercentage, calculateTimeDifference, errorNotifier } from "../../../function/commonHelper";
import { menuAccesConversion } from "./helper";
import { dateFormat } from "../../../Services/CommonFunctions";


export const getLoggedUserDetails = async (uid: string) => {
    // console.log(uid, "uid_uid")
    try {
        const { data: user, error }: any = await FetchData(TableNames.users).eq("uid", uid);
        // console.log({user}, "user");
        if (error) throw error;
        if(user?.length > 0) {

            const { data: Roles, error: RolesError } = await FetchData(TableNames.roles).in("id", user[0]?.roles_array);

            if (RolesError) throw RolesError;
            const filteredTableData = Roles.filter((entry: any) =>
            user?.[0]?.roles_array?.includes(entry.id)
          );
  
          const mapFilteredTableData =
            filteredTableData &&
            filteredTableData?.map((data: any) => data?.role);
            // console.log({Roles}, "Roles");
            return {...user?.[0],menuAccess:menuAccesConversion(Roles),'roles':mapFilteredTableData,'name':
            user?.[0]?.first_name.concat(
              " ",
              user?.[0]?.last_name
            ) || ""};
        }
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getCourseProgressTableData = async (UsersDetails: any) => {

    try {

        const { data: batches, error } = await FetchData(TableNames.batches, ["id", "mode", "course:courses(course_name)", "schedule", "status", "branch_id","start_date", "start_time",'end_time', "city:cities(city_name)", "duration", "batch_code", "batch_progress_in_hrs", "trainer:users(first_name, last_name, phone_number, uid)", "branch:branches(branch_name)"]).in("branch_id", UsersDetails?.branches).order("id", {ascending: false});
        if (error){
            return error
        };

        if (UsersDetails?.roles.includes('Trainer')) {
            const filterBatches = batches?.filter((batch: any) => (UsersDetails?.name) === `${batch?.trainer?.first_name} ${batch?.trainer?.last_name}`);
            // console.log(filterBatches, "filterBatches")
            const batchData = filterBatches.map((batch: any) => ({
                ...batch, batch_progress_in_percent: Math.round(calculatePercentage(batch?.batch_progress_in_hrs, batch?.duration)), course_name: batch?.course?.course_name, branch_name: batch?.branch?.branch_name, city: batch?.city?.city_name, start_date: dateFormat(batch?.start_date, "DD MMM YYYY"), trainer_name: `${batch?.trainer?.first_name} ${batch?.trainer?.last_name}`, trainer_mobile: batch?.trainer?.phone_number}))

            return batchData;
        } else {
            const batchData = batches.map((batch: any) => ({
                ...batch, batch_progress_in_percent: Math.round(calculatePercentage(batch?.batch_progress_in_hrs, batch?.duration)), course_name: batch?.course?.course_name, branch_name: batch?.branch?.branch_name, city: batch?.city?.city_name, start_date: dateFormat(batch?.start_date, "DD MMM YYYY"), trainer_name: `${batch?.trainer?.first_name} ${batch?.trainer?.last_name}`, trainer_mobile: batch?.trainer?.phone_number}))

            return batchData;
        }

    } catch (error) {

        return errorNotifier(error);

    }

}

export const batchProgressById = async (batchId: number | string) => {
    try {
        const { data: batch, error }: any = await FetchData(TableNames.batches, ["*", "course:courses(course_name)", "branch:branches(branch_name)", "city:cities(city_name)", "trainer:users(uid)"]).eq("id", batchId);

        const { data: sessions, error: err } = await FetchData(TableNames.sessions, ["duration", "attendees", "session_status"]).eq("batch_id", batchId)
        const filterStatusBasedSession = sessions?.filter((fil: any) => fil?.session_status === "Success");
        const batchData = batch.map((el: any) => ({...el, start_date: dateFormat(el.start_date, "DD MMM YYYY"), planned_date_of_completion: dateFormat(el.planned_date_of_completion, "DD MMM YYYY")}))
    
        const progress_in_hrs = filterStatusBasedSession?.reduce((accumulator: any, currentValue: any) => Number(accumulator) + Number(currentValue?.duration), 0)

        const batch_progress_in_percent = calculatePercentage(progress_in_hrs, batch[0].duration)
        const currentBatch= batchData[0]
        currentBatch.city_name = `${currentBatch.branch.branch_name} - ${currentBatch.city.city_name}`
        currentBatch.course_name = currentBatch.course.course_name
        currentBatch.start_time = timeFormater(currentBatch?.start_time)
       currentBatch.progress = `${progress_in_hrs}/${currentBatch.duration}`
        if (error || err ) throw error  || err;
        return {batch: batchData[0], progress_in_hrs, total_duration: batch[0].duration, batch_progress_in_percent, trainer_id: batch[0].trainer.uid};
    } catch (error) {
        return errorNotifier(error);
    }
}
export const getSessionsAndTrainersByBatchId = async (batchId: number | string) => {
    try {
        const { data, error } = await FetchData(TableNames.sessions, ["id", "session_date", "trainer:users(uid,first_name, last_name, phone_number)", "duration", "start_time", "end_time", "details", "attendees", "session_status"]).eq("batch_id", batchId).order("id", {ascending: false})
        const { data: batch_trainers, error: trainer_error }: any = await FetchData(TableNames.batch_trainers, ["trainer:users(uid ,first_name, last_name, email, phone_number)", "start_date", "end_date"]).eq("batch_id", batchId)

        const updateTrainerData = batch_trainers?.map((el: any) => ({...el, trainer_name: `${el.trainer.first_name} ${el.trainer.last_name}`, trainer_mobile: el.trainer.phone_number, duration: data?.filter((ele: any) => (ele.trainer.uid === el.trainer.uid)).reduce((acc: any, curr_val: any) => Number(acc) + Number(curr_val.duration), 0), current_trainer: batch_trainers[batch_trainers.length - 1].trainer.uid === el.trainer.uid ? true : false}))

        const updatedSessionData = data?.map((el: any) => ({...el, session_date: dateFormat(el.session_date, "DD MMM YYYY"), trainer_name: `${el.trainer.first_name} ${el.trainer.last_name}`, trainer_mobile: el.trainer.phone_number, participants: el?.attendees?.length}))

        if (error || trainer_error) throw error || trainer_error
        return {updatedSessionData, updateTrainerData}
            // return updateTrainerData
    } catch (error) {
        return errorNotifier(error);
    }
}
export const mapFunction = (arr: any, val: any) => {
    return arr.filter((item:any) => item !== val);
  }
export const timeFormater = (time:string) =>{
return parseInt(time.slice(0,2)) >12 ? `${parseInt(time.slice(0,2))-12}:${time.slice(3,5)} PM`:  `${parseInt(time.slice(0,2))}:${time.slice(3,5)} AM`
}


export const getStudentsByBatchid = async (batchId: number) => {
    try {
        const { data, error } = await FetchData(TableNames.trainings, ["student:students(uid, name, phoneNumber)", "status"]).eq("batch_id", batchId)
        if (error) throw error
        return data
    } catch (error) {
        return errorNotifier(error)
    }
}

export const createSession = async (sessionData: any) => {
    try {

        const { data: duration_session, error: e }: any = await FetchData(TableNames.sessions, ["id", "batch:batches(course:courses(duration))", "duration"]).eq("batch_id", sessionData.batch_id).order("id")

        const {data: batch }: any = await FetchData(TableNames.batches).eq("id", sessionData?.batch_id)

        const session_duration = calculateTimeDifference(sessionData.start_time, sessionData.end_time)
        const newSessionData = {
            ...sessionData,
            duration: session_duration,
        } 

        if (duration_session.length > 0) {
             const sessionUpdate = {
                    recent_session: batch[0].in_progress ? false : true
            }    
            await UpdateData(TableNames.sessions, sessionUpdate, { conditionKey: "id", conditionValue: duration_session[duration_session.length - 1]?.id })
        }
        // if (batch[0].in_progress) {
        //     var { data, error } = await InsertData(TableNames.sessions, newSessionData)
        //     if (error) throw error
        // } else {
        //     return { message: "The batch you are trying to add session is ended.", status: false }
        // }
        if (batch[0].batch_progress_in_hrs === batch[0].duration) {
            return { message: "The batch you are trying to add session is ended.", status: false }
        } else {
            var { data, error } = await InsertData(TableNames.sessions, newSessionData)
            if (error) throw error 
        }
        const { data: sessions, error: session_err }: any = await FetchData(TableNames.sessions, ["id", "batch:batches(course:courses(duration))", "duration", "session_status"]).eq("batch_id", sessionData.batch_id).order("id")
        const filterStatusBasedSession = sessions?.filter((fil: any) => fil?.session_status === "Success");
        
        const batch_hours = filterStatusBasedSession.reduce((acc: any, currVal: any) => Number(acc) + Number(currVal?.duration), 0)
    
        const updateTemplate = batch_hours === batch[0].duration ? {
            batch_progress_in_hrs: batch_hours,
            in_progress: false
        } : {
            batch_progress_in_hrs: batch_hours
            }
        
        await UpdateData(TableNames.batches, updateTemplate, { conditionKey: "id", conditionValue: sessionData.batch_id })

        return { data: data, status: true};

    } catch (error) {
        return errorNotifier(error)
    }
}

export const updateSession = async (sessionData:any, sessionId: number) => {
    try {        
        const session_duration = calculateTimeDifference(sessionData.start_time, sessionData.end_time);
        const updatedSessionData = {
            ...sessionData,
            duration: session_duration
        }
        const { data, error } = await UpdateData(TableNames.sessions, updatedSessionData, { conditionKey: "id", conditionValue: sessionId });
        if (error) throw error;
        return true;
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getTrainerIdBasedStudents = async (batch_Id:any) => {
    try {
        const { data: batchDetails, error:batch_error } = await FetchData(TableNames.batches, ["id","trainer_id"]).eq("id", batch_Id);
        const { data: trainerDetails, error } = await FetchData(TableNames.trainings, ["id", "batch_id", "trainers_ids", "student:students(name, phoneNumber)", "trainer_payment", "existing_trainer_payment", "student_payment_status"]).eq("batch_id", batch_Id)
        // console.log(trainerDetails, "Trainer_Details");
        if (error || batch_error) throw error || batch_error

        const trainingData = batchDetails?.map((batch: any) => (
            trainerDetails && trainerDetails?.filter((fil:any)=> fil?.trainers_ids?.includes(batch?.trainer_id))
        ))
        // console.log(trainingData[0], "trainingData");
        return trainingData[0]
    } catch (error) {
        return errorNotifier(error)
    }
}

export const TrainerTransactionTableData = async (UsersDetails: any) => {
    try {
        const { data: batchDetails, error:batch_error } = await FetchData(TableNames.batches, ["id", "batch_code", "trainer:users(first_name, last_name, phone_number)", "branch_id", "branch:branches(branch_name)", "total_trainer_payment", "process_trainer_payment_date", "trainer_id"]).in("branch_id", UsersDetails?.branches);
        const { data: trainerDetails, error } = await FetchData(TableNames.trainings, ["id", "batch_id", "trainers_ids", "student:students(name)"])
        if (error || batch_error) throw error || batch_error

        const trainingData = batchDetails?.map((batch: any) => ({
            id: batch?.id,
            batch_code: batch?.batch_code,
            updated_at: batch?.process_trainer_payment_date?.length > 0 ? (batch?.process_trainer_payment_date[batch?.process_trainer_payment_date?.length-1]) : '',
            batch_start_date: dateFormat(batch?.start_date, "DD MMM YYYY"),
            trainer_name:batch?.trainer?.first_name + batch?.trainer?.last_name,
            trainer_number: batch?.trainer?.phone_number,
            paid_amount: batch?.total_trainer_payment,
            students: trainerDetails && trainerDetails?.filter((fil:any)=> (fil?.batch_id===batch?.id)&&(fil?.trainers_ids?.includes(batch?.trainer_id)))
        }))
        return trainingData
    } catch (error) {
        return errorNotifier(error)
    }
}