import React, { useEffect, useState, CSSProperties } from 'react'
import { getStudentData } from '../../function/Student/SignUp/Student'
// import { uuid } from '../../redux/useReducer'
import { courseEnquiry } from '../../function/Student/Courses/Courses'
import { getAllFeedbacks, getCourses, getProfileDetails, getStudentConcerns, submitConcernOrFeedback } from '../../function/Student/Profile/Profile'
import closeicon from '../../images/close-red-icon.svg'
import { getFeedback, updateStatus } from '../../function/Student/Feedback/Feedback'
import { dateFormat } from '../elements/dateConvertion'
import eyeIcon from '../../images/eyeIcon.svg'
import ClipLoader from "react-spinners/ClipLoader";

function Feedback() {
    const uuid:any = localStorage.getItem('uid')
    const [onsubmit, setOnsubmit] = useState(false)
    const [courseInfo, setCourseInfo] = useState({}) as any
    const [selectCourse, setSelectedCourse] = useState({}) as any
    const [text, setText] = useState('')
    const [courseSelectState, setCourseselectState] = useState(false)
    const [textState, setTextstate] = useState(false)
    const [submitState, setSubmitstate] = useState(false)
    const [pageState, setPagestate] = useState(false)
    const [viewPage, setViewpage] = useState(true)
    const [feedbackRes, setFeedbackres] = useState({}) as any
    const [sessionFeed, setSessionfeed] = useState({}) as any
    const [personData, setPersonData] = useState({}) as any
    const [firstLetter, setFirstLetter] = useState('')
    const [tableName,setTableName] = useState('feedbacks')

    const [load, setLoad] = useState(false)
    let [loading, setLoading] = useState(true);
    const override: CSSProperties = {

        flex: 1,
        marginTop:240,
        justifyContent: 'center',
        alignItems:'center'
    
    
      };

    var length = 50;

    const handelSubmit = async () => {
        let trainingId = selectCourse
        let concern = text

        if (textState === true && courseSelectState === true) {
            const value = await submitConcernOrFeedback({ concern_or_feedback: concern, training_id: trainingId, student_id: uuid })
            setOnsubmit(false)
            setSubmitstate(true)
        }
        setSubmitstate(false)
        setDivhidden(true)

    }
    const datas = async () => {

        setLoading(true)
        let coursedata = await getCourses(uuid)
        setCourseInfo(coursedata)
        const data = await getAllFeedbacks(uuid)
        setFeedbackres(data)
        const datases = await getStudentConcerns(uuid)

        setSessionfeed(datases)
        const personalData = await getProfileDetails(uuid)
        setPersonData(personalData)
        title(personalData)
        setLoad(true)
        setPagestate(true)
        setOnsubmit(true)
        setLoading(false)
    }
    const handleChange = (e: any) => {
        courseInfo.map((item: any) => {
            if (item?.course?.course_name === e.value) {
                setSelectedCourse(item?.id)
            }
        })
        setCourseselectState(true)
    }
    const handletext = (e: any) => {
        setText(e.value)
        setTextstate(true)
    }

    const handleClose = () => {
        window.location.reload();
        setDivhidden(false)
        setOnsubmit(true)
    }

  
    const handleClose1 = () => {
        setPagestate(true)
    }

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0
        })
        datas()
    }, [])
    const hadleFeed = () => {
        setViewpage(true)
        setTableName('feedbacks')
    }
    const hadleFeed1 = () => {
        setViewpage(false)
        setTableName('student_concern_feedback')

    }
    const title = async (props: any) => {
        let title_str = ''
        await props?.name?.split(' ').forEach((element: any) => {
            title_str += element[0].toUpperCase();
        })
        setFirstLetter(title_str)
    }

   
    const [comment, setComment] = useState('')
    const [reply, setReply] = useState('')
const [reOpen,setReOpen] = useState('')
const [feedbackId,setFeedbackId] = useState('')
    const replyValue = (props: any) => {
        setReOpen(props.status)
        setFeedbackId(props.id)
        setComment(props.comment ? props?.comment : props?.concern_or_feedback)
        setReply(props?.reply)
        setPagestate(false)
        setShow(true)
    }
    const [show, setShow] = useState(true)
    const [divHiden, setDivhidden] = useState(false)
    const handlereplayshow = () => {
        setShow(false)
    }
    const FeedBackReopen = async() => {
        const data = await updateStatus(tableName,feedbackId)
        
        datas()
    }

    return (
        <div className='bg-[#FFFFFF] min-h-screen pb-36 '>
            {loading == false ? <> {load ? <div>
                {pageState ? <div>
                    <div className='p-10 text-left mx-auto '>
                        <p className='text-[25px] max-mobile:text-[20px] text-[#707070]'>Concern / Feedback </p>
                        <hr className='mt-4' />
                    </div>
                    <div hidden={divHiden}>
                        <div className=' flex justify-end m-5 max-mobile:m-1 max-mobile:w-[100vw] max-tablet:justify-end max-mobile:justify-center'>
                            
                            <div className={`my-4 text-white ${tableName == 'feedbacks' ? 'bg-[#036AD1] ': 'bg-sky-500 '}  cursor-pointer   font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2`} onClick={hadleFeed}>Session Feedback</div>
                            <div className={`my-4 text-white ${tableName == 'student_concern_feedback' ? 'bg-[#036AD1] ': 'bg-sky-500 '}  cursor-pointer   font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2`} onClick={hadleFeed1}>Course Feedback</div>
                        </div>
                        {viewPage ? <div className='px-10 max-tablet:px-10 max-mobile:px-2'>
                            {tableName == 'feedbacks' ? <p className='text-start text-[24px] max-tablet:text-[18px] font-bold mb-2 text-[#707070]'>Session Feedback</p> : ''}
                            <div className='flex bg-[#F9FCFF] max-mobile:text-[14px] w-[100%] max-mobile:w-[96vw] py-3 shadow-3xl'>
                                <p className=' w-[6%]'>#</p>
                                <p className=' w-[15%]'>Created Date</p>
                                <p className=' w-[20%]'>Course</p>
                                <p className=' w-[15%]'>Status</p>
                                <p className=' w-[30%]'>Comments</p>
                                <p className=' w-[14%]'>Action</p>
                            </div>
                            <div className=' h-[200px] overflow-y-scroll  shadow-3xl' >
                                {feedbackRes?.length?feedbackRes?.map((item: any, index: number) => {
                                    return <div className='flex bg-[#fff] max-mobile:text-[14px] w-[100%] max-mobile:w-[95vw] py-3 '>
                                        <p className=' w-[6%]'>{index + 1}</p>
                                        <p className=' w-[15%] break-words'>{dateFormat(item?.created_at, ' DD MMM YYYY')}</p>
                                        <p className=' w-[20%] break-words'>{item?.training?.course?.course_name}</p>
                                        <p className=' w-[15%]'>{item?.status}</p>
                                        <p className=' w-[30%] break-all'>{item?.comment?.substring(0, length)} </p>
                                        <p className='w-[14%] flex justify-center'><img className=' w-[30%] max-mobile:w-[50%] cursor-pointer' src={eyeIcon} alt='eye cion' onClick={() => replyValue(item)} /></p>
                                        
                                    </div>

                                }):<div className='mt-20'>No Data</div>}
                            </div>
                        </div> :
                            <div className='px-10 max-tablet:px-10 max-mobile:px-2'>
                            {tableName != 'feedbacks' ? <p className='text-start text-[24px] max-tablet:text-[18px] font-bold mb-2 text-[#707070]'>Course Feedback</p> : ''}

                                <div className='flex bg-[#F9FCFF] max-mobile:text-[14px] w-[100%] max-mobile:w-[95vw] py-3 shadow-3xl'>
                                    <p className=' w-[6%]'>#</p>
                                    <p className=' w-[15%]'>Created Date</p>
                                    <p className=' w-[20%]'>Course</p>
                                    <p className=' w-[15%]'>Status</p>
                                    <p className=' w-[30%]'>comments</p>
                                    <p className=' w-[14%]'>Action</p>
                                </div>
                                <div className=' h-[200px] max-mobile:w-[95vw] overflow-y-scroll  shadow-3xl' >
                                    {sessionFeed.length?sessionFeed?.map((item: any, index: number) => {
                                        return <div className='flex max-mobile:text-[14px] bg-[#fff] w-[100%] py-3 ' >
                                            <p className=' w-[6%]'>{index + 1}</p>
                                            <p className=' w-[15%] break-words'>{dateFormat(item?.created_at, ' DD MMM YYYY')}</p>
                                            <p className=' w-[20%] break-words'>{item?.training?.course?.course_name}</p>
                                            <p className=' w-[15%]'>{item?.status}</p>
                                            <p className=' w-[30%] break-words'>{item?.concern_or_feedback? item?.concern_or_feedback.length>10? item?.concern_or_feedback.substring(0, length)+'..':item?.concern_or_feedback.substring(0, length)+'':''}</p>
                                            <p className='w-[14%] flex justify-center'><img className='w-[30%] max-mobile:w-[50%]   cursor-pointer' src={eyeIcon} alt='eye cion' onClick={() => replyValue(item)} /></p>
                                        </div>

                                    }):<div className='mt-20'>No Data</div>}
                                </div>
                            </div>}
                    </div>

                    <div>
                        {onsubmit ? <div className='w-[60%] max-tablet:w-[60%] max-mobile:w-[87vw] mx-auto shadow-3xl my-14 p-5 max-desktop:w-[60%]'>
                            <p className=" px-3 text-[29px] max-tablet:text-[29px] max-mobile:text-[20px] text-[#707070] font-medium" > Concern / Feedback</p>
                            <select className=' shadow-5xl outline-none w-[90%] max-tablet:w-[90%] max-mobile:w-[70%]  p-2 text-left cursor-pointer' onChange={(e) => handleChange(e.target)}>
                                <option selected disabled>Select Course</option>
                                 {courseInfo?.map((item: any) => {
                                return <option>{item?.course?.course_name}</option>
                            })}
                            </select>
                            <textarea className="p-2 my-3 w-[90%] mx-auto block h-24 border text-left justify-start text-[17px]  font-medium" placeholder='Write a Concern / Feedback' onChange={(e) => handletext(e.target)} />
                            <button type="button" className="my-4 text-white bg-[#036AD1]  font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2" onClick={handelSubmit} disabled={submitState}>Submit</button>
                        </div> : <div className='w-[40%] max-tablet:w-[40%] max-mobile:w-[80vw]  mx-auto shadow-3xl my-32 max-monitor:my-44 p-10 relative  max-desktop:w-[40%]'>
                            <img className='cursor-pointer  right-5  top-3 absolute mb-5 w-6 h-6 ' onClick={handleClose} src={closeicon} alt='closeicon' />
                            <p>Your Concern is sent to the respective Manager. Be assured that our team will get in touch with you with in 1 working day</p>
                        </div>}
                    </div>

                </div> : <div className=' min-h-screen  pt-[20%]'>
                    <div className='w-[60%] max-mobile:w-[80vw] max-tablet:w-[60%] pb-10 shadow-3xl mx-auto relative items-center align-middle my-5'>
                        <img className='cursor-pointer  right-5  top-3 absolute mb-5 w-6 h-6 ' onClick={handleClose1} src={closeicon} alt='closeicon' />
                        <div className='flex  items-center p-5'>
                            <p className=' bg-[#036AD1] text-[#fff] rounded-full p-1.5 w-[35px] h-[35px]'>{firstLetter}</p>
                            <p className='pl-5'>{personData.name}</p>
                        </div>
                        <p className=' text-left break-all text-[18px] pl-14 pr-6'>{comment}</p>

                        <p className=' border-2 px-4 py-2  w-[100px] ml-20 mt-3 mb-5' onClick={handlereplayshow}>Reply</p>
                        <p hidden={show} className=' text-left text-[20px] max-mobile:text-[16px] max-mobile:pl-3 pl-32 pb-3'>{reply ? reply : 'Our Team working on your concern, we will get bact to you shortly!'}</p>
                        {reOpen == 'Resolved' ? <button className='border-2 px-4 py-2 w-[100px] ml-20 mt-3 mb-5' onClick={()=>FeedBackReopen()}>Reopen</button> : ''}

                    </div>
                </div>}
            </div> : <div></div>}</> : <> <ClipLoader

color="#036AD1"

loading={loading}

cssOverride={override}

/></>}
           
        </div>
    )
}

export default Feedback