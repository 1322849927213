import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { signInTypes } from "../Types/loggedInUserDetailsType";

const initialState: any = {
  userDetails: localStorage.getItem("userDetails") || "",
};

const loggedInSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoggedValue: (state: any, action: PayloadAction<any>) => {
      action.payload.userDetails &&
      localStorage.setItem("userDetails", action.payload.userDetails);
      state.userDetails = action.payload.userDetails;
    },
  },
});

export const { setLoggedValue } = loggedInSlice.actions;
export default loggedInSlice.reducer;
