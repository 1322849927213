import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  sideNavOpenClose: false,
};

const sideNavSlice = createSlice({
  name: "navStatus",
  initialState,
  reducers: {
    showSideNavStatus: (state: any, action: PayloadAction<any>) => {
      state.sideNavOpenClose = action.payload;
    }
  },
});

export const { showSideNavStatus } = sideNavSlice.actions;
export default sideNavSlice.reducer;
