import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function TrainerCard({data}:any) {
    const cardsData = [
{name:'batch_code',bgcolor:'#f8fbff',color:'black'},
{name:'course_name',bgcolor:'#f8fbff',color:'black'},
{name:'schedule',bgcolor:'#f9f9f9',color:'black'},
{name:'batch_progress_in_percent',bgcolor:'#f9f9f9',color:'black'},
{name:'status',bgcolor:'#f8fbff',color:'black'},
{name:'timing',bgcolor:'#f8fbff',color:'red'},
    ]
    const navigate = useNavigate()
    // console.log(data,'itemee')
    const batchGet = () =>{
      navigate('/trainer/batch',{state:data})
    }
  const getData = (name:any) =>{
    if(name == 'batch_progress_in_percent'){
      return 'Batch Progress:' + data[name] + '%'
    }else if(name =='timing'){ 
     let start = parseInt(data.start_time.slice(0,2)) >12 ? `${parseInt(data.start_time.slice(0,2))-12}:${data.start_time.slice(3,5)} PM`:  `${parseInt(data.start_time.slice(0,2))}:${data.start_time.slice(3,5)} AM`
     let end = parseInt(data.end_time.slice(0,2)) >12 ? `${parseInt(data.end_time.slice(0,2))-12}:${data.end_time.slice(3,5)} PM`:  `${parseInt(data.end_time.slice(0,2))}:${data.end_time.slice(3,5)} AM`
      
     return `${start} - ${end}`
    }
    else{
      return data[name]
    }
  }

  return (
    <div onClick={batchGet} className='bg-[#fff] text-[14px] py-[15px] rounded mb-[14px] mx-[6px] flex flex-wrap shadow-md'>
      {cardsData.map(({name,bgcolor,color})=>{
        return <p style={{wordBreak:'break-word'}} className={`bg-[${bgcolor}] font-[600] text-[${color}]  py-[10px] basis-1/2 text-left pl-[10px] `}>
          {getData(name)}</p>
      })}
    </div>
  )
}
