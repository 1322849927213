import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import Back from '../../../images/BackIcon.png'
import { Progressbar } from '../../elements/progressBar';
import { batchProgressById, getSessionsAndTrainersByBatchId, getStudentsByBatchid, timeFormater } from '../Functions/TrainerFunctions';
import { PulseLoader } from 'react-spinners';


function BatchInformation() {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { state } = useLocation();
    const [batchDetails, setbatchDetails] = useState() as any
    const [trainerData, setTrainerData] = useState([]) as any
    const [sessionData, setSession] = useState([])
    const [compensationData, setCompensation] = useState([])
    const [studentData, setStudentData] = useState({}) as any
    const BackBtn = () => {
        navigate('/trainer/course-progress')
    }
    const keyList = [
        { 'label': 'Branch & City', 'name': 'city_name' },
        { 'label': 'Rooms', 'name': 'rooms' },
        { 'label': 'Schedule', 'name': 'schedule' },
        { 'label': 'Courses Duration', 'name': 'duration' },
        { 'label': 'Start Date', 'name': 'start_date' },
        { 'label': 'Start time', 'name': 'start_time' },
        { 'label': 'Planned Date of Completion', 'name': 'planned_date_of_completion' },
        { 'label': 'Course', 'name': 'course_name' },
        { 'label': 'End Date', 'name': 'end_date' },
        { 'label': 'Batch Progress', 'name': 'progress' },
    ]

    const getData = (name: any) => {
        const batch = batchDetails?.batch
        if (name == 'progressBar') {
            return <Progressbar percentage={batchDetails?.batch_progress_in_percent ? batchDetails?.batch_progress_in_percent : 0} className='w-[35px]' />
        }
        return batch ? batch[name] : '-'
    }

    const getBatchData = async () => {
        setIsLoading(true)
        const data = await batchProgressById(state.id)
        const trainerResponse = await getSessionsAndTrainersByBatchId(state.id)
        const studentData = await getStudentsByBatchid(state.id)
        setStudentData(studentData)
        setbatchDetails(data)
        if (trainerResponse) {
            const trainerDetails = trainerResponse && typeof trainerResponse === 'object' ? trainerResponse.updateTrainerData : [];
            setTrainerData(trainerDetails ? trainerDetails : [])
        }
        const checkSessionStatus = trainerResponse && typeof trainerResponse === 'object' ? trainerResponse.updatedSessionData : [];
        if (checkSessionStatus) {
            const { success, others } = checkSessionStatus.reduce(
                (acc: any, el: any) => {
                    if (el?.session_status === "Success") {
                        acc.success.push(el);
                    } else {
                        acc.others.push(el);
                    }
                    return acc;
                },
                { success: [], others: [] }
            );
            setSession(success)
            setCompensation(others)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getBatchData()
    }, [])

    if (isLoading) {
        return (
            <div className="bg-[#F9FCFF] h-screen flex items-center justify-center">
                <PulseLoader color="#065FEF" size={8} />
            </div>
        );
    }

    return (
        <div className='bg-[#036AD1] pb-2 h-full'>

            <div className='flex justify-end py-2 px-3'>
                <img src={Back} alt='Back' className='w-[25px]' onClick={() => BackBtn()} />
            </div>

            <div className='bg-[#e9e5f1] pb-2 rounded-md m-2 h-fulls'>
                <div className='bg-[#fff] rounded p-3  shadow-md '>
                    <p className='text-start text-[#a8a9a8] font-bold pb-2'>Batch Information</p>
                    <hr></hr>
                    <div className='flex flex-wrap mt-3 '>
                        {keyList.map((item: any) => {
                            return <div className={`basis-1/2 mb-2  text-left px-2 pb-2 ${item.name == 'progress' ? 'shadow-md rounded flex items-center justify-between' : ''}`}>
                                <div>
                                    <label htmlFor="" className='text-[12px] text-[#a8a9a8] font-medium'>{item.label}</label>
                                    <p className='text-[14px] font-bold'>{getData(item.name) ? getData(item.name) : '-'}</p>
                                </div>

                                {item.name == 'progress' ? getData('progressBar') : ''}
                            </div>
                        })}
                    </div>


                </div>

                <p className='text-left mx-4 text-[14px]  my-3 font-semibold'>Trainer Details</p>
                <div className='bg-white py-2 mx-3 mb-5 shadow-lg rounded'>

                    <div className='flex bg-[#EFF7FF] px-2' style={{ wordBreak: 'break-word' }}>
                        <div className='font-bold  basis-1/3 text-[12px] px-1'>
                            <p>{trainerData[0]?.trainer_name}</p>
                            <p>{trainerData[0]?.trainer_mobile}</p>
                            <p className='bg-[#ffcc00]'>Current Trainer</p>
                        </div>
                        <div className=' basis-1/3 px-1'>
                            <p className='text-[10px] text-[#a8a9a8] font-medium'>Start Date</p>
                            <p className='font-bold text-[12px]'>{trainerData[0]?.start_date ? trainerData[0]?.start_date : '-'}</p>
                            <p className='font-bold text-[12px]'>Duration</p>
                        </div>
                        <div className='basis-1/3 px-1'>
                            <p className='text-[10px] text-[#a8a9a8] font-medium'>End Date</p>
                            <p className='font-bold text-[12px]'>{trainerData[0]?.end_date ? trainerData[0]?.end_date : '-'}</p>
                            <p className='font-bold text-[12px]'>{trainerData[0]?.duration} Hours</p>
                        </div>
                    </div>


                </div>


                <div className='flex justify-between items-center text-[14px] mx-4  my-2'> <p className='text-left font-semibold'>Session Details</p>
                    <button onClick={() => { navigate('/trainer/add-session', { state: { studentData: studentData, batch: state, action: 'add' } }) }} className='py-[2px] px-2 h-full rounded text-[12px] bg-[#df1953] text-white'>Add Session +</button></div>
                <div className='bg-white py-2 mx-3 mb-5 shadow-lg rounded'>

                    <div className='flex text-[14px] font-medium text-[#a8a9a8] flex-wrap text-left' style={{ wordBreak: 'break-word' }}>
                        {sessionData.length?sessionData.map((session: any) => {
                            return <>
                                <div className='bg-[#EFF7FF] basis-1/2 px-2 py-1'>
                                    <p>{session.trainer_name}</p>
                                    <p>{session.trainer_mobile}</p>
                                </div>
                                <div className='bg-[#fce3e6] basis-1/2 px-2 py-1'>
                                    <p>{session.session_date}</p>
                                    <p>{timeFormater(session.start_time)} - {timeFormater(session.end_time)}</p>
                                </div>
                                <div className='bg-white basis-1/2 px-2 py-1'>
                                    <p>Topic :</p>
                                    <p className='text-[#1f75bf]'>{session.details.topic}</p>
                                </div>
                                <div className='bg-[#EFF7FF] basis-1/2 px-2 py-1'>
                                    <p>Participation : <span className='text-[#1f75bf]'>{session.participants}</span></p>
                                    <p>Duration :<span className='text-[#1f75bf]'> {session.duration} {session.duration > 1 ? 'Hrs' : 'Hr'}</span></p>
                                </div>
                            </>
                        }):'No Sessions'}
                    </div>


                </div>
                <div className='flex justify-between items-center  mx-2  my-2'> <p className='text-left text-[14px] font-semibold'>Compensation Details</p>
                    <button onClick={() => { navigate('/trainer/add-session', { state: { studentData: studentData, batch: state, action: 'edit' } }) }} className='py-[2px] px-2 h-full rounded text-[12px] bg-[#df1953] text-white'>Add Compensation +</button></div>
                <div className=' py-2 mx-3'>

                    <div className='flex text-[14px] text-[#a8a9a8] font-medium bg-[#EFF7FF] flex-wrap text-left' style={{ wordBreak: 'break-word' }}>
                        {compensationData.length?compensationData.map((session: any) => {
                            return <>
                                <div className='bg-[#EFF7FF] basis-1/2 px-2 py-1'>
                                    <p>{session.trainer_name}</p>
                                    <p>{session.trainer_mobile}</p>
                                </div>
                                <div className='bg-[#fce3e6] basis-1/2 px-2 py-1'>
                                    <p>{session.session_date}</p>
                                    <p>{timeFormater(session.start_time)} - {timeFormater(session.end_time)}</p>
                                </div>
                                <div className='bg-white basis-1/2 px-2 py-1'>
                                    <p>Topic :</p>
                                    <p className='text-[#1f75bf]'>{session.details.topic}</p>
                                </div>
                                <div className='bg-[#EFF7FF] basis-1/2 px-2 py-1'>
                                    <p>Participation : <span className='text-[#1f75bf]'>{session.participants}</span></p>
                                    <p>Duration :<span className='text-[#1f75bf]'> {session.duration} {session.duration > 1 ? 'Hrs' : 'Hr'}</span></p>
                                </div>
                            </>
                        }):"No Compensation"}
                    </div>


                </div>
            </div>

        </div>
    )
}

export default BatchInformation