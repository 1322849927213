import React, { useEffect, useState, CSSProperties } from "react";
import { ApplyjobCard } from "../elements/cards";
import applyiedjobData from "../../content/applyiedjobData.json";
import Header from "../elements/header";
import { useNavigate } from "react-router-dom";
// import { uuid } from "../../redux/useReducer";
import { getAppliedJobs } from "../../function/Student/job/jobs";
import { dateFormat } from "../elements/dateConvertion";
import ClipLoader from "react-spinners/ClipLoader";
import Footer from "../elements/Footer";

function Applyjob() {
  const uuid:any = localStorage.getItem('uid')
  const navigate = useNavigate();
  const [appliedjob, setAppliedjob] = useState({}) as any;
  const [jobstate, setJobstate] = useState(false);
  let [loading, setLoading] = useState(true);


const override: CSSProperties = {
    flex: 1,
    marginTop:240,
    justifyContent: 'center',
    alignItems:'center'

 

  };

  const [count, setCount] = useState(6);


  const handleClick = (props: any) => {
    navigate("/appliedjobdesc", { state: props });

  };
  const handleLoad = () => {
    let data_Lenght = appliedjob?.map((item: any) => {
      return item;
    });
    setCount(data_Lenght.length);
  };

  const data = async () => {
    setLoading(true)
    let value = await getAppliedJobs(uuid);
    setAppliedjob(value);
    setJobstate(true);
    setLoading(false)
  };
  useEffect(() => {
    data();
  }, []);

  return (
    <div>
      <Header />
      <p className=" text-left text-[30px] max-tablet:text-[30px] max-mobile:text-[20px] font-medium text-[#707070] ml-10 my-5">
        Applied Jobs
      </p>
      {jobstate ? (
        <div className="grid grid-cols-3 gap-4 p-10 max-tablet:p-10 max-mobile:p-2 max-laptop:grid-cols-2 max-mobile:grid-cols-1 ">
          {appliedjob?.slice(0, count).map((data: any, index: any) => {
            return (
              <div key={index} onClick={() => handleClick(data)}>
                <ApplyjobCard
                  subtitle={data?.job?.company}
                  classStyle="max-w-sm"
                  title={data?.job?.position}
                  status={data.status}
                  date={dateFormat(data?.inserted_at, " DD MMM YYYY")}
                  location={data?.job?.location}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <ClipLoader
            color="#036AD1"
            loading={loading}
            cssOverride={override}
          />
        </div>
      )}
      {
        appliedjob.length ? count > 6  ? <button
        onClick={handleLoad}
        className="text-white text-center  w-44  bg-gradient-to-r from-[#E91F00] via-[#E93C00] to-[#E95900]  font-medium rounded-md text-sm px-5 py-2.5 mb-10"
      >
        {" "}
        Load More{" "}
      </button>: '' 
      :<p className="text-[24px] max-tablet:text-[24px] max-mobile:text-[18px] mt-20">No Jobs Applied</p>
      }
     <Footer/>
    </div>
  );
}

export default Applyjob;
