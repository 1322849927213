import React, { useState, useRef, useEffect,CSSProperties } from "react";
import Header from "../elements/header";
import AllCourseData from "../../content/allCourseData.json";
import greaterthenicon from "../../images/greaterthenicon.svg";
import allcoursePage from "./allcoursePage";
import rightArrow from "../../images/rightArrow.svg";
import homepageData from "../../content/homepageData.json";
import Graterthansymbol from "../../images/Graterthansymbol.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  getCourseCategories,
  getTrendingCourses,
} from "../../function/Student/Courses/Courses";
import enroleImg from "../../images/enroleImg.svg";
import searchIcon from "../../images/searchIcon.svg";
import { StarRating } from "../elements/progressBar";
// import { uuid } from "../../redux/useReducer";
import ClipLoader from "react-spinners/ClipLoader";
import Footer from "../elements/Footer";

function SearchCourse() {
  const navigate = useNavigate();
  const scrollRef: any = useRef();
  const [trendcourseData, setTrendCourseData] = useState({}) as any;
  const [allcourseData, setAllCourseData] = useState({}) as any;
  const [search, setSearch] = useState("");
  const [coursestate, setCourseState] = useState(false);
  let [loading, setLoading] = useState(true);
  const uuid:any = localStorage.getItem('uid')
  const override: CSSProperties = {
    flex: 1,
    marginTop:240,
    justifyContent: 'center',
    alignItems:'center'
  };

  const data = async () => {
    setLoading(true)
    const course_data = await getTrendingCourses("Paid", uuid);
    setTrendCourseData(course_data);
    const allCourse = await getCourseCategories("Paid");
    setAllCourseData(allCourse);
    setCourseState(true);
    setLoading(false)
  };

  const handleChange = () => {
    navigate("/all-coursepage", { state: allcourseData });
  };
  const handleCourse = () => {
    navigate("/trending-course", { state: trendcourseData });
  };
  const handletrendCourse = (props: any) => {
    navigate(`/course/${props.course_name.replace(/[ /]/g, "-").toLowerCase()}`, {
      state: props,
    });
  };
  const convertToK = (number: number) => {
    if (number >= 1000) {
      const suffixes = ["", "k", "M", "B", "T"];
      const suffixIndex = Math.floor(Math.log10(number) / 3);
      const abbreviatedNumber = (number / Math.pow(1000, suffixIndex)).toFixed(
        1
      );
      return abbreviatedNumber + suffixes[suffixIndex];
    }
    return number.toString();
  };

  const handletech = (props: any) => {
    navigate(`/technologies-course/${props.category_name.replace(/ /g, "-")}`, {
      state: props.id,
    });
  };
  const handleFreeCourse = () => {
    navigate("/free-coursepage");
  };

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
    data();
  }, []);

  const searchresult = () => {
    if (search.length > 0) {
      return trendcourseData.filter((obj: any) =>
        obj.course_name.toLowerCase().includes(search.toLowerCase())
      );
    }
    return trendcourseData;
  };
  let length = 15
  return (
    <div>
      <Header />
      {loading == false? (
        <>
          <div className="flex justify-between pt-5 px-5 max-mobile:px-2">
            <p className=" text-[#707070] text-[30px] font-medium max-mobile:text-[22px]">
              Trending Courses
            </p>
            <p
              onClick={handleCourse}
              className="flex cursor-pointer text-[25px] max-tablet:text-[25px]  text-[#036AD1] font-medium max-mobile:text-[18px]"
            >
              View All{" "}
              <img
                className=" ml-2 mt-2 w-[15px] h-[15px] max-tablet:w-[15px] max-tablet:h-[15px] max-mobile:w-[8px] max-mobile:h-[8px]"
                src={greaterthenicon}
                alt="icon"
              />{" "}
            </p>
          </div>
          <div className="flex mx-auto justify-center items-center">
            <input
              placeholder="Search..."
              className=" mb-5 rounded-[3px] shadow-3xl text-[14px] text-[#036AD1] mt-5 p-2  w-3/6 outline-none placeholder-[#036AD1]"
              onChange={(e) => setSearch(e.target.value)}
            ></input>
            <img
              className="bg-[#036AD1] p-2 shadow-3xl  w-[40px] h-[40px] max-tablet:w-[40px] max-tablet:h-[40px] max-mobile:w-[30px] max-mobile:h-[35px] "
              src={searchIcon}
              alt="icon"
            />
          </div>
          <div className=" my-10">
            <div className="flex items-center px-5 mx-5 justify-between space-x-5  max-mobile:mx-1 max-mobile:px-1">
              <img
                src={rightArrow}
                alt="ScrollY"
                className="rotate-180 cursor-pointer max-mobile:hidden"
                onClick={() => (scrollRef.current.scrollLeft -= 1200)}
              />
              {coursestate ? (
                <div
                  className={`mx-auto flex space-x-5 overflow-x-auto scrollBar  snap-x snap-mandatory  scroll-smooth  `}
                  ref={scrollRef}
                >
                  {searchresult()
                    ?.slice(0, 5)
                    .map((item: any, index: any) => {
                      return (
                        <div
                          className=" cursor-pointer"
                          onClick={() => handletrendCourse(item)}
                        >
                        
                          <div className="  items-center  w-[280px]  object-cover bg-white border border-gray-200 rounded-lg shadow max-mobile:w-[70vw]">
                            <img
                              className="aspect-[5/3] mx-auto max-mobile:aspect-[8/4]"
                              // src={item?.thumbnail?JSON.parse(item.thumbnail)?.url:''}
                              src={item?.thumbnail.url}
                              alt="course thumbnil"
                            />
                            <p className="px-4 pt-3 border-t-2 text-left text-[19px] max-mobile:text-[14px] font-medium text-[#707070] rounded-t-3xl">
                              {item.course_name?.substring(0, length)}
                            </p>
                            <div className="ml-0 my-2 text-left px-4 flex items-center">
                              <p className=" font-bold text-[12px] text-[#000000]">
                                {" "}
                                {item.star_rating}
                              </p>
                              <StarRating rating=
                              {5}
                              // {item.star_rating} 
                              />
                            </div>
                            <div className="flex justify-between p-3 ">
                              <p className=" text-[#007AFF] text-[14px] flex">
                                <img
                                  className="w-[16px] h-[16px] mr-1"
                                  src={enroleImg}
                                  alt="enroleicon"
                                />{" "}
                                {convertToK(item.students_enrolled)} Enrolled
                              </p>
                              <p className=" text-[#007AFF] text-[14px] mr-3">
                                {item.duration} Hrs
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <div
                    onClick={handleCourse}
                    className=" flex cursor-pointer  items-center"
                  >
                    <p className="text-[24px] max-tablet:text-[24px] max-mobile:text-[16px] text-[#036AD1]  my-5 bg-[#ffffff] rounded-full shadow-3xl h-[130px] w-[130px] max-tablet:h-[130px] max-tablet:w-[130px] max-mobile:w-[100px] max-mobile:h-[100px] items-center text-center flex p-4 mx-20">
                      {" "}
                      View All
                      <img
                        className="pl-1"
                        src={Graterthansymbol}
                        alt="icon"
                      />{" "}
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <img
                src={rightArrow}
                alt="ScrollY"
                className=" cursor-pointer max-mobile:hidden"
                onClick={() => (scrollRef.current.scrollLeft += 1200)}
              />
            </div>
          </div>
          <div className=" bg-[#F9FCFF]">
            <div className="flex justify-between pt-3 px-5">
              <p className=" text-[#707070] text-[30px] max-tablet:text-[30px] font-medium max-mobile:text-[18px]">
                Explore All Courses 
              </p>
              <p
                className="flex cursor-pointer text-[25px] max-tablet:text-[25px] text-[#036AD1] font-medium max-mobile:text-[16px]"
                onClick={handleChange}
              >
                View All{" "}
                <img
                  className=" ml-2 mt-2 w-[15px] h-[15px] max-mobile:[8px] max-mobile:h-[8px]"
                  src={greaterthenicon}
                  alt="icon"
                />{" "}
              </p>
            </div>
            {coursestate ? (
              <div>
              {allcourseData.length>3 ?  <div className="grid grid-cols-5 h-4/6  px-20 max-laptop:grid-cols-3 max-mobile:grid-cols-2 max-mobile:px-2 ">
                {allcourseData.slice(0, 10).map((item: any) => {
                  return (
                    <div
                      key={item.id}
                      onClick={() => handletech(item)}
                      className="flex flex-col items-center max-tablet:flex cursor-pointer justify-center m-4 w-[200px] h-[200px] max-tablet:h-[200px] rounded-md bg-[#FFFFFF] max-desktop:w-[170px] max-desktop:h-[170px] max-mobile:w-[46vw] max-mobile:m-1 mx-auto"
                    >
                   
                      <img
                        className="flex mx-auto justify-center items-center w-auto aspect-auto  h-[50px] "
                        src={item.thumbnail}
                        alt="icon"
                      />
                      <p className=" text-center mt-3 text-[17px] text-[#000000] font-normal max-desktop:text-[16px] max-laptop:text-[12px]">
                        {item.category_name}
                      </p>
                      <p className="text-[#4E4E4E]">
                        {item.no_of_courses} Courses
                      </p>
                    </div>
                  );
                })}
              </div> :  <div className="flex max-mobile:flex-wrap  max-tablet:px-20 max-mobile:px-8 item-center h-4/6 maxtab px-20 mx-auto mb-5">
                {allcourseData.slice(0, 10).map((item: any) => {
                  return (
                    <div
                      key={item.id}
                      onClick={() => handletech(item)}
                      className="flex flex-col items-center  cursor-pointer justify-center m-4 w-[250px] h-[200px] max-mobile:h-[150px] max-mobile:mr-3 max-mobile:w-[35vw] max-tablet:w-[250px] max-tablet:h-[200px]  rounded-md bg-[#FFFFFF] max-desktop:w-[190px] max-desktop:h-[170px] max-mobile:m-1 mx-auto"
                    >
                      <img
                        className="flex mx-auto justify-center items-center w-auto aspect-auto h-[60px] max-tablet:h-[60px] max-mobile:w-[70px] max-mobile:h-[50px] max-mobile:aspect-8/3"
                        src={item.thumbnail}
                        alt="icon"
                      />
                      <p className=" text-center mt-3 text-[17px] text-[#000000] font-normal max-desktop:text-[16px] max-laptop:text-[12px]">
                        {item.category_name}
                      </p>
                      <p className="text-[#4E4E4E] max-mobile:text-[14px]">
                        {item.no_of_courses} Courses
                      </p>
                    </div>
                  );
                })}
              </div>}
             
              </div>
            ) : (
              <div></div>
            )}
            <div style={{backgroundSize:'100% 100%'}} className="bg-[url('../public/images/freeCourseBg.png')] bg-cover  pl-24 max-mobile:pl-10 h-[100vh] max-tablet:h-[100vh] max-mobile:h-[50vh]">
              <div className="flex flex-col pt-52 max-tablet:pt-52 max-mobile:pt-20  item-center">
                <p className="text-[91px]  text-left text-[#FFFFFF] font-bold max-desktop:text-[62px] max-laptop:-text-[55px] max-laptop:text-[40px] max-tablet:text-[36px] max-mobile:text-[25px]">
                  Explore Free Courses <br /> by FITA Academy
                </p>
                <p
                  onClick={() => handleFreeCourse()}
                  className="text-white text-center mt-5 w-44  bg-gradient-to-r from-[#E91F00] via-[#E93C00] to-[#E95900]  font-medium rounded-md text-[20px] px-5 py-2.5 mb-2 max-mobile:w-32 max-mobile:text-[14px] cursor-pointer"
                >
                  {" "}
                  View Courses
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
       
          <ClipLoader
            color="#036AD1"
            loading={loading}
            cssOverride={override}
          />
      
      )}
<Footer/>

    </div>
  );
}

export default SearchCourse;
