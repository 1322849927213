import React, { useEffect, useState, CSSProperties } from 'react'
import Header from '../elements/header'
import notificationData from '../../content/notificationData.json'
import { NotificationCard } from '../elements/cards'
import { getPushContentByStudentUid } from '../../function/Student/pushNotification/PushContent'

import parse from 'html-react-parser'
import searchIcon from '../../images/searchIcon.svg'
// import { uuid } from '../../redux/useReducer'
import { useLocation } from 'react-router-dom'
import ClipLoader from "react-spinners/ClipLoader";
import Footer from '../elements/Footer'


function Notificationpage() {
    const uuid:any = localStorage.getItem('uid')
    let lengthcount = 0
    const { state } = useLocation()
    const [content] = useState(state)
    const [courseData, setCourseData] = useState({}) as any
    const [notificationState, setNotificationState] = useState(false)
    const [loadmorebtn,setloadmorebtn] = useState(true)

    const [search, setSearch] = useState('')
    let [loading, setLoading] = useState(false)

    const override: CSSProperties = {
        flex: 1,
        marginTop: 240,
        justifyContent: 'center',
        alignItems: 'center'
    };
    let data_Lenght;
    const searchresult = () => {
        if (search.length > 0) {
            return courseData?.filter((obj: any) => obj.title.toLowerCase().includes(search.toLowerCase()))
        }
        return courseData
    }
    const data = async () => {
        // setloadmorebtn()
        setLoading(true)
        const pushconentall : any = await getPushContentByStudentUid(uuid)
        setLoading(false)
        let pushconent = pushconentall
        console.log(pushconentall,'pushconentall')
        // if(pushconentall){
        //      pushconent = pushconentall.filter((el: any) => ( state == el.content_type))
        // }
        setCourseData(pushconent)
        setNotificationState(true)
        data_Lenght = await Object.values(courseData).length ? courseData?.map((item: any) => {
            return (lengthcount += 1);
        }) : 0;
    }

    const [count, setCount] = useState(6)

    const handleLoad = () => {
        let data_Lenght = courseData?.map((item: any) => { return item })
        setCount(data_Lenght?.length)
        setloadmorebtn(false)
    }

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0
        })
        data()
        courseData.length > count ? setloadmorebtn(true) : setloadmorebtn(false)
    }, [state])
    return (
        <div className='bg-[#F9FCFF] min-h-screen'>
            <Header />
            {loading == false ?
                <div>
                    <div className='flex mx-auto justify-center items-center'>
                        <input placeholder='Search...' className=' mb-5 rounded-[3px] shadow-3xl text-[14px] text-[#036AD1] mt-5 p-2  w-3/6 outline-none placeholder-[#036AD1]' onChange={(e) => setSearch(e.target.value)}></input>
                        <img className='bg-[#036AD1] p-2 shadow-3xl  w-[45px] h-[40px]' src={searchIcon} alt='icon' />
                    </div>
                    {notificationState ?
                        <div>

                            {courseData?.length === 0 ? <div> <p>No Resource Available</p> </div>
                                : 3 < lengthcount && count > 1 ?
                                    <div className="flex mt-5 mx-auto justify-center max-laptop:block max-mobile:block">

                                        {searchresult() ? searchresult()?.slice(0, count)?.map((data: any) => {
                                            return <div className='w-4/12'>
                                            
                                                <NotificationCard title={data.title} image={data?.thumbnail?.url} desc={data?.text_message} subType={data?.sub_type} content_type={data?.content_type} data={data} />
                                            </div>
                                        }) : <div className='  w-11/12 text-center'> No Data</div>}
                                    </div> : <div className={`${searchresult() ? 'grid grid-cols-3 gap-4 max-laptop:grid-cols-2 max-mobile:grid-cols-1' : ''} items-center mx-auto w-11/12 p-10 max-mobile:px-2 `}>
                                        {searchresult() ? searchresult()?.slice(0, count)?.map((data: any) => {
                                            return <div >
                                                
                                                <NotificationCard title={data.title} image={data?.thumbnail?.url} desc={data?.text_message} subType={data?.sub_type} content_type={data?.content_type} data={data} />
                                            </div>
                                        }) : <div className='text-center'> No Data</div>}
                                    </div>}
                        </div> : <></>
                    }
                    {loadmorebtn? <button onClick={handleLoad} className="text-white text-center mt-5   w-44  bg-gradient-to-r from-[#E91F00] via-[#E93C00] to-[#E95900] font-medium rounded-md text-[20px] px-5 py-2.5 mb-2" > Load More </button> : ''}
                </div>
                : <>
                    <ClipLoader
                        color="#036AD1"
                        loading={loading}
                        cssOverride={override}
                    />
                </>}
                <Footer/>

        </div>
    )
}

export default Notificationpage; 