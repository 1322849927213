import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../elements/header'
import ReactPlayer from 'react-player';
import time from '../../images/stopClock.svg'
import { getCourseVideosById, updateVideoDurationRecord } from '../../function/Student/Courses/Courses';
import { Progressbar } from '../elements/progressBar';
import { update } from 'lodash';
import Footer from '../elements/Footer';

let progress_by_module = {} as any

export default function SingleVideo() {

    const navigate = useNavigate()
    const { state } = useLocation()
    const [selectCourse] = useState(state)
    const [runTime, setRunTime] = useState() as any
    const progressRef = useRef<number>(0);
    const Ref = useRef<any>();
    const [loadState, setloadState] = useState(true)
    const [courselist, setCourselist] = useState({}) as any
    const [playerDetails, setPlayerDetails] = useState({}) as any
    const [ListArr, setListArr] = useState({}) as any
    const [timeToStart, SetTimetostart] = useState()
    const [isPlaying, setIsPlaying] = React.useState(true);
    const [isReady, setIsReady] = React.useState(false);
    const [pagereload, setpagereload] = useState(0)
    const playerRef = React.useRef();

    const onReady = React.useCallback((props: any) => {
        if (!isReady) {
            SetTimetostart(props.key in progress_by_module == false ? 0 : progress_by_module[props.key])
            Ref.current.seekTo(props.key in progress_by_module == false ? 0 : progress_by_module[props.key], "seconds");
            setIsReady(true);
        }
    }, [isReady]);


    const progressHandler = (event: any) => {
        if (isPlaying) {
            progressRef.current = Math.floor(event.playedSeconds);

            setRunTime(progressRef.current)
        }
        else {
            if (timeToStart && timeToStart < Math.floor(event.playedSeconds)) {
                Ref.current.seekTo(runTime);
            }
        }

    }
    const seekingHandler = (event: any) => {
        {
            var delta = event - runTime;
            if (timeToStart && timeToStart < event) {
                if (delta > 0.01) {
                    Ref.current.seekTo(runTime);
                }
            }

        }
    }


    const updata = async () => {
        let to_seconds = toSeconds(playerDetails.duration)
        localStorage.setItem('visited', 'false')
        const courselist = await getCourseVideosById(selectCourse?.allCourse)
        progress_by_module = courselist?.progress_by_module
        if (timeToStart != undefined && progressRef.current > timeToStart && to_seconds >= progressRef.current) {
            progress_by_module[playerDetails.key] = progressRef.current
            const res = await updateVideoDurationRecord({ progress_by_module: progress_by_module }, state.allCourse);
            SetTimetostart(progress_by_module[playerDetails.key])
            const courselist = await getCourseVideosById(selectCourse?.allCourse)
            progress_by_module = courselist?.progress_by_module
            setCourselist(courselist)
        }
    }


    const secondsToHms = (d: any) => {
        d = Number(d)
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`
    }

    function toSeconds(seconds: string) {
        if (seconds === '') {
            return 0
        }
        var a = `${seconds}`?.split(':');
        let value = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2])
        return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    }

    const currentTime = secondsToHms(runTime)

    const data = async () => {
        setPlayerDetails(selectCourse?.selectCourse)

        const courselist = await getCourseVideosById(selectCourse?.allCourse)
        progress_by_module = courselist?.progress_by_module

        SetTimetostart(selectCourse?.selectCourse.key in progress_by_module == false ? 0 : progress_by_module[selectCourse?.selectCourse.key])

        setCourselist(courselist)

        durationCal(selectCourse?.selectCourse)
        setVideoListData(courselist?.course?.videos)
        setloadState(true)
    }

    const durationCal = (props: any) => {
        toSeconds(props?.duration)
    }
    const [VideoListData, setVideoListData] = useState([]) as any;


    window.addEventListener('popstate', (event) => {

        updata()
        localStorage.setItem('visited', 'yes')
    }, false);

    const playVideo = () => {
        setIsPlaying(true)


    }
    window.addEventListener('beforeunload', function (e) {
        e.preventDefault();
        localStorage.setItem('visited', 'yes')
        e.returnValue = "Are you Sure want to Exit.";
        setpagereload(pagereload + 1)

        updata()

    }, false);

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0
        })
        data()
    }, [])

    const endVideo = () => {
        let to_seconds = toSeconds(playerDetails.duration)
        progressRef.current = to_seconds
        updata()
    }

    const handleClick = (props: any) => {
        setPlayerDetails(props)
        navigate('/single-videos', { state: { allCourse: selectCourse?.allCourse, selectCourse: props } })

    }
    let prev: any = null;

    return (
        <div>
            <Header />
            {loadState ? <div className='mt-10 mb-20 mx-auto w-8/12 max-tablet:w-10/12  '>
                <div>

                    <ReactPlayer
                        ref={Ref}
                        onEnded={endVideo}
                        url={playerDetails?.link}
                        onPause={() => {
                            updata();

                            setIsPlaying(false)
                        }}
                        playing={isPlaying}
                        onPlay={playVideo}
                        controls={true}
                        config={{ file: { attributes: { id: 'audio-element', controlsList: 'nodownload noplaybackrate' } } }}
                        width='100%'
                        onProgress={progressHandler}
                        onSeek={(e) => {
                            seekingHandler(e)

                        }}
                        className='h-[400px] max-tablet:h-[300px] max-mobile:h-[300px]'
                        onReady={() => onReady(playerDetails)}
                    />

                </div>
                <div className='flex shadow-3xl justify-between w-full p-2 rounded-lg my-2 text-[#FFFFFF] items-center'>
                    <p className=' text-[#036AD1] text-[23px] font-bold pl-2'>{playerDetails?.title} </p>
                    <div className='flex items-center'>
                        <img src={time} alt='time' className=' w-[22px] h-[22px] mr-2 ' />
                        <p className='text-[#707070] text-[16px] '>{currentTime}/{playerDetails?.duration}</p>
                    </div>
                </div>

                <div className='mt-5 grid grid-cols-2 gap-3 max-mobile:grid-cols-1 '>
                    {courselist?.course?.videos?.map((item: any) => {
                        prev = (courselist?.course?.videos).indexOf(item)
                        return <div>
                            {(prev == 0 || courselist?.course?.videos[prev - 1].progress === 100) ? <div className='flex mx-auto shadow-3xl rounded-md  justify-between px-6 mt-6 items-center cursor-pointer' onClick={() => handleClick(item)}>
                                <div className='text-left py-5' key={item.key}>
                                    <p className='text-[#036AD1] text-[26px] max-laptop:text-[22px] font-medium'>{item.title}</p>
                                    <div className='flex pt-5 items-center'>
                                        <img src={time} alt='time' className=' w-[22px] h-[22px] mr-2 ' />
                                        <p className=' text-[#707070] text-[22px]'>{item.duration} Hrs</p>
                                    </div>
                                </div>
                                <div className='py-2'><Progressbar percentage={item.progress ? item.progress : 0} className='w-[100px] h-[100px] max-tablet:w-[100px] max-mobile:w-[25vw]' />
                                </div>
                            </div> : <div className='flex mx-auto shadow-3xl rounded-md  justify-between px-6 mt-6 items-center cursor-pointer' >
                                <div className='text-left py-5' key={item.key}>
                                    <p className='text-[#036AD1] text-[26px] max-laptop:text-[22px] font-medium'>{item.title}</p>
                                    <div className='flex pt-5 items-center'>
                                        <img src={time} alt='time' className=' w-[22px] h-[22px] mr-2 ' />
                                        <p className=' text-[#707070] text-[22px]'>{item.duration} Hrs</p>
                                    </div>
                                </div>
                                <div className='py-2'><Progressbar percentage={item.progress ? item.progress : 0} className='w-[100px] h-[100px] max-mobile:w-[25vw]' />
                                </div>
                            </div>}
                        </div>
                    })}
                </div>
            </div> : <div ></div>

            }
<Footer/>

        </div>

    )
}