import { errorNotifier } from "../../commonHelper"
import { TableNames } from "../../config/Tables";
import { FetchData } from "../../crud";
import { calculatePaidCourseCompletion } from "../job/helper";

export const getResources = async(userId: string) => {
    try {
        const { data: resource_category, error } = await FetchData(TableNames.resource_category);
        if (error) throw error;
        return resource_category;
    } catch (error) {
        return errorNotifier(error); 
    }
}

export const getIndividualResource = async (resourceId: number | string, studentId: string) => {
    try {
        const { data: training_data, error: training_error }: any = await FetchData(TableNames.trainings, ["*", "course:courses(id, type)"]).eq("student_id", studentId).order("id", { ascending: false })
        const filtered_training = training_data?.filter((el: any) => (el.course.type === "Paid"))
        const { data: technology }: any = await FetchData(TableNames.technologies, ["id"]).contains("courses", [filtered_training[0]?.course?.id])  
        const { data: resources, error } = await FetchData(TableNames.resources).eq("resource_category", resourceId)
        if (error) throw error
        return resources
    } catch (error) {
        return errorNotifier(error)
    }
} 


// export const getPendingPaymentsTableData = async (UsersDetails: any) => {
//     try {
//         const { data: TrainingData, error } = await FetchData(TableNames.trainings,
//              ["course:courses(course_name,videos)",
//              "student:students(uid, name,phoneNumber)", "batch:batches(id,schedule,status,trainer:users(first_name,last_name,phone_number),course:courses(course_name,duration),sessions:sessions(*))",
//               "status", "total_fee", "trainer:users(first_name,last_name,phone_number)", "balance_due",
//               "crm:users(first_name,last_name,phone_number)","id","city:cities(city_name,id)","branch:branches(id,branch_name)"])
//               .gt("balance_due",0).in("branch_id", UsersDetails);
              
//         if (error) throw error;
//         const alteredData = TrainingData?.map((training:any) => ({
//             ...training,
//             course_name: training?.course?.course_name,
//             student_name: training?.student?.name,
//             student_number: training?.student?.phoneNumber,
//             schedule: training?.batch?.schedule,
//             trainer_name: (training?.batch?.trainer?.first_name || "") + " " + (training?.batch?.trainer?.last_name || ""),
//             trainer_number: training?.batch?.trainer?.phone_number,
//             batch_status: training?.batch?.status,
//             total_fee: Math.round(Number(training?.total_fee)),
//             balance_due: Math.round(Number(training?.balance_due)),
//             total_paid: Math.round(Number(training?.total_fee - training?.balance_due)),
//             crm_name: (training?.crm?.first_name || "") + " " + (training?.crm?.last_name || ""),
//             city_name: training?.city?.city_name,
//             city_id: training?.city?.id,
//             branch_name: training?.branch?.branch_name,
//             branch_id:training?.branch?.id,
//             // payment_completion: Math.floor(((training?.total_fee - training?.balance_due) / training?.total_fee) * 100) + "%",
//             payment_completion: training?.batch ? calculatePaidCourseCompletion(training?.batch?.course?.duration, training?.batch?.sessions) : 0,
//             city: null,
//             branch: null,
//             batch: null,
//             course: null,
//             trainer: null,
//             student: null,
//             crm: null
//         }));
//         return alteredData;
//     } catch (error) {
//         return errorNotifier(error);
//     }
// }
 
// export const getBatchPendingPaymentsTableData = async (UsersDetails: any) => {
//     try {
        
//         const { data, error } = await FetchData(TableNames.batches, ["id", "batch_code", "status", "batch_progress_in_hrs", "duration", "trainer:users(first_name,last_name,phone_number)", "trainings(total_fee,crm:users(first_name,last_name),transactions(paid_amount))"]).in("branch_id", UsersDetails);
//         if (error) throw error
//         return formatPendingBatchPayments(data);
//         // if (err) throw err;
//     } catch (error) {
//         return errorNotifier(error);
//     }
// }

// export const formatPendingBatchPayments = (batchData:any[]) => {
//     const result = batchData.map((bd) => {
//         let batchSize = bd?.trainings?.length;
//         let total_fee = 0;
//         let total_paid = 0;
//         let crmName = null;
//         let batchProgress = Number((bd?.batch_progress_in_hrs ?? "0"));
//         let batchCompletionPercentage = Math.round((batchProgress / bd?.duration) * 100);
//         bd?.trainings?.forEach((tr:any) => {
//             total_fee += Number(tr.total_fee)
//             crmName = nullConversion(tr?.crm?.first_name) +" "+ nullConversion(tr?.crm?.last_name)
//             total_paid=tr?.transactions?.reduce((total:number,fee:any)=>Math.round(total+Number(fee?.paid_amount)),0)
//             // batchSize+=1
//         });
//         let balance_due = Math.round(Number(total_fee - total_paid));
//         let trainerName = nullConversion(bd?.trainer?.first_name) +" "+ nullConversion(bd?.trainer?.last_name);
//         let trainerNumber = bd?.trainer?.phone_number ||null;
//         delete bd?.trainings;
//         delete bd?.trainer;
//         delete bd?.batch_progress_in_hrs;
//         delete bd?.duration;
//         return {
//             ...bd,
//             batchSize,
//             total_fee,
//             total_paid,
//             crmName,
//             balance_due,
//             trainerName,
//             trainerNumber,
//             batchCompletionPercentage
//         }
 
//     });
//     // console.log(result, "result");
//     return result;
// }