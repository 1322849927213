import React from 'react';

import './App.css';

import Layout from './component/layout/layout';
import { Toaster } from 'react-hot-toast';

function App() {
  return (

   
    <div className="App font-[roboto]">
      
        <Toaster/>
        <Layout />
    </div>
  );
}

export default App;
