import React, { useEffect, useRef, useState, CSSProperties } from 'react'
import rightArrow from '../../images/rightArrow.svg'
import homepageData from '../../content/homepageData.json'
import Graterthansymbol from '../../images/Graterthansymbol.svg'
import { Link, useNavigate } from 'react-router-dom';
import { getPushContentByStudentUid } from '../../function/Student/pushNotification/PushContent';
// import { uuid } from '../../redux/useReducer';
import ClipLoader from "react-spinners/ClipLoader";

function DreamJob() {
    const scrollRef: any = useRef();
    const uuid:any = localStorage.getItem('uid')
    const navigate = useNavigate()
    const [courseData, setCourseData] = useState({}) as any
    const [courseState, setCoursestate] = useState(false)
    let [loading, setLoading] = useState(true);
    const handleClick = () => {
        navigate('/notificationpage', { state: "Predefined Push Content" })
    }

    const data = async () => {
        setLoading(true)
        const pushconent:any = await getPushContentByStudentUid(uuid)
        setCourseData(pushconent)
        setLoading(false)
        if(pushconent == false || !pushconent.length){
            setCoursestate(true)
        }
    }
    const override: CSSProperties = {
        flex: 1,
        marginTop: 240,
        justifyContent: 'center',
        alignItems: 'center'
    };


    const fileterData = () => {
        // console.log(courseData, courseData.filter((obj: any) => obj.content_type.toLowerCase().includes('Predefined')))
        return courseData?.filter((obj: any) => obj.content_type === "Predefined Push Content")
    }

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0
        })
        data()
    }, [])
    // console.log('courseData', courseData)



    const CardDetail = (props: any) => {
        let data: any = {
            title: props.title,
            image: props.thumbnail.url,
            desc: props.text_message,
            subType: props.sub_type,
            data: props,
            content_type: props.content_type
        }

        navigate('/notificationdetails', { state: data })

    }

    return (

        <div className=' mb-10'>
            {loading == false? <div className="flex items-center px-5 mx-5 justify-between space-x-5  max-mobile:px-2 max-mobile:mx-2">
                <img
                    src={rightArrow}
                    alt="ScrollY"
                    className="rotate-180 cursor-pointer max-mobile:hidden"
                    onClick={() => (scrollRef.current.scrollLeft -= 1200)}
                />
                {courseState ? <div className={`mx-auto flex h-[20vh] space-x-5 overflow-x-auto scrollBar  snap-x snap-mandatory items-center  scroll-smooth  `} ref={scrollRef}>

                    No Content
                </div> :  <div className={`mx-auto flex space-x-5 overflow-x-auto scrollBar  snap-x snap-mandatory  scroll-smooth  `} ref={scrollRef}>
                    {courseData?.slice(0, 4)?.map((item: any, index: any) => {
                       return <div key={index} className='flex  my-5 space-x-5' onClick={() => CardDetail(item)}>
                            <div className={` w-[320px]  max-mobile:w-[80vw] gap-3 border-2  `}  >
                                <img className='flex  mx-auto justify-center aspect-[5/3] h-[220px] max-mobile:w-[80vw]' src={item.thumbnail.url} alt='icon' />
                            </div>
                        </div>
                    })}
                    <div className=' flex  items-center'>
                        <p onClick={handleClick} className='text-[24px] max-tablet:text-[24px] max-mobile:text-[16px] text-[#036AD1]  my-5 bg-[#ffffff] rounded-full shadow-3xl h-[130px] w-[130px] max-tablet:w-[130px] max-tablet:h-[130px] max-mobile:w-[100px] max-mobile:h-[100px] items-center text-center flex p-4 mx-20 cursor-pointer'> View All<img className='pl-1' src={Graterthansymbol} alt='icon' /> </p>
                    </div>
                </div> }
               
                <img
                    src={rightArrow}
                    alt="ScrollY"
                    className=" cursor-pointer max-mobile:hidden"
                    onClick={() => (scrollRef.current.scrollLeft += 1200)}
                />
            </div> : <ClipLoader
                color="#036AD1"
                loading={loading}
                cssOverride={override}/>
                }

        </div>
    )
}
export default DreamJob