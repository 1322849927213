import React,{useState} from 'react'
import Cards from '../elements/cards'

function CourseStartPage(props:any) {
  
  var cardValue= props.flag.status

  return (
    <div className='bg-[#F9FCFF] h-[88vh] flex items-center'>
       {cardValue === 'no_classes' ? <Cards value={`Your classes are tentatively scheduled on "${props.flag.start_date} & ${props.flag.start_time}".Happy Learning`} /> : <Cards value='Our Career Consultants are working on arraning classes for you. Contact your branch coordinator if you have any queries'/>} 
    </div> 
  )
}

export default CourseStartPage