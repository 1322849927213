import { configureStore } from "@reduxjs/toolkit";
import sideNavPosition from "./Reducers/sideNavPosition";
import loggedInUserDetails from "./Reducers/loggedInUserDetails";
import userSelectedRoles from "./Reducers/userSelectedRoles";
import transactionIDS from "./Reducers/transactionIDS";
import signInUserData from "./Reducers/signInUserData";
import applicantsView from "./Reducers/applicantsView";
import courseProgressModal from "./Reducers/courseProgressModal";
import useReducer from "../../../redux/useReducer";

export const store = configureStore({
  reducer: {
    navStatus: sideNavPosition,
    login: loggedInUserDetails,
    roles: userSelectedRoles,
    transaction: transactionIDS,
    signInInput: signInUserData,
    applicants: applicantsView,
    courseprogress: courseProgressModal,
    user: useReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type State = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;
