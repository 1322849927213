import React, { useEffect, useState, CSSProperties } from "react";
import { ApplyjobCard } from "../elements/cards";
import applyiedjobData from "../../content/applyiedjobData.json";
import Header from "../elements/header";
import WhiteLock from "../../images/White_lock.svg";
import { useNavigate } from "react-router-dom";
// import { uuid } from "../../redux/useReducer";
import { dateFormat } from "../elements/dateConvertion";
import { getProfileDetails } from "../../function/Student/Profile/Profile";
import { checkEligiblityForPlacementAndGetJobData, getJobsForTheStudent } from "../../function/Student/job/jobs";
import ClipLoader from "react-spinners/ClipLoader";
import Footer from "../elements/Footer";

function Latestjob() {
  const uuid:any = localStorage.getItem('uid')
  const navigate = useNavigate();
  const [placedJob, setPlacedJob] = useState() as any;
  const [eligibility, setEligibility] = useState({}) as any;
  const [jobstate, setJobstate] = useState(false);
  let [loading, setLoading] = useState(true);
  const override: CSSProperties = {
    flex: 1,

    marginTop: 240,

    justifyContent: "center",

    alignItems: "center",
  };

  const handleClick = (props: any) => {
    navigate("/latestjobdesc", { state: props });
  };

  const [count, setCount] = useState(6);
  // const []

  const handleLoad = () => {
    let data_Lenght = eligibility?.data?.map((item: any) => {
      return item;
    });
    setCount(data_Lenght.length);
  };

  const data = async () => {
    setLoading(true);

    let placementData = await getProfileDetails(uuid);
    setPlacedJob(placementData);
    let dataValue: any = await checkEligiblityForPlacementAndGetJobData(uuid);
    // let dataValue:any = await getJobsForTheStudent(uuid)
    setEligibility(dataValue);
    setJobstate(true);
    setLoading(false);
  };

  useEffect(() => {
    data();
  }, []);
  return (
    <div>
      <Header />
      {jobstate ? (
        <div>
          {placedJob?.placement_need ? (
            <div>
                 {/* {true ? ( */}
              {eligibility?.status?(
            
                <div>
                  <div className="grid grid-cols-3 gap-4 p-10 max-laptop:grid-cols-2 max-mobile:grid-cols-1">
                    {eligibility?.data
                    //  {eligibility
                      ?.slice(0, count)
                      ?.map((data: any, index: number) => {
                        return (
                          <div key={index} onClick={() => handleClick(data)}>
                            <ApplyjobCard
                              subtitle={data?.company}
                              classStyle="max-w-sm"
                              title={data?.position}
                              status=""
                              date={dateFormat(
                                data?.inserted_at,
                                "DD MMM YYYY"
                              )}
                              location={data?.location}
                            />
                          </div>
                        );
                      })}
                  </div>
                  {count > 6 ?
                   <button
                   onClick={handleLoad}
                   className="text-white text-center  w-44  bg-gradient-to-r from-[#E91F00] via-[#E93C00] to-[#E95900]  font-medium rounded-md text-[20px] px-5 py-2.5 mb-10"
                 >
                   {" "}
                   Load More{" "}
                 </button> : ''
                  }
                 
                </div>
              ) : (
                <div>
                  {eligibility.message == "payment-not-completed" ||
                  eligibility.message == "progress-not-completed" ? (
                    <div className=" flex max-monitor:h-[94vh] max-laptop:h-[84vh] max-desktop:h-[90vh] max-tablet:h-[88vh] max-mobile:h-[76vh] bg-[#F9FCFF] items-center align-middle ">
                      <div className=" align-middle mx-auto text-center relative w-[500px] max-tablet:w-[500px] max-mobile:w-[90vw] p-10 justify-center bg-white border border-gray-200 rounded-lg shadow">
                        <div className="flex py-10 max-tablet:py-10 max-mobile:py-2">
                          <div className="w-[40%]">
                            <img
                              className="w-[150px] h-[112px] max-tablet:w-[150px] max-tablet:h-[112px] max-mobile:w-[40px]"
                              src={WhiteLock}
                            ></img>
                          </div>
                          <p className="w-[60%] max-tablet:w-[60%] max-mobile:w-[90%] text-[#000000] text-[18px] max-tablet:text-[18px] max-mobile:text-[14px] text-left ml-5">
                            Jobs section will be enabled after you complete the
                            Balance payment and completing at least 90% Course
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className=" flex min-h-screen bg-[#F9FCFF] items-center align-middle ">
                      <div className=" align-middle mx-auto text-center relative w-[450px] p-10 justify-center bg-white border border-gray-200 rounded-lg shadow">
                        <p className=" text-[#000000] text-[18px] ">
                          You have not applied for any job openings in the last
                          90 days. If you stilll need placement support, please
                          speak to our HR
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className=" flex min-h-screen bg-[#F9FCFF] items-center align-middle ">
              <div className=" align-middle mx-auto text-center relative w-[450px] p-10 justify-center bg-white border border-gray-200 rounded-lg shadow">
                <p className=" text-[#000000] text-[18px] ">
                  no placement support
                </p>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <ClipLoader
            color="#036AD1"
            loading={loading}
            cssOverride={override}
          />
        </div>
      )}
<Footer/>
      
    </div>
  );
}

export default Latestjob;

// <div>
//   <Header />
//   {jobstate ?
//     <div>
//       {!eligibility.isAppliedIn90Days ? <div>
//         <p className=' text-left text-[30px] font-medium text-[#707070] ml-10 my-5'>Latest Job Openings</p>
//         {
//           eligibility.status  ?
//             <div className=' flex h-[80vh] bg-[#F9FCFF] items-center align-middle '>
//               <div className=' align-middle mx-auto text-center relative  w-[519px] py-10 justify-center p-6 bg-white border border-gray-200 rounded-lg shadow max-mobile:w-[85%]' >
//                 <div className='my-10'>
//                   <div className='flex'>
//                     <div className='w-[40%]'>
//                       <img className='w-[150px] h-[112px]' src={WhiteLock}></img>
//                     </div>
//                     <p className='w-[60%] text-[#000000] text-[18px] text-left ml-5'>Jobs section will be enabled after you complete the Balance payment and completing at least 90% Course</p>
//                   </div>
//                 </div>
//               </div>
//             </div> :
//             <div>
//               {
//                 !placedJob?.placement_need ?
//                   <div className=' flex h-[80vh] bg-[#F9FCFF] items-center align-middle '>
//                     <div className=' align-middle mx-auto text-center relative h-[416px] w-[519px] py-10 justify-center p-6 bg-white border border-gray-200 rounded-lg shadow' >
//                       <div className='mt-[20%]'>
//                         <div className='flex'>
//                           <div className='w-[40%]'>
//                             <img className='w-[150px] h-[112px]' src={WhiteLock}></img>
//                           </div>
//                           <p className='w-[60%] text-[#000000] text-[18px] text-left ml-5'>You already got placed through FITA Academy & Joined the company. If you still need placement support, kindly speak to our HR</p>
//                         </div>
//                       </div>
//                     </div>
//                   </div> :
//                   <div>
//                     <div className='grid grid-cols-3 gap-4 p-10 max-laptop:grid-cols-2 max-mobile:grid-cols-1'>
//                       {jobList?.map((data: any, index: number) => {
//                         return <div key={index} onClick={() => handleClick(data)}>
//                           <ApplyjobCard subtitle={data?.company} classStyle='max-w-sm' title={data?.position} status='' date={dateFormat(data?.inserted_at, 'DD MMM YYYY')} location={data?.location} />
//                         </div>
//                       })}
//                     </div>
//                     <button onClick={handleLoad} className="text-white text-center  w-44  bg-gradient-to-r from-[#E91F00] via-[#E93C00] to-[#E95900]  font-medium rounded-md text-[20px] px-5 py-2.5 mb-10" > Load More </button>
//                   </div>
//               }
//             </div>
//         }
//       </div> : <div className=' flex min-h-screen bg-[#F9FCFF] items-center align-middle '>
//         <div className=' align-middle mx-auto text-center relative w-[450px] p-10 justify-center bg-white border border-gray-200 rounded-lg shadow' >
//             <p className=' text-[#000000] text-[18px] '>You have not applied for any job openings in the last 90 days. If you stilll need placement support, please speak to our HR</p>
//           </div>
//       </div>}

//     </div> : <div></div>}
// </div>
