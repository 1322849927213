// import { useState, useEffect } from "react"
// import React from 'react'
// import OtpInput from "./otpinput"
// import circlRrightArrow from '../../images/circlRrightArrow.png'
// import { Navigate } from "react-router-dom";

// function OtpScreen() {
//     const [counter, setCounter] = useState(30);
//     const [otpnumber, setOtpnumber] = useState({
//         first: '',
//         second: '',
//         third: '',
//         four: ''
//     } as any)

//     const handleInputChange = (e: React.FormEvent<HTMLInputElement>, key: string) => {
//         if (key === 'first' && ((e.currentTarget.value).length === 1)) {
//             setOtpnumber({ first: e.currentTarget.value })
//         } else if (key === 'second' && ((e.currentTarget.value).length === 1))  {
//             setOtpnumber({ second: e.currentTarget.value })
//         } else if (key === 'third' && ((e.currentTarget.value).length === 1)) {
//             setOtpnumber({ third: e.currentTarget.value })
//         } else if (key === 'four' && ((e.currentTarget.value).length === 1)) {
//             setOtpnumber({ four: e.currentTarget.value })
//         }
//     }

//     useEffect(() => {
//         var timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
//         return () => clearInterval(timer);
//     }, [counter]);

//     return (
//         <div>
//             <div className="text-[65px] text-[#036AD1] font-[roboto] font-bold mt-40">Verify Your Mobile</div>
//             <div className="text-[31px] text-[#707070] font-[roboto] font-medium mt-5">Enter your OTP code here</div>
//             <div className="flex text-center justify-center">
//                 <OtpInput value={otpnumber.first} valueLength={1} onChange={(e) => handleInputChange(e, 'first')} />
//                 <OtpInput value={otpnumber.second} valueLength={1} onChange={(e) => handleInputChange(e, 'second')} />
//                 <OtpInput value={otpnumber.third} valueLength={1} onChange={(e) => handleInputChange(e, 'third')} />
//                 <OtpInput value={otpnumber.four} valueLength={1} onChange={(e) => handleInputChange(e, 'four')} />
//             </div>
//             <p className="text-[31px]  text-[#585858] font-[roboto] font-medium mt-5">Didn’t recive the code ? Click here...<br /> <span className="text-left -ml-96 "> 00:{counter < 10 ? '0' + counter : counter}</span> </p>
//            <a href="/sign-in-google" > <img className=" inline-block w-6 h-6"  src={circlRrightArrow} alt='arrow' /></a>
//         </div>
//     )
// }

// export default OtpScreen

import { useState, useEffect, useRef } from "react";
import React from "react";
import circlRrightArrow from "../../images/circlRrightArrow.png";
import { useLocation, useNavigate } from "react-router-dom";
import { SUPABASE_CLIENT } from "../../Services/Supabase";
import {
  createStudent,
  getStudentData,
} from "../../function/Student/SignUp/Student";
// import { uuid } from '../../redux/useReducer'
import { OTPVerification, sendOTPService } from "../../Services/OTP";
// import {set_uid} from "../signupForm/Uuid"

// uid, setUID
let currentIndex: number = 0;
function OtpScreen() {
  const uuid: any = localStorage.getItem("uid");
  let signinDetail: any;
  const { state } = useLocation();
  const [{ countryCode, mobileNo }] = useState(state);
  const [counter, setCounter] = useState(59);
  const [totalcount, setTotalcount] = useState(599);
  const [time, settime] = useState({
    min: 9,
    sec: 59,
  });
  const [otpnumber, setOtpnumber] = useState<string[]>(new Array(4).fill(""));
  const [otpindex, setOtpindex] = useState<number>(0);
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const [nextbtn, setNextbtn] = useState(true);
  const [active, setActive] = useState(false);
  const [createuserData, setCreateuserData] = useState({
    uid: "",
    phoneNumber: "",
  });
  const [resendOtp, setResendOtp] = useState(false);

  // let otp = ["1", "2", "3", "4", "5", "6"];

    let otp = ["1", "2", "3", "4"];

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setNextbtn(true);
    const { value } = target;
    const newOtp = [...otpnumber];
    newOtp[currentIndex] = value.substring(value.length - 1);
    if (!value) {
      setOtpindex(currentIndex - 1);
    } else {
      setOtpindex(currentIndex + 1);
    }
    setOtpnumber(newOtp);
    let otpFlag = 0;
    newOtp.forEach((Element) => {
      if (Element === "") {
        otpFlag = 1;
      }
    });
    if (otpFlag) {
      setNextbtn(true);
    } else {
      setNextbtn(false);
    }
  };

  const handleKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    currentIndex = index;
    if (key === "Backspace") {
      setOtpindex(currentIndex - 1);
    }
  };
  const items = { ...localStorage };

  const handleClick = async () => {
    const otpvalue = otpnumber.join("");
    console.log(otpvalue);

    // const { data, error } = await SUPABASE_CLIENT.auth.verifyOtp({
    //     phone: "+" + mobileNo,
    //     token: otpvalue,
    //     type: "sms"
    // });
    // if (error) {
    // } else {
    //    await sessiondetails()
    //     // createStudent( getStudentData, mobileNo )

    // let status = arrayEquals(otpnumber, otp)
    // sessiondetails()
    // if (status) {
    //     //is login need to be check for routing
    //     navigate('/home', { state: mobileNo })
    // } else {
    //     navigate('/sign-in-google', { state: mobileNo })

    // }

    //test code

    // if (otpvalue == '5678') {

    //     let { data, error } = await SUPABASE_CLIENT
    //         .from('students')
    //         .select("*")
    //         .eq('phoneNumber',`+${countryCode} ${mobileNo}` )
    //     // set_uid(data?.user?.id)
    //     let stu_data:any = data

    //     if(stu_data?.length){
    //         stu_data = stu_data[0]
    //     }
    //         localStorage.setItem('uid',stu_data?.uid)
    //         if (stu_data.is_email_verified == false) {
    //             navigate('/sign-in-google', { state: mobileNo })
    //         }
    //         else {
    //             navigate('/home', { state: mobileNo })
    //         }

    // }

    //LIve project code

    console.log(countryCode, mobileNo);

    const data: any = await OTPVerification(
      otpvalue,
      `+${countryCode} ${mobileNo}`,
      "sms"
    );
    console.log(data);
    if (data) {
      const stu_data = await getStudentData(data?.user?.id);
      // set_uid(data?.user?.id)
      localStorage.setItem("uid", data?.user?.id);
      if (stu_data == "EMAIL_UNVERIFIED" || stu_data == "DETAILS_UNFILLED") {
        navigate("/sign-in-google", { state: mobileNo });
      } else {
        navigate("/home", { state: mobileNo });
      }
    }
  };

  const sessiondetails = async () => {
    const { data, error } = await SUPABASE_CLIENT.auth.getSession();
    let form_value = data;
    // let userId = await form_value.session?.user.id ? form_value.session?.user.id : ''
    // let phoneNumber = await form_value.session?.user.phone ? form_value.session?.user.phone : ''

    await createStudent({ uid: uuid, phoneNumber: mobileNo });
  };

  //   const signindetails = async (props: any) => {
  //       signinDetail = await props.session.user
  //       formdata(signinDetail)
  //   }

  //   const formdata = async (props:any) => {
  //     getStudentData(props.id)
  //     let userId =  props.id
  //     let phoneNumber =  props.phone

  //     setCreateuserData({uid:userId, phoneNumber: phoneNumber})
  //   }

  // function arrayEquals(a: any, b: any) {
  //         Array.isArray(b) &&
  //         a.length === b.length &&
  //         a.every((val, index) => val === b[index]))
  //     return (Array.isArray(a) &&
  //         Array.isArray(b) &&
  //         a.length === b.length &&
  //         a.every((val, index) => val === b[index])
  //     )
  // }

  const handleResend = async () => {
    await sendOTPService(mobileNo, "sms");
    setOtpnumber(["", "", "", "", "", ""]);
    setActive(false);
    setCounter(59);
    settime({ min: 9, sec: 59 });
  };

  useEffect(() => {
    inputRef.current?.focus();
    var timer: any =
      totalcount > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
        setTotalcount(totalcount - 1);

        if (counter == 0) {
          let currnt_min = time.min - 1;
          settime({ min: currnt_min, sec: 59 });
          setCounter(59);
        } else {
          settime({ min: time.min, sec: counter });
        }
      }, 1000);
    if (totalcount == 0) {
      setResendOtp(true);
      setActive(true);
      settime({ min: 0, sec: 0 });
    }
    return () => clearInterval(timer);
  }, [counter, otpindex]);

  return (
    <div className="bg-[url('../public/images/frontBg.png')] bg-cover h-screen ">
      <p className="text-[65px] text-[#036AD1] font-[roboto] font-bold max-monitor:pt-60 max-desktop:pt-48  max-mobile:text-[22px]  ">
        Verify Your Mobile
      </p>
      <p className="text-[31px] text-[#707070] font-[roboto] font-medium mt-1 max-mobile:text-[18px]">
        Enter your OTP code here
      </p>
      <div className="flex mt-3 text-center justify-center">
        {otpnumber.map((_, index) => {
          return (
            <React.Fragment key={index}>
              <input
                value={otpnumber[index]}
                ref={index === otpindex ? inputRef : null}
                onKeyDown={(e) => handleKeyDown(e, index)}
                type="number"
                className="otp-input w-10 text-center py-3 h-14 border-b-2 ml-3 border-b-black text-[48px] max-mobile:text-[28px] outline-none text-[#707070] pr-2"
                onChange={(e) => handleInputChange(e)}
                disabled={active}
              />
            </React.Fragment>
          );
        })}
      </div>
      <div className=" text-left flex  justify-center">
        <p className="text-[31px] text-[#585858] font-[roboto] font-medium mt-5 max-mobile:text-[18px]">
          Didn’t recive the code?
          {resendOtp ? (
            <span
              onClick={handleResend}
              className="text-[#0048FF] cursor-pointer"
            >
              Click here
            </span>
          ) : (
            <span className="text-[#585858] ">Click here</span>
          )}
          ...
          <br />
          <span className="">
            {"0" + time.min}:{time.sec < 10 ? "0" + time.sec : time.sec}
          </span>
        </p>
      </div>
      <button disabled={nextbtn} onClick={handleClick}>
        {" "}
        <img
          className=" inline-block w-[46px] h-[46px] my-5"
          src={circlRrightArrow}
          alt="arrow"
        />{" "}
      </button>
    </div>
  );
}

export default OtpScreen;
