// import { dateFormat } from "../../../Services/CommonFunctions"

import { errorNotifier } from "../../commonHelper"

import { TableNames } from "../../config/Tables"

import { FetchData, FetchDataById, InsertData, UpdateData } from "../../crud"

import { TrainingInterface, VideoDurationInterface } from "./interface"

 

export const getTrendingCourses = async (courseType: string, studentUid: string) => {

    try {

        const { data: trainings }: any = await FetchData(TableNames.trainings, ["id", "course_id"]).eq("student_id", studentUid)

        const { data: courses, error }: any = await FetchData(TableNames.courses).eq("type", courseType).eq("trending", true).order("id", {ascending: false});
        
        const { data: course_features } = await FetchData(TableNames.courseFeatures, ["id", "feature", "icon"])

        const { data: app_enquiries }: any = await FetchData(TableNames.app_enquiries, ["course"]).eq("student_id", studentUid)
        if (courseType === "Free") {
            let trendingCourses = courses?.map((course: any) => ({ ...course, is_enrolled: trainings.some((el: any) => el.course_id === course.id), training: trainings.find((training: any) => (training.course_id === course?.id)) || null, course_feature: course?.course_features?.map((cf1: any) => (course_features?.filter((cf2: any) => cf2?.id === cf1 ? cf2 : null))).map((n: any) => (n[0])) }))
            if (error) throw error
            return trendingCourses
        } else {
            let trendingCourses = courses?.map((course: any) => ({ ...course, is_enquired: app_enquiries.some((el: any) => el.course === course.id), training: trainings.find((training: any) => (training.course_id === course?.id)) || null, course_feature: course?.course_features?.map((cf1: any) => (course_features?.filter((cf2: any) => cf2?.id === cf1 ? cf2 : null))).map((n: any) => (n[0])) }))
            if (error) throw error
            return trendingCourses
        }
    } catch (error) {
        return errorNotifier(error)
    }

}

 

const calculateCourseForcategory = (courseData: any) => {

    const countObject: any = {}

 

    courseData?.forEach((course: any) => {

        countObject[course.category] = countObject[course.category] ? countObject[course.category] + 1 : 1

    })

    return countObject;

}

 

export const getCourseCategories = async (courseType: string) => {

    try {

        const { data: courseCategories, error }: any = await FetchData(TableNames.categories, ["id", "category_name", "thumbnail"]).eq("status", 'Active')

        const { data: courses, error: err }: any = await FetchData(TableNames.courses).eq("type", courseType)

 

        let countObject = calculateCourseForcategory(courses)

 

        const categoriesData = courseCategories.map((category: any) => ({ ...category, no_of_courses: countObject[category.id] || 0 }))

        if (error) throw error

        return categoriesData

    } catch (error) {

        return errorNotifier(error)

    }

}

 

export const getCoursesPerCategory = async (categoryId: number | string, courseType: string, studentUid: string) => {

    try {

        const { data: trainings }: any = await FetchData(TableNames.trainings, ["id", "course_id"]).eq("student_id", studentUid)

        const { data: courses, error } = await FetchData(TableNames.courses).eq("category", categoryId).eq("type", courseType)

        const { data: course_features } = await FetchData(TableNames.courseFeatures, ["id", "feature", "icon"])

 

        const { data: app_enquiries }: any = await FetchData(TableNames.app_enquiries, ["course"])

 

        if (courseType === "Free") {

            let trendingCourses = courses?.map((course: any) => ({ ...course, is_enrolled: trainings.some((el: any) => el.course_id === course.id), training: trainings.find((training: any) => (training.course_id === course?.id)) || null, course_feature: course?.course_features?.map((cf1: any) => (course_features?.filter((cf2: any) => cf2?.id === cf1 ? cf2 : null))).map((n: any) => (n[0])) }))

            if (error) throw error

            return trendingCourses

        } else {

            let trendingCourses = courses?.map((course: any) => ({ ...course, is_enquired: app_enquiries.some((el: any) => el.course === course.id), training: trainings.find((training: any) => (training.course_id === course?.id)) || null, course_feature: course?.course_features?.map((cf1: any) => (course_features?.filter((cf2: any) => cf2?.id === cf1 ? cf2 : null))).map((n: any) => (n[0])) }))

            if (error) throw error

            return trendingCourses

        }

    } catch (error) {

        return errorNotifier(error)

    }

}

 

export const getAllTestimonials = async () => {

    try {

        const { data: testimonials, error } = await FetchData(TableNames.testimonials, ["id", "type", "external_link", "video_url", "thumbnail"]).order("id", { ascending: false });

        if (error) throw error

        return testimonials

    } catch (error) {

        return errorNotifier(error)

    }

}

 

export const getAllHiringCompanies = async () => {

    try {

        const { data: companies, error }: any = await FetchData(TableNames.hiringCompanies, ["id", "company_logo"]);

        if (error) throw error;

        return companies;

    } catch (error) {

        return errorNotifier(error);

    }

}

 

export const getContactInfo = async () => {

    try {

        const { data: contact, error } = await FetchData(TableNames.contact_info, ['phone_number'])

        if (error) throw error

        return contact[0]

    } catch (error) {

        return errorNotifier(error)

    }

}

 

export const getStudentByUid = async (studentUid: string) => {

    try {

        const { data: student, error }: any = await FetchData(TableNames.students, ["name", "email", "phoneNumber"]).eq("uid", studentUid)

        if (error) throw error

        return student[0]

    } catch (error) {

        return errorNotifier(error)

    }

}

 

export const courseEnquiry = async (userId: string, course_id: string | number, branch_id?: string | number) => {

    try {

        const { data: app_enquiry } = await FetchData(TableNames.app_enquiries, ["course", "branch"]).match({ student_id: userId, course: course_id })


        if (app_enquiry?.length !== 0) {

            return { message: "Already enquired this course in this branch", status: false }

        }

        const studentData = {

            student_id: userId,

            course: course_id,

            branch: branch_id,

            is_enquired: true

        }

        const { data: enquiry, error } = await InsertData(TableNames.app_enquiries, studentData)

        if (error) throw error

        return { enquiry, status: true }

    } catch (error) {

        return errorNotifier(error)

    }

}

 

export const getPreloadData = async () => {

    try {

        const { data: branches, error } = await FetchData(TableNames.branches, ["id", "branch_name", "city"]).order("branch_name", { ascending: true });

        const { data: cities } = await FetchData(TableNames.cities, ["id", "city_name"]).order("city_name", { ascending: true });

        const data = cities?.map((city: any) => ({ ...city, branches: branches?.filter((branch: any) => city?.id === branch?.city) }))

        if (error) throw error

        return data

    } catch (error) {

        return errorNotifier(error)

    }

}

 

export const freeCourseEnrollment = async (trainingData: TrainingInterface) => {

    try {


        const { data: stu_data }: any = await FetchData(TableNames.students, ["courses"]).eq("uid", trainingData.student_id);


 


 

        let course_list = stu_data[0].courses

        if (course_list?.includes(trainingData?.course_id)) {

            return { "message": "Course already enrolled", status: false }

        }

        else {

            course_list.push(trainingData?.course_id)

            await UpdateData(TableNames.students, { courses: course_list }, { conditionKey: "uid", conditionValue: trainingData.student_id })

            const { data, error } = await InsertData(TableNames.trainings, trainingData)

            if (error) throw error

            return { message: data, status: true }

        }

    } catch (error) {

        return errorNotifier(error)

    }

}

 

 

export const progressByModuleCalculation = (courseData: any, trainingProgressByModule: any) => {

    let totalCompletedDuration = 0;

    let totalDuration = 0;

    courseData.videos = courseData.videos?.map((video: any) => {

        let progress = calculatePercentage(trainingProgressByModule[video.key] || 0, TimeFormatToSeconds(video.duration));

        totalDuration += TimeFormatToSeconds(video.duration);

        totalCompletedDuration += +trainingProgressByModule[video.key] || 0;

        return { ...video, progress }

    }) || [];

    return { course: courseData, overAllProgress: calculatePercentage(totalCompletedDuration, totalDuration) || 0 };

}

 

const calculatePercentage = (current: number, total: number = 1) => {

    return Math.round((current / total) * 100);

}

 

const TimeFormatToSeconds = (timeString: string) => {

    const [sec, min, hr]: any = timeString?.split(":").reverse();

    let seconds = 0;

    if (hr) {

        seconds += hr * 3600;

    }

    if (min) {

        seconds += min * 60;

    }

    if (sec) {
        seconds += +sec
    }
    return seconds;
}

 

// export const getCourseVideosById = async (id: number) => {

//     try {


//         const { data, error }: any = await FetchData(TableNames.trainings, ["student:student_id(name)", "course:course_id(course_name,videos)", "progress_by_module"]).eq("id", id);



//         const progress_by_module_length = Object.keys(data[0].progress_by_module)?.length;

 

//         if (error) throw error;

 

//         if(progress_by_module_length > 0) {

//             return { ...data[0], ...progressByModuleCalculation(data[0].course, data[0].progress_by_module) };

//         } else {

//             return { ...data[0] };

//         }

//         // return { ...data[0], ...progressByModuleCalculation(data[0].course, data[0].progress_by_module) };

 

//     } catch (error) {

//         return errorNotifier(error);

//     }

// }

export const getCourseVideosById = async (id: number) => {

    try {


        const { data, error }: any = await FetchData(TableNames.trainings, ["student:student_id(name)", "course:course_id(course_name,videos)", "progress_by_module"]).eq("id", id);

    

        const progress_by_module_length = Object.keys(data[0].progress_by_module)?.length;

        if (error) throw error;

 

        if(progress_by_module_length > 0) {

            return { ...data[0], ...progressByModuleCalculation(data[0].course, data[0].progress_by_module) };

        } else {

            return { ...data[0] };

        }

        // return { ...data[0], ...progressByModuleCalculation(data[0].course, data[0].progress_by_module) };

 

    } catch (error) {

        return errorNotifier(error);

    }

}
 

export const updateVideoDurationRecord = async (videoDurationData: VideoDurationInterface, trainingId: number | string) => {

    try {

        const { data: training, error: training_error }: any = await FetchData(TableNames.trainings, ["student:student_id(name)", "course:course_id(course_name,videos)", "progress_by_module"]).eq("id", trainingId);

 
        const { data, error }: any = await UpdateData(TableNames.trainings, videoDurationData, { conditionKey: "id", conditionValue: trainingId })

 

        let course_progress = progressByModuleCalculation(training[0].course, data[0].progress_by_module).overAllProgress
 

        if (course_progress === 100) {

            let date_of_completion = new Date()

            await UpdateData(TableNames.trainings, { date_of_completion: date_of_completion }, { conditionKey: "id", conditionValue: trainingId })

        }

 

        if (error) throw error
        return training[0].course.videos


    } catch (error) {

        return errorNotifier(error)

    }

}