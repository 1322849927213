import { SUPABASE_CLIENT } from "../Services/Supabase";
import { TableNames } from "./config/Tables";
import { FetchData } from "./crud";

interface UserAuth{
    email: string,
    password: string,
    data: any,
    user_metadata: any,
    uid:string
}

interface UserAuthDataProps{
    phone?: string,
    phone_confirm?: boolean,
    password?:string
}

export const CreateUserWithEmailAndPassword = async (email: string, password: string) => {
    const { data, error }= await SUPABASE_CLIENT.functions.invoke("UserAuthHandler", {
        body: {
            email,
            password,
            action: "CREATE",
            email_confirm: true
        }
    });
    if (error || data.error) throw error || data.error;
    return data;
}

// export const signInWithPhoneAndPassword = async (phoneNumber: string, password: string) => {
//     const { data, error } = await SUPABASE_CLIENT.auth.signInWithPassword({
//         phone: phoneNumber,
//         password
//     });
//     if (error) throw error
// }

export const signInWithPhoneAndPassword = async (phoneNumber: string, password: string) => {
    const { data, error } = await SUPABASE_CLIENT.auth.signInWithPassword({
        phone: phoneNumber,
        password
    });
    if (error) throw error
    return data;
}

export const generateRandomPassword=(length:number)=> {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    let password = "";

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }

    return password;
}

export const CreateUserByAdminAuth = async (userAuthData: UserAuthDataProps) => {
    const { data, error } = await SUPABASE_CLIENT.functions.invoke("UserAuthHandler", {
        body: {
            ...userAuthData,
            action:"CREATE"
        }
    });
    if (error || data.error) throw error || data.error;
    return data;
}


export const isEmailIdAlreadyExists = async (email: string) => {
    const { data, error }:any = await FetchData(TableNames.users).eq("email", email);
    return data?.length;
}

export const UpdateUser = async (userData: Partial<UserAuth>) => {
    const { data, error } = await SUPABASE_CLIENT.functions.invoke("UserAuthHandler", {
        body: {
            ...userData,
            action: "UPDATE"
        }
    });
    if (error || data.error) throw error || data.error;
    return data;
}

export const DeleteUser = async (uid: string) => {
    const { data, error } = await SUPABASE_CLIENT.functions.invoke("UserAuthHandler", {
        body: {
            uid,
            action: "DELETE"
        }
    });
    if (error || data.error) throw error || data.error;
    return data;
}

export const getUsersDetails = async (uid: string) => {
    const { data, error }: any = await FetchData(TableNames.users).eq("uid", uid);
    return data;

}