import moment from "moment";

export const onKeyDown = (keyEvent: any) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
};

//DD-MMM-YYYY

export const dateFormat=(dateText:string, dateFormat:string)=>{
    let text;
    if (moment(dateText).format(dateFormat) !== "Invalid date") {
      text = moment(dateText).format(dateFormat);
    } else {
      text = moment(new Date()).format(dateFormat);
  }
    return text;
}

export function datePlusMinutes(minutes:number) {
  const now = new Date();
  now.setMinutes(now.getMinutes() + minutes);
  return now.getTime();
}

// export const GetParamsForNavigation = ({id}:any) => {
//     const navigate = useNavigate();
//     const params = useParams();
//     const routeNameForHome = params.dashboardScreen;
//     const routeNameForSubSection = params.subsection;
//     return (
//         navigate(`/${routeNameForHome}/${routeNameForSubSection}/${id}`)
//     )
// }