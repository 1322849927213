import React, { useState, useEffect } from 'react'
import AssessmentData from '../../content/AssessmentData.json'
import Header from '../elements/header'
import { useNavigate } from 'react-router-dom'
import Footer from '../elements/Footer'
var selecetedData = [] as any

function BeforeAssessmentoption() {

  const navigate = useNavigate()
  const [fillterData, seFillterData] = useState({})
  const handleChange = (item: any) => {
    setTimeout(function () {
      seFillterData(AssessmentData.filter(name => (name.id === item.nextId)).map(filteredName => filteredName))
      selecetedData.push(item.unikey)
      if (item.nextId === 'end') {
        navigate('/allocatedcourse', { state: { selecetedData } })
        selecetedData = []
      }
    }, 100);

  }


  useEffect(() => {
    seFillterData(AssessmentData.filter(name => (name.level === 1)).map(filteredName => filteredName))
  }, [])

  return (
    <div className="bg-[url('../public/images/frontBg.png')] bg-cover h-[100vh] ">
      <Header />
      <div className=' items-center  max-monitor:pt-72 max-desktop:pt-36  max-laptop:pt-28'>
        {Object.values(fillterData).map((item: any, index) => {
          return <div key={index} onClick={() => handleChange(item)} className=' border-2  flex px-5 mb-5 w-2/6 items-center h-[80px] rounded-lg mx-auto text-left bg-[#FFFFFF] shadow-3xl my-3 max-laptop:w-3/6 max-mobile:w-5/6 active:bg-[#036AD1] hover:bg-[#036AD1] hover:text-[#fff]'>
            <input type="radio" name='option' id={item.question} checked={false} value={item.question} /> 
            <p className='ml-5 '>{item.question}</p> 

          </div>
        })}
      </div>
      <Footer/>
    </div>
  )
}

export default BeforeAssessmentoption