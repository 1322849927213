import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SUPABASE_CLIENT } from "../../Services/Supabase";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { mobileno_data } from "../../redux/useReducer";

import { sendOTPService } from "../../Services/OTP";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
  LoadCanvasTemplateNoReload,
} from "react-simple-captcha";
import { Phone, Sms } from "@mui/icons-material";
import { parsePhoneNumber } from "libphonenumber-js";

import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import { errorNotifier } from "../../function/commonHelper";

function MobileNumberScreen() {
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileNo, setMoileNo] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [submitOtp, setsubmitOtp] = useState(false);

  let parsedPhoneNumber: any;

  const signinFunction = async () => {
    // if(mobileNo == '1234567890'){
    //   navigate("/otp-screen", { state: mobileNo })

    // }
    if (parsedPhoneNumber) {
      // console.log('Country Code:', parsedPhoneNumber.countryCallingCode, typeof(parsedPhoneNumber.countryCallingCode) );
      // console.log('Phone Number:', parsedPhoneNumber.nationalNumber);

      const phone = `+${parsedPhoneNumber.countryCallingCode} ${parsedPhoneNumber.nationalNumber}`;
      console.log(phone);

      const data = await sendOTPService(phone, "sms");
      console.log(data, "login data");
      navigate("/otp-screen", {
        state: {
          countryCode: parsedPhoneNumber.countryCallingCode,
          mobileNo: parsedPhoneNumber.nationalNumber,
        },
      });
    }
  };

  const doSubmit = async () => {
    let user_captcha = captcha;

    //sentry code
    // throwKnownError();
    parsedPhoneNumber = parsePhoneNumber(`+${mobileNo}`);
    if (parsedPhoneNumber) {
      if (validateCaptcha(captcha) === true) {
        if (parsedPhoneNumber?.countryCallingCode == "91") {
          if (parsedPhoneNumber?.nationalNumber?.length == 10) {
            setsubmitOtp(true);
            await signinFunction();
            setsubmitOtp(false);
          } else {
            errorNotifier({ message: "Mobile number must be 10 digit" });
          }
        } else {
          if (
            parsedPhoneNumber?.nationalNumber?.length <= 15 &&
            parsedPhoneNumber?.nationalNumber?.length >= 6
          ) {
            setsubmitOtp(true);
            await signinFunction();
            setsubmitOtp(false);
          } else {
            errorNotifier({ message: "Mobile number must be a valid number" });
          }
        }
        loadCaptchaEnginge(4);
        setCaptcha("");
      } else {
        errorNotifier({ message: "Captcha Does Not Match" });

        loadCaptchaEnginge(4);
        setCaptcha("");
      }
    } else {
      errorNotifier({ message: "incorrect number" });
    }
  };

  //sentry code

  // const throwKnownError = ()=>{
  //   throw new Error ("err from sentry")
  // }

  // const doSubmit = () => {
  //   let user_captcha = captcha

  //   if (validateCaptcha(captcha) === true) {
  //     if (mobileNo.length === 12) {
  //     signinFunction(mobileNo)
  //     }else{
  //       alert('Enter Valid Mobile Number')
  //     }
  //     loadCaptchaEnginge(4);
  //     setCaptcha('')
  //   }

  //   else {
  //     alert('Captcha Does Not Match');
  //     loadCaptchaEnginge(4);
  //     setCaptcha('')
  //   }
  // };

  const trainerLogin = () => {
    navigate("/trainer/login");
  };

  useEffect(() => {
    loadCaptchaEnginge(4);
  }, []);

  return (
    <div className="bg-[url('../public/images/frontBg.png')] bg-cover h-[100vh] ">
      <div className="flex justify-end mr-3 mt-3">
        <p
          className="cursor-pointer text-[#036AD1] underline text-[18px]"
          onClick={() => trainerLogin()}
        >
          Trainer Login
        </p>
      </div>
      <div className="grid items-center mob:justify-center  text-center justify-center max-monitor:pt-60 max-desktop:pt-32 max-mobile:pt-40">
        <div className="text-[65px] text-[#036AD1] font-[roboto] font-bold max-laptop:text-[48px] max-mobile:text-[20px]">
          Enter your Mobile number
        </div>
        <div className="text-[31px] text-[#707070] font-[roboto] font-normal mt-1 max-mobile:text-[16px]">
          You will get a code via sms
        </div>
        <div className="flex justify-center text-[48px]  text-[#707070] font-[roboto] font-medium underline underline-offset-[17px] pt-3">
          <PhoneInput
            placeholder="Enter your Mobile Number"
            value={mobileNo}
            country={"in"}
            onChange={(e) => setMoileNo(e)}
            containerStyle={{
              border: "none",
              outline: "none",
              width: "220px",
              height: "40px",
              marginBottom: "10px",
              marginLeft: "-50px",
              marginTop: "20px",
            }}
            inputStyle={{
              marginLeft: "50px",
              borderRadius: "1px",
              border: "none",
              borderBottom: "1px solid black",
              paddingTop: "15px",
              paddingBottom: "25px",
              width: "220px",
              fontSize: "23px",
              paddingLeft: "20px",
              maxWidth: "500px",
              minWidth: "200px",
            }}
            dropdownClass="text-left"
            buttonStyle={{ border: "none" }}
          />
          {/* style={{ outline: 'none', marginLeft: '10px', borderRadius: '1px', border: 'none', borderBottom: '1px solid black', paddingTop: '10px', paddingBottom: '5px', width: '300px', fontSize: '40px', paddingLeft: '30px', maxWidth: '500px', minWidth: '200px' }} */}

          {/* <input  className='outline-none ml-[10px] rounded-[1px] border-solid border-b border-[#000]  pt-[10px] pb-[5px] w-[300px] text-[40px] max-mobile:text-[30px] pl-[30px] max-mobile:w-[230px] max-w-[500px] min-w-[200px]' value={mobileNo} onChange={(e: any) => setMoileNo(e.target.value)} /> */}
        </div>
        <div className="grid p-5 align-middle items-center">
          <div className="flex justify-center items-center text-center border-dashed border-2 w-[30%] max-mobile:w-[50%] max-mobile:text-[14px] ml-[35%] max-mobile:mx-auto">
            <LoadCanvasTemplate reloadColor="red" />
          </div>
          <div className="my-5">
            <input
              placeholder="Enter Captcha Value"
              className=" border-black border-2 mx-4 rounded-md pl-2 items-center text-center"
              onChange={(e) => setCaptcha(e.target.value)}
            />
            <button
              disabled={submitOtp}
              className=" p-1 rounded-md text-white bg-[#036AD1] px-3 max-mobile:mt-3"
              onClick={doSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileNumberScreen;
