import { toast } from "react-hot-toast";
import { AuthErrorMessages, isDBError } from "./Error/error";
import { SUPABASE_CLIENT } from "../Services/Supabase";
import { FetchData } from "./crud";

export const errorNotifier = (error: any) => {
    switch (true) {
        case error.name === "AuthApiError":
            AuthErrorHandler(error);
            break;
        case isDBError(error):
            DBErrorHandler(error);
            break;
        default:
            toast.error(error.details || error.message || error.name);
    }
    return false
}

export const DBErrorHandler = (error: any) => {
    let message;
    switch (error.code) {
        case "23505":
            message = error.details?.split("Key (")[1];
            message = message.split(")")[0] + " already exists";
            break;
        default:
            message = error.details
            break;
    }
    toast.error(message);

}


export const AuthErrorHandler = (error: any) => {
    let message;
    switch (error.message) {
        case "duplicate key value violates unique constraint \"users_email_partial_key\"":
        case "A user with this email address has already been registered":
            message = AuthErrorMessages.emailExists;
            break;

        default:
            message = error.message
            break;
    }
    toast.error(message);
}



// export const DuplicationErrorHandler = async (table: string, key: string, checker: string | undefined) => {
//     if (!checker) {
//         return {isExist: false}
//     }
//     let errorObj = {
//         message: `${checker} already exists.`
//     }
//     const {data} = await FetchData(table, [key]);
//     const arrayOfValues: any = data?.map((obj: any) => obj?.category_name)
//     return {isExist: arrayOfValues.includes(checker), isError: errorObj}
// }

// export const UpdateChecker = async (table: string, key: string, id: string, checker: string | undefined) => {
//     const changedData : any= await FetchData(table, [key]).eq("id", id)
//     if (changedData.data[0]?.category_name !== checker) {
//         return DuplicationErrorHandler(table, key, checker)
//     }
//     return true
// }

const parseTime = (timeString: string) =>  {
  const [time, period] = timeString.split(' ');
  const [hours, minutes] = time.split(':');
    
  return {
    hours: parseInt(hours),
    minutes: parseInt(minutes),
    period: period.toLowerCase()
  };
}

export const calculateTimeDifference = (startTime: string, endTime: string): any => {
    const start = parseTime(startTime);
    const end = parseTime(endTime);
    
  let minutesDifference = end.minutes - start.minutes;
  let hoursDifference = end.hours - start.hours;

  // Handle negative time difference
  if (minutesDifference < 0) {
    minutesDifference += 60;
    hoursDifference--;
  }

  // Convert AM/PM to 24-hour format
  if (start.period === 'pm' || end.period === "pm") {
    hoursDifference += 12;
  }

  return hoursDifference + minutesDifference / 60;
}

export const calculatePercentage = (progress_duration: any, total_duration: any) => {


  const progress_percent = (Number(progress_duration) / Number(total_duration)) * 100

  return progress_percent
  
}