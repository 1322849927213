import { format } from "path";
import { SUPABASE_CLIENT } from "../../Services/Supabase"
import { errorNotifier } from '../commonHelper'

interface FileInterface{
    fileName: string,
    file:any
}

//Upload sample data format
// {
//     file: event.target.files[0],
//         fileName: event?.target.files[0].name
// }
export const uploadFile = async ({ file, fileName }: FileInterface,bucketName:string) => {
    try {
        const { data, error } = await SUPABASE_CLIENT.storage.from(bucketName).upload(fileName, file, {
            cacheControl: "3600",
            upsert: true
        });
        if (error) throw error;
        const { data: url } = await SUPABASE_CLIENT.storage.from(bucketName).getPublicUrl(data.path);
        return url;
    } catch (error) {
        return errorNotifier(error);
    }
}

// filename="thumbnail/key"

//Update file sample data format
// {
//     file: event.target.files[0],
//         fileName: targetPathName
// }

export const updateFile = async ({ file, fileName }: FileInterface, bucketName: string) => {
    try {
        const { data, error } = await SUPABASE_CLIENT.storage.from(bucketName).update(fileName, file, {
                cacheControl: '3600',
                upsert: true
            });
        if (error) throw error;
        const { data: url } = await SUPABASE_CLIENT.storage.from(bucketName).getPublicUrl(data.path);
        return url;
    } catch (error) {
        return errorNotifier(error);
    }
}


// filename - the storage key either as string or string array, 
// bucketName - Bucket name in where you want to perform this action
export const deleteFile = async (fileName: string|string[], bucketName: string) => {
    try {
        let pathName=Array.isArray(fileName)?fileName:[fileName]
        const { data, error } = await SUPABASE_CLIENT.storage.from(bucketName).remove(pathName);
        if (error) throw error;
        return true;
    } catch (error) {
        return errorNotifier(error);
    }
}