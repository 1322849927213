import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import TrainerCard from '../components/TrainerCard';
import TrainerHeader from '../components/TrainerHeader';
import { getCourseProgressTableData, getLoggedUserDetails } from '../Functions/TrainerFunctions';
import { ProgressModal } from './ProgressModal';
import searchIcon from '../../../images/searchIconPng.png';

export default function CourseProgress() {
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]) as any
  const [filteredCourses, setFilteredCourses] = useState([]) as any
  const [bulkFlag, setBulkFlag] = useState(false);
  const [scheduleDropdown, setScheduleDropdown] = useState([]) as any
  const [statusDropdown, setStatusDropdown] = useState([]) as any
  const [filterDict, setFilterDict] = useState({ schedule: '', status: '' });
  const [courseFilter, setCourseFilter] = useState('');
  const navigate = useNavigate();
  const UID = localStorage.getItem('uid');

  const fetchData = useCallback(async () => {
    if(UID){
      const userDetails = await getLoggedUserDetails(UID);
      const data = await getCourseProgressTableData(userDetails) as any
      setStatusDropdown([...new Set(data.map((item:any) => item.status))]);
      setScheduleDropdown([...new Set(data.map((item:any) => item.schedule))]);
      setCourses(data);
      setFilteredCourses(data);
      setIsLoading(false);
    }
  }, [UID]);

  useEffect(() => {
    if (!UID) {
      navigate('/trainer/login');
    } else {
      fetchData();
    }
  }, [UID, fetchData, navigate]);

  const handleChange = useCallback(
    (key: string, value: string) => {
      const updatedFilterDict = { ...filterDict, [key]: value };
      setFilterDict(updatedFilterDict);
      const filteredData = courses.filter((item:any) => 
        (updatedFilterDict.status ? item.status === updatedFilterDict.status : true) &&
        (updatedFilterDict.schedule ? item.schedule === updatedFilterDict.schedule : true) &&
        (courseFilter ? item.course_name.toLowerCase().includes(courseFilter.toLowerCase()) : true)
      );
      setFilteredCourses(filteredData);
    },
    [courses, filterDict, courseFilter]
  );

  const handleCourseFilterChange = useCallback(
    (value: string) => {
      setCourseFilter(value);
      const filteredData = courses.filter((item:any) => 
        item.course_name.toLowerCase().includes(value.toLowerCase()) &&
        (filterDict.status ? item.status === filterDict.status : true) &&
        (filterDict.schedule ? item.schedule === filterDict.schedule : true)
      );
      setFilteredCourses(filteredData);
    },
    [courses, filterDict]
  );

  if (isLoading) {
    return (
      <div className="bg-[#F9FCFF] h-screen flex items-center justify-center">
        <PulseLoader color="#065FEF" size={8} />
      </div>
    );
  }

  return (
    <div className={`${!bulkFlag?'bg-[#036AD1] ':""}h-full`}>
      {bulkFlag ? (
        <>
          <ProgressModal setBulk={setBulkFlag} />
          <button
            type="button"
            onClick={() => setBulkFlag(false)}
            className="absolute top-4 right-4 z-50 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-indigo-500 p-1 rounded-md"
          >
            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </>
      ) : (
        <>
          <TrainerHeader />
          <div className="flex justify-between text-[#fff] items-center py-6 px-2">
            <p className="text-[18px] font-bold ml-[10px]">Course Progress</p>
            <button
              onClick={() => navigate('/trainer/transaction')}
              className="bg-[red] text-[16px] rounded-md px-2 py-1"
            >
              Trainer Transaction
            </button>
          </div>
          <div className="flex justify-around items-center mb-[10px] bg-[#fff] py-[10px]">
            <div className="flex mx-2 py-[1px]">
              <input
                onChange={(e) => handleCourseFilterChange(e.target.value)}
                type="text"
                placeholder="Search"
                className="w-[90px] text-[14px] rounded-tl-full rounded-bl-full h-[30px] bg-[#f9f9f9] pl-[10px]"
              />
              <span className="flex bg-[#276ab4] items-center justify-center rounded-tr-full rounded-br-full w-[25px]">
                <img className="w-[25px] px-[2px]" src={searchIcon} alt="search" />
              </span>
            </div>
            <div>
              <select
                value={filterDict.schedule}
                onChange={(e) => handleChange('schedule', e.target.value)}
                className="bg-[#f9f9f9] rounded w-[85px] py-[3px] text-[14px]"
              >
                <option value="">Schedule</option>
                {scheduleDropdown.map((item:any) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div>
              <select
                value={filterDict.status}
                onChange={(e) => handleChange('status', e.target.value)}
                className="bg-[#f9f9f9] rounded w-[90px] py-[3px] text-[14px]"
              >
                <option value="">Status</option>
                {statusDropdown.map((item:any) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="bg-[#EFF7FF] m-[5px] rounded">
            <div className="flex justify-end py-[12px]">
              <button
                onClick={() => setBulkFlag(true)}
                className="p-[3px] bg-[red] text-[14px] rounded text-white mr-[15px] shadow-lg"
              >
                Bulk Update
              </button>
            </div>
            <div>
              {filteredCourses.map((item:any) => (
                <TrainerCard key={item.id} data={item} /> // Assuming each course item has a unique id
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
