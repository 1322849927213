import React from 'react'
import Mycourse from './mycourse'
import Mycertificate from './mycertificate'
import Myplacement from './myplacement'
import { Routes, Route } from 'react-router-dom';
import Header from '../elements/header';
import Profile from './profile';
import Feedback from './feedback';
import Editprofile from './editprofile';
import Uploadresume from './Uploadresume';

function rightScreen({ data }: any) {

  let routelink = data
  return (
    <div className=' w-full mt-1 bg-[#F9FCFF]'>
      {routelink === 'mycourse' ? <Mycourse /> : routelink === 'mycertificate' ? <Mycertificate /> : routelink === 'myplacementsupport' ? <Myplacement /> : routelink === 'feedback' ? <Feedback /> : routelink === 'editprofile' ? <Editprofile /> : routelink === 'Resume' ? <Uploadresume />: <div></div>}
    </div>
    // <Routes>
    //   <Route path='/profile/mycourse' element={ <Mycourse /> } />
    //   <Route path='/profile/mycertificate' element={<Mycertificate />} />
    //   <Route path='/profile/myplacementsupport' element={<Myplacement />} />
    // </Routes>
  )
}

export default rightScreen