import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// import { getCourseProgressTableData } from "../../functions/admin/Course Progress/courseProgress";
import { useAppSelector } from "../../..";
import { getCourseProgressTableData, getLoggedUserDetails } from "../Functions/TrainerFunctions";
// import { CourseProgressSessionModal } from "./CourseProgressSessionModal";
import BulkUpdate from './BulkUpdate'
import { PulseLoader } from "react-spinners";
const checkboxClasses =
  "absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600";

export const ProgressModal = ({setBulk}:any) => {
  const checkboxRef: any = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState(new Set());
  const [progress, setProgress] = useState([] as any);
  const [changeModalInput, setChangeModalInput] = useState(false);
  const UID:any = localStorage.getItem('uid')
  const [isLoading, setIsLoading] = useState(true);
  const HeadersContent = [
    "#",
    "Batch Code",
    "Courses",
    "Schedule",
    "Trainer",
    "Trainer Phone Number",
    "Batch Progress(%)",
    "Batch Status",
    "Branch",
    "City",
  ];

  useEffect(() => {
    (async () => {
        setIsLoading(true)
        const userDetails =await getLoggedUserDetails(UID)
        const response:any = await getCourseProgressTableData(userDetails)
    //   const response = await getCourseProgressTableData(signInData?.data[0]);
      setProgress(response);
      setIsLoading(false)
    })();
  }, []);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedPeople.size > 0 && selectedPeople.size < progress.length;
    setChecked(selectedPeople.size === progress.length);
    setIndeterminate(isIndeterminate);
    if(checkboxRef.current)checkboxRef.current.indeterminate = isIndeterminate;
  }, [selectedPeople, progress]);

  const toggleAll = () => {
    setSelectedPeople(checked || indeterminate ? new Set() : new Set(progress));
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  const handleCheckboxChange = (person: any) => {
    const updatedSelectedPeople = new Set(selectedPeople);
    if (updatedSelectedPeople.has(person)) {
      updatedSelectedPeople.delete(person);
    } else {
      updatedSelectedPeople.add(person);
    }
    setSelectedPeople(updatedSelectedPeople);
  };

  if (isLoading) {
    return (
        <div className="bg-[#F9FCFF] h-screen flex items-center justify-center">
            <PulseLoader color="#065FEF" size={8} />
        </div>
    );
}

  return (
    <div className="flex items-center justify-center w-full">
      {changeModalInput ? (
        // <CourseProgressSessionModal selectedPeople={selectedPeople} />
        <BulkUpdate setBulk={setBulk} selectedBatches={selectedPeople}/>
      ) : (
        <div className="px-4 sm:px-6 lg:px-8 w-full h-screen py-10">
          <h1 className="text-xl mt-1 font-Roboto font-[500] text-blue-900">
            Course Progress Bulk Update
          </h1>
          <div className="mt-2 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="relative">
                  {selectedPeople.size > 0 && (
                    <div className="flex h-12 items-center space-x-3 bg-white pl-">
                      <button
                        type="button"
                        onClick={() => setChangeModalInput(true)}
                        className="inline-flex sticky left-4 items-center rounded bg-blue-900 px-2 py-1 text-sm font-semibold text-white shadow-sm"
                      >
                        Proceed
                      </button>
                    </div>
                  )}
                  <table className="min-w-full table-fixed divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="sticky divide-gray-200 bg-white left-0 px-7 z-10 sm:w-12 sm:px-6"
                        >
                          <input
                            type="checkbox"
                            className={checkboxClasses}
                            ref={checkboxRef}
                            checked={checked}
                            onChange={toggleAll}
                          />
                        </th>
                        {HeadersContent?.map((head: any) => (
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            {head}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {progress.map((person: any, index: number) => (
                        <tr
                          key={person.email}
                          className={
                            selectedPeople.has(person)
                              ? "bg-gray-50"
                              : undefined
                          }
                        >
                          <td className="sticky left-0 z-10 divide-gray-200 bg-white px-7 sm:w-12 sm:px-6">
                            {selectedPeople.has(person) && (
                              <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                            )}
                            <input
                              type="checkbox"
                              className={checkboxClasses}
                              value={person.email}
                              checked={selectedPeople.has(person)}
                              onChange={() => handleCheckboxChange(person)}
                            />
                          </td>
                          <td
                            className={`whitespace-nowrap py-4 pr-3 text-sm font-medium ${
                              selectedPeople.has(person)
                                ? "text-indigo-600"
                                : "text-gray-900"
                            }`}
                          >
                            {index + 1}
                          </td>
                          <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                            {person.batch_code}
                          </td>
                          <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                            {person.course_name}
                          </td>
                          <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                            {person.schedule}
                          </td>
                          <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                            {person.trainer_name}
                          </td>
                          <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                            {person.trainer_mobile}
                          </td>
                          <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                            {person.batch_progress_in_percent}
                          </td>
                          <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                            {person.status}
                          </td>
                          <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                            {person.branch_name}hhh
                          </td>
                          <td className="whitespace-nowrap py-3.5 pr-3 text-sm text-gray-500">
                            {person.city}
                          </td>
                          {/* <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                        <a
                          href="#"
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Edit<span className="sr-only">, {person.name}</span>
                        </a>
                      </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
