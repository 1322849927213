import { useState, useEffect } from "react";
import { SUPABASE_CLIENT } from "../../../Services/Supabase";
import { useNavigate } from "react-router-dom";
import FitaLoginLogo from "../../../images/FitaLoginLogo.png";
import { Commoninput } from "../components/Commoninput";
import { useFormik } from "formik";
import { loginValidation } from "../../TrainerModule/Formik/FormikValidation";
import { for_Login } from "../../TrainerModule/Formik/InitialValues";
import toast from "react-hot-toast";
import { useAppDispatch } from "../../..";
import { PulseLoader } from "react-spinners";
import { FetchData } from "../../../function/crud";
import { TableNames } from "../../../function/config/Tables";

export const TrainerLogin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [disableButton, setDisableButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const proceedLogin = async () => {
    try {
      if (window.navigator.onLine) {
        setIsLoading(true)
        setDisableButton(true);
        const { data:User, error } :any = await SUPABASE_CLIENT.auth.signInWithPassword(
          values
        );
        const {
          data: loginUserAccessRole,
          error: loginUserAccessRoleError,
        }: any = await FetchData(TableNames.roles, ["id", "role"]);
        const rolesId = loginUserAccessRole.filter((entry: any) =>
        User?.user.user_metadata.roles_array?.includes(entry.id)
        );
        const rolesNames =
        rolesId &&
        rolesId?.map((data: any) => data?.role);
        
        if (error) {
          toast.error("Invalid Username/Email");
        } 
        // else if(!rolesNames.includes('Trainer')){
        //   toast.error("Dont have Access");
        // }
        else {
          toast.success("User LoggedIn Successfully");
          localStorage.setItem('uid',User?.user?.id)
          localStorage.setItem('userType',rolesNames)
        
          navigate('/trainer/course-progress');

        }
        setDisableButton(false);
      } else toast.error("Please Check your Internet Connection");
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      // console.error("An error occurred during login:", error);
      toast.error("An error occurred during login. Please try again.");
    }
  };

  const { values, errors, touched, handleBlur, handleSubmit, handleChange } =
    useFormik({
      initialValues: for_Login,
      validationSchema: loginValidation,
      onSubmit: () => {
        proceedLogin();
      },
    });
    
  return (
    <>
      {isLoading ? (
        <div className="bg-[#F9FCFF] h-screen flex items-center justify-center">
          <PulseLoader color="#065FEF" size={8} />
        </div>
      ) : (
        <div className="bg-[#F9FCFF] h-screen flex items-center relative">
    
          <div className="mt-[20px] xl:mt-[60px] h-[75vh] lg:h-[75vh] w-10/12 2xl:w-9/12 mx-auto rounded-[9px] z-10 relative flex items-center justify-center">
              <form
                onSubmit={handleSubmit}
                className="w-[280px] mx-auto bg-white flex flex-col justify-center items-center shadow-md shadow-[#00000029] rounded-[7px] space-y-10 2xl:space-y-10 py-8 lg:py-6 2xl:py-12"
              >
                <img
                  src={FitaLoginLogo}
                  alt="FitaLoginLogo"
                  className="w-[100px]"
                />
                <div className="w-11/12 mx-auto flex flex-col items-center justify-center space-y-2 2xl:space-y-4">
                  <Commoninput
                    label={"Email Address"}
                    placeholderName={"example@gmail.com"}
                    type={"email"}
                    name={"email"}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email}
                    isTouched={touched.email}
                    labelStyle={"text-[#707070] text-[18px]"}
                    inputStyle={
                      "h-[40px] shadow-md shadow-[#00000029]"
                    }
                  />
                  <Commoninput
                    label={"Password"}
                    placeholderName={"Enter password"}
                    type={"password"}
                    name={"password"}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password}
                    isTouched={touched.password}
                    labelStyle={"text-[#707070] text-[18px]"}
                    inputStyle={
                      "h-[40px] shadow-md shadow-[#00000029]"
                    }
                    openEyeStyle={"top-2"}
                    closeEyeStyle={"top-2"}
                  />
                </div>
                <button
                  type="submit"
                  disabled={disableButton}
                  className={`bg-[#036AD1] shadow-md shadow-[#00000029] rounded-[3px] text-white font-Roboto font-medium w-4/12 py-2.5 2xl:py-3 ${
                    disableButton && "opacity-40"
                  }`}
                >
                  Login
                </button>
              </form>
          </div>

        </div>
        
      )}
    </>
  );
};
