// import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// interface ApplicantsState {
  
//   selectedRows: number[];
// }

// const initialState: ApplicantsState = {
  
//   selectedRows: [],
// };

// const applicantsSlice = createSlice({
//   name: 'applicants',
//   initialState,
//   reducers: {
//     setApplicants: (state, action: PayloadAction<number[]>) => {
//       state.selectedRows = action.payload;
//     },
//   },
// });

// export const { setApplicants } = applicantsSlice.actions;
// export default applicantsSlice.reducer;



import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  applicantData: {
    applicantView: [],
  },
};

const viewApplicantsSlice = createSlice({
  name: "applicants",
  initialState,
  reducers: {
    setApplicants: (state: any, action: PayloadAction<any>) => {
      state.applicantData = {
        ...state.applicantData,
        ...action.payload,
      };
    },
    resetApplicantView: (state: any) => {
      state.applicantData = {
        applicantView: [],
      };
    },
  },
});

export const { setApplicants, resetApplicantView } = viewApplicantsSlice.actions;
export default viewApplicantsSlice.reducer;