import React, { useEffect, useState , CSSProperties} from 'react'
import { getPlacementStatus } from '../../function/Student/Profile/Profile'
// import { uuid } from '../../redux/useReducer'
import selectedgreen from '../../images/selectedgreen.svg'
import unselectedgreen from '../../images/unselectedgreen.svg'
import selectedred from '../../images/selectedred.svg'
import unselectedred from '../../images/unselectedred.svg'
import ClipLoader from "react-spinners/ClipLoader";

function Myplacement() {
  const uuid:any = localStorage.getItem('uid')
  const [isplace, setIsplace] =useState()
  const [placmentdata, setPlacementdata] = useState() as any
  let [loading, setLoading] = useState(true);

  const override: CSSProperties = {

    flex: 1,

    marginTop:240,

    justifyContent: 'center',

    alignItems:'center'

 

  };
  
  const data = async () => {
    setLoading(true)
    const placement = await getPlacementStatus(uuid)
    setPlacementdata(placement)
    check(placement)
    setLoading(false)
  }
  const check =(props:any)=> {
    setIsplace(props.placement_need)
  }
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0
  })
    data()
  },[])

  return (
    <div className=' min-h-screen bg-[#FFFFFF] pb-28'>
      {loading == false? <><div className='p-10 text-left mx-auto '>
        <p className='  text-[#707070] text-[24px]'>Placement Support </p>
        <hr className='mt-4' />
      </div>
      <div className=' flex items-center max-tablet:block'>
        <p className='ml-10 text-[23px] max-tablet:text-[23px] max-mobile:text-[20px] font-medium text-[#707070] text-left'>Need Placement Support from FITA Academy?</p>
        <div className=' flex mx-5 max-tablet:mx-auto items-center justify-center'>
          <div className='mx-3 flex'>
            {/* <input className=' border-[#0D4600]' type="checkbox" checked={isplace}  /> */}
            {isplace ? <img src={selectedgreen} alt='icon' /> : <img src={unselectedgreen} alt='icon' /> } 
             <label className='text-[#0D4600] pl-3 font-bold' > Yes</label>
          </div>
          <div className='flex mx-3'>
          {!isplace ? <img src={selectedred} alt='icon' /> : <img src={unselectedred} alt='icon' /> } 
           <label className='pl-3 text-[#FF0000] font-bold' > No</label>
          </div>
        </div>
      </div>
      {isplace ? <div className=' text-left p-5 rounded-md bg-[#FBFBFB] shadow-3xl w-9/12 mx-auto my-10 '>
        <p className='text-[22px] text-[#707070] font-medium'>Important Instructions</p>
        <p className='pt-5 text-[21px] max-tablet:text-[17px] text-[#707070]'>FITA Academy HR team tirelessly work with more than 1500+ Companies to recruit our students. However the number of Openings vary based on the Technology, Market condition & the City you are willing to work.</p>
        <p className='pt-5 text-[21px] max-tablet:text-[17px] text-[#707070]'> Do not make any payment to any of the companies that recruits you.</p>
      </div> : <div className=' text-center p-5 rounded-md bg-[#FBFBFB] shadow-3xl w-9/12 mx-auto my-10 '>
        <p className='pt-5 text-[21px] max-tablet:text-[17px] text-[#707070]'> You already got placed through FITA Academy & Joined the company. If you still need placement support, kindly speak to our HR.</p>
      </div>
      }</> : <><ClipLoader
      color="#036AD1"
      loading={loading}
      cssOverride={override}
    /></>}
      
    </div>
  )
}

export default Myplacement