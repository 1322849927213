// import { sendMail } from "./email";
import axios from "axios";
import { errorNotifier } from "../function/commonHelper";
import {
  EmailEndPoint,
  EmailOTPContent,
  TableNames,
} from "../function/config/Tables";
import { FetchData, UpdateData } from "../function/crud";
import { toast } from "react-hot-toast";
import { datePlusMinutes } from "./CommonFunctions";
import {
  CreateUserByAdminAuth,
  generateRandomPassword,
  signInWithPhoneAndPassword,
} from "../function/auth";
import { createStudent } from "../function/Student/SignUp/Student";
import { SUPABASE_CLIENT } from "./Supabase";
import { SupabaseClient } from "@supabase/supabase-js";
import * as Sentry from "@sentry/react";

export const REACT_APP_SMS_API_KEY = "Ad576702203d72b63901018405ac8ef98";

export const REACT_APP_SMS_SID = "HXIN1731782665IN";

export const REACT_APP_SMS_TEMPLATE_ID = "1707163247940044322";

export const REACT_APP_SMS_SENDER_ID = "FITAAC";

export const sendOTPToEmail = async (userId: string, receiver: string) => {
  try {
    const OTP = generateOTP();
    const { error } = await UpdateData(
      TableNames.students,
      {
        otp: {
          otp: OTP,
          expiryTime: datePlusMinutes(10),
        },
      },
      { conditionKey: "uid", conditionValue: userId }
    );
    if (error) throw error;
    const emailContent = {
      to: receiver,
      subject: EmailOTPContent.subject,
      message: EmailOTPContent.html + OTP,
    };
    const emailResponse = await axios.post(EmailEndPoint, emailContent);
    return emailResponse?.status === 200;
  } catch (error) {
    Sentry.captureException(error);
    return errorNotifier(error);
  }
};

// export const sendOTPService = async (receiver: string, service: "email" | "sms" = "email") => {
//     try {
//         const columnname = service === "email" ? "email" : "phoneNumber"
//         receiver =  service === "email" ? receiver: `+${receiver}`
//         const { data: userData, error: userQueryError } = await FetchData(TableNames.students).eq(columnname, receiver);
//         if (userQueryError) throw userQueryError;
//         if (!userData?.length) {
//             const password = generateRandomPassword(10);
//             const userCreation:any = await CreateUserByAdminAuth({
//                 phone: receiver,
//                 phone_confirm: true,
//                 password
//             });
//             await createStudent({
//                 uid: userCreation?.user?.id,
//                 phoneNumber: receiver,
//                 password

//             })
//         }

// //for otp send function live project code

//         //  const { data } = await SUPABASE_CLIENT.functions.invoke('sendOTPService', {
//         //     body: { receiver:receiver, service: service },
//         //   })
//           return userData
//     } catch (error) {
//         Sentry.captureException(error);
//         return errorNotifier(error);

//     }
// }

export const sendOTPService = async (
  receiver: string,
  service: "email" | "sms" = "email"
) => {
  try {
    console.log(receiver);
    const columnname = service === "email" ? "email" : "phoneNumber";
    const { data: userData, error: userQueryError } = await FetchData(
      TableNames.students
    ).eq(columnname, receiver);
    console.log("sendOTPService init : ", userData, userQueryError);

    if (userQueryError) throw userQueryError;
    if (!userData?.length) {
      const password = generateRandomPassword(10);
      const userCreation: any = await CreateUserByAdminAuth({
        phone: receiver,
        phone_confirm: true,
        password,
      });
      await createStudent({
        uid: userCreation?.user?.id,
        phoneNumber: receiver,
        password,
      });
    }

    const OTP = "5678"; // generateOTP();
    const { error, data } = await UpdateData(
      TableNames.students,
      {
        otp: {
          otp: OTP,
          expiryTime: datePlusMinutes(10),
        },
      },
      {
        conditionKey: service === "email" ? "email" : "phoneNumber",
        conditionValue: receiver,
      }
    );
    console.log("UpdateData : ", data);
    if (error) throw error;
    const emailContent = {
      to: receiver,
      subject: EmailOTPContent.subject,
      message: EmailOTPContent.html + OTP,
    };
    if (service === "email") {
      const emailResponse = await axios.post(EmailEndPoint, emailContent);
      return emailResponse?.status === 200;
    } else {
      const smsResponse = await sendSMSOTP({
        // to: `+91${receiver}`,
        to: `${receiver}`,
        OTP,
      });
      console.log(smsResponse, "smsResponse");
      if (smsResponse) {
        return true;
      } else {
        let errMessage = {
          message: "OTP sending failed",
        };
        throw errMessage;
      }
    }
  } catch (error) {
    return errorNotifier(error);
  }
};

// export const setEmailVerifiedTrue = async (userId: string) => {
//     return await ;
// }

export const EmailOTPVerification = async (
  userId: string,
  otp: string,
  email: string
) => {
  try {
    const { data, error }: any = await FetchData(TableNames.students, [
      "otp",
      "password",
    ]).eq("uid", userId);
    if (error) throw error;
    let student = data[0];
    if (student?.otp?.otp !== otp) {
      let verificationError = { message: "Invalid OTP" };
      throw verificationError;
    }
    if (student?.otp?.expiryTime < new Date().getTime()) {
      let errMessage = {
        message: "OTP expired",
      };
      throw errMessage;
    }
    const { error: OTPError } = await UpdateData(
      TableNames.students,
      {
        otp: null,
        is_email_verified: true,
        email,
      },
      { conditionKey: "uid", conditionValue: userId }
    );
    if (OTPError) throw OTPError;
    toast.success("Email verified Successfully");
    return true;
  } catch (error) {
    Sentry.captureException(error);
    return errorNotifier(error);
  }
};

//
// export const OTPVerification = async (otp: string | number, receiver: string, service: "email" | "sms" = "email") => {
//     try {
//         const conditionKey = service === "email" ? "email" : "phoneNumber";
//         const { data, error }: any = await FetchData(TableNames.students, ["otp","password"]).eq(conditionKey, receiver);
//         if (error) throw error;
//         let student = data[0];
//         if (student?.otp?.otp !== otp) {
//             let verificationError = { message: "Invalid OTP" }
//             throw verificationError;
//         }
//         if (student?.otp?.expiryTime < new Date().getTime()) {
//             let errMessage = {
//                 message:"OTP expired"
//             }
//             throw errMessage
//         }
//         const { error: OTPError } = await UpdateData(TableNames.students, {
//             otp: null,
//             ...service === "email" && {
//                 is_email_verified: true
//             }
//         }, { conditionKey, conditionValue: receiver });
//         if (OTPError) throw OTPError;
//         let user;
//         if (service === "sms") {
//             user=await signInWithPhoneAndPassword(receiver, student?.password);
//         }
//         toast.success("OTP verified successfully")
//         return service === "sms" ? user : true;
//     } catch (error) {
//         return errorNotifier(error);
//     }
// }

// Live Code

// export const OTPVerification = async (
//   otp: string | number,
//   receiver: string,
//   service: "email" | "sms" = "email"
// ) => {
//   try {
//     const { data } = await SUPABASE_CLIENT.functions.invoke("OTPVerify", {
//       body: { receiver: receiver, service: service, otp: otp },
//     });
//     if (data.status == "success") {
//       return data.data;
//     } else {
//       return false;
//     }
//   } catch (error) {
//     Sentry.captureException(error);
//     return errorNotifier(error);
//   }
// };

export const OTPVerification = async (
  otp: string | number,
  receiver: string,
  service: "email" | "sms" = "email"
) => {
  try {
    const conditionKey = service === "email" ? "email" : "phoneNumber";
    const { data, error }: any = await FetchData(TableNames.students, [
      "otp",
      "password",
    ]).eq(conditionKey, receiver);
    console.log(data, error);

    if (error) throw error;
    let student = data[0];
    console.log(student);
    if (student?.otp?.otp !== otp) {
      let verificationError = { message: "Invalid OTP" };
      throw verificationError;
    }
    if (student?.otp?.expiryTime < new Date().getTime()) {
      let errMessage = {
        message: "OTP expired",
      };
      throw errMessage;
    }
    const { error: OTPError } = await UpdateData(
      TableNames.students,
      {
        otp: null,
        ...(service === "email" && {
          is_email_verified: true,
        }),
      },
      { conditionKey, conditionValue: receiver }
    );
    console.log(OTPError);
    if (OTPError) throw OTPError;
    let user;
    if (service === "sms") {
      user = await signInWithPhoneAndPassword(receiver, student?.password);
    }
    return service === "sms" ? user : true;

    // const columnname = service === "email" ? "email" : "phoneNumber"
    // const { data: userData, error: userQueryError } = await FetchData(TableNames.students).eq(columnname, receiver);
    // console.error(userData)
    // return userData; // fix
  } catch (error) {
    return errorNotifier(error);
  }
};

export const generateOTP = () => {
  return Math.floor(100000 + Math.random() * 900000).toString();
};

// export const sendSMSOTP = async ({ to, OTP }: { to: string; OTP: string }) => {
//   // const OTP = generateOTP();
//   const TO_NUMBER = to;
//   const MESSAGE = `Your OTP is ${OTP} to login into your FITA Academy account. This OTP is valid for 10 minutes`;

//   // const data = {
//   //     to: "+1"+TO_NUMBER,
//   //     type: 'OTP',
//   //     sender: SENDER_ID,
//   //     body: MESSAGE,
//   //     template_id: TEMPLATE_ID,
//   // };

//   // const config = {
//   //     headers: {
//   //         'Content-Type': "application/json",
//   //         'api-key': API_KEY,
//   //     },
//   // };
//   const apiUrl = `https://api.kaleyra.io/v1/${process.env.REACT_APP_SMS_SID}/messages`;
//   // const senderId = SENDER_ID;
//   // const messageType = 'OTP';
//   // const messageBody = `Your OTP is {${OTP}} to login into your FITA Academy account. This OTP is valid for 10 minutes`;
//   // const source = 'API';
//   // const optionalParameter = '<OPTIONAL_PARAMETER>';
//   // const optionalParameter1 = '<OPTIONAL_PARAMETER1>';
//   // const optionalParameter2 = '<OPTIONAL_PARAMETER2>';
//   // const callbackObj = '<CALLBACK_OBJ>';

//   // Create an axios instance with the API key in the headers
//   const axiosInstance = axios.create({
//     baseURL: apiUrl,
//     headers: {
//       "api-key": process.env.REACT_APP_SMS_API_KEY,
//       "Content-Type": "application/json",
//     },
//   });
//   const postData = {
//     to: TO_NUMBER,
//     type: "OTP",
//     sender: process.env.REACT_APP_SMS_SENDER_ID,
//     body: MESSAGE,
//     template_id: process.env.REACT_APP_SMS_TEMPLATE_ID,
//   };

//   // Send the POST request
//   return Promise.resolve(
//     axiosInstance
//       .post("", postData)
//       .then((response) => {
//         return true;
//       })
//       .catch((error) => {
//         console.error("Error sending SMS:", error);
//         return false;
//       })
//   );
//   //     .then((response:any) => {
//   //     })
//   //     .catch((error:any) => {
//   //         console.error('Error sending message:', error);
//   //     });

//   // }
// };

export const sendSMSOTP = async ({ to, OTP }: { to: string; OTP: string }) => {
  // const OTP = generateOTP();
  const TO_NUMBER = to;
  const MESSAGE = `Your OTP is ${OTP} to login into your FITA Academy account. This OTP is valid for 10 minutes`;

  // const data = {
  //     to: "+1"+TO_NUMBER,
  //     type: 'OTP',
  //     sender: SENDER_ID,
  //     body: MESSAGE,
  //     template_id: TEMPLATE_ID,
  // };

  // const config = {
  //     headers: {
  //         'Content-Type': "application/json",
  //         'api-key': API_KEY,
  //     },
  // };
  const apiUrl = `https://api.kaleyra.io/v1/${REACT_APP_SMS_SID}/messages`;
  // const senderId = SENDER_ID;
  // const messageType = 'OTP';
  // const messageBody = `Your OTP is {${OTP}} to login into your FITA Academy account. This OTP is valid for 10 minutes`;
  // const source = 'API';
  // const optionalParameter = '<OPTIONAL_PARAMETER>';
  // const optionalParameter1 = '<OPTIONAL_PARAMETER1>';
  // const optionalParameter2 = '<OPTIONAL_PARAMETER2>';
  // const callbackObj = '<CALLBACK_OBJ>';

  // Create an axios instance with the API key in the headers
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    headers: {
      "api-key": REACT_APP_SMS_API_KEY,
      "Content-Type": "application/json",
    },
  });
  const postData = {
    to: TO_NUMBER,
    type: "OTP",
    sender: REACT_APP_SMS_SENDER_ID,
    body: MESSAGE,
    template_id: REACT_APP_SMS_TEMPLATE_ID,
  };

  // Send the POST request
  return Promise.resolve(
    axiosInstance
      .post("", postData)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        return false;
      })
  );
  //     .then((response:any) => {
  //     })
  //     .catch((error:any) => {
  //     });

  // }
};

export const checkSMSStatus = async (messageId: string) => {
  let url = `https://api.kaleyra.io/v1/${process.env.REACT_APP_SMS_SID}/messages/status`;
  const API_KEY = process.env.REACT_APP_SMS_API_KEY;
  const axiosInstance = axios.create({
    baseURL: url,
    headers: {
      "api-key": API_KEY,
      "Content-Type": "application/json",
    },
  });
  const queryParams = {
    message_ids: messageId,
  };
  axiosInstance
    .get("", { params: queryParams })
    .then((response) => {})
    .catch((error) => {
      console.error("Error checking message status:", error);
    });
};
