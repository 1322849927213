import { calculatePercentage, errorNotifier } from "../../commonHelper"
import { TableNames } from "../../config/Tables"
import { FetchData } from "../../crud"
import { progressByModuleCalculation } from "../Profile/Profile"

export const getCourseMaterials = async (batchId: number | string, studentUid: string) => {
    try {
        const { data, error }: any = await FetchData(TableNames.batches, ["batch_progress_in_hrs,course:courses(course_materials, course_name, duration)", "branch:branches(branch_name)", "trainer:users(first_name, last_name)"]).eq("id", batchId)
        // edited by frontend
        if (!data?.length) {
            return errorNotifier('no data')
        }
        // 

        const { data: sessions } = await FetchData(TableNames.sessions, ["duration"]).contains("attendees", [studentUid]).eq("batch_id", batchId)
        // edited by frontend
        if (!data?.sessions) {
            return errorNotifier('no data')
        }
        // 
        const studentProgress = sessions?.reduce((acc: any, currVal: any) => Number(acc) + Number(currVal?.duration), 0)

        const courseMaterials = {
            ...data[0], course_progress: Math.round(calculatePercentage(studentProgress, data[0]?.course?.duration)), trainer: data[0].first_name + data[0].last_name
        }

        if (error) throw error
        return courseMaterials
    } catch (error) {
        return errorNotifier(error)
    }
}

export const getCourses = async (studentUid: string) => {
    try {
        const { data, error }: any = await FetchData(TableNames.trainings, ["id", "course:courses(id, course_name, type, thumbnail)", "batch_id"]).eq("student_id", studentUid)
        if (error) throw error
        return data
      
    } catch (error) {
        return errorNotifier(error)
    }
}

// export const getIndividualCourseMaterials = async (trainingId: number) => {
//     try {
//         const { data: training_data, error: training_error }: any = await FetchData(TableNames.trainings, ["*", "batch:batches(duration,course:courses(course_materials, course_name), user:users(first_name, last_name), branch:branches(branch_name), city:cities(city_name), session:sessions(*))"]).eq("id", trainingId)
//         // edited by frontend
//         if (!training_data.length) {
//             return errorNotifier('no data')
//         }
//         // 
//         const session_data = training_data[0].batch.session?.map((session: any) => ({ ...session, is_present: session?.attendees?.includes(training_data[0].student_id) }));

//         const filtered_students = session_data?.filter((stu: any) => { return stu?.is_present === true })

//         const total_session_time = filtered_students?.reduce((acc: any, currVal: any) => Number(acc) + Number(currVal?.duration), 0)

//         const altered_data = {
//             course_name: training_data[0].batch.course.course_name,
//             course_materials: training_data[0].batch.course.course_materials,
//             trainer: training_data[0].batch.user.first_name + training_data[0].batch.user.last_name,
//             branch: training_data[0].batch.branch.branch_name,
//             city: training_data[0].batch.city.city_name,
//             course_progress: calculatePercentage(total_session_time, Number(training_data[0]?.duration))
//         }

//         if (training_error) throw training_error
//         return altered_data

//     } catch (error) {
//         return errorNotifier(error)
//     }
// }


export const getIndividualCourseMaterials = async (trainingId: number) => {
    try {
        const { data: training_data, error: training_error }: any = await FetchData(TableNames.trainings, ["*", "batch:batches(duration,course:courses(course_materials, course_name), user:users(first_name, last_name), branch:branches(branch_name), city:cities(city_name), session:sessions(*))"]).eq("id", trainingId)
     
        //  edited by frontend
        if (!training_data.length) {
            return errorNotifier('no data')
        }

       
        if(training_data[0].batch == null){
            throw{message:'no data'}
        }

        const session_data = training_data[0].batch?.session?.map((session: any) => ({ ...session, is_present: session?.attendees?.includes(training_data[0].student_id) }));
        
        
        const filtered_students = session_data?.filter((stu: any) => { return stu?.is_present === true })
        const total_session_time = filtered_students?.reduce((acc: any, currVal: any) => Number(acc) + Number(currVal?.duration), 0)
        const altered_data = {
            course_name: training_data[0].batch.course.course_name,
            course_materials: training_data[0].batch.course.course_materials,
            trainer: training_data[0].batch.user.first_name + training_data[0].batch.user.last_name,
            branch: training_data[0].batch.branch.branch_name,
            city: training_data[0].batch.city.city_name,
            // course_progress: calculatePercentage(total_session_time, Number(training_data[0]?.duration)),
            course_progress: total_session_time && training_data[0].batch?.duration ? calculatePercentage(total_session_time, Number(training_data[0].batch?.duration)) : 0,
        }
        // by frontend

        // course_progress: calculatePercentage(total_session_time, Number(training_data[0].batch.course?.duration)),

        if (training_error) throw training_error
        return altered_data
    } catch (error) {
        return errorNotifier(error)

    }
}



export const getIndividualFreeCourseMaterials = async (trainingId: number) => {
    try {
        const { data, error }: any = await FetchData(TableNames.trainings, ["student:student_id(name)", "city:cities(city_name)", "course:course_id(id, course_name, duration, videos, course_materials)", "progress_by_module", "date_of_completion", "branch:branches(branch_name))"]).eq("id", trainingId);
        // edited by frontend
        if (!data.length) {
            return errorNotifier('no data')
        }
        // 
        if (error) throw error

        return { ...data[0], ...progressByModuleCalculation(data[0].course, data[0].progress_by_module) };

    } catch (error) {
        return errorNotifier(error)
    }
}