import React, { useEffect, useState, CSSProperties } from "react";
import { useLocation } from "react-router-dom";
import { ApplyjobCardDesc } from "../elements/cards";
import Header from "../elements/header";
import rightarrowhead from "../../images/rightheadarrow.svg";
import {
  getAppliedJobById,
  getJobsDataById,
} from "../../function/Student/job/jobs";
import { dateFormat } from "../elements/dateConvertion";
import closeicon from "../../images/close-red-icon.svg";
// import { uuid } from "../../redux/useReducer";
import ClipLoader from "react-spinners/ClipLoader";
import Footer from "../elements/Footer";

function AppliedJobDesc() {
  const { state } = useLocation();
  const uuid:any = localStorage.getItem('uid')
  const [JobDetails] = useState(state);
  const [jobDesc, setJobDesc] = useState({}) as any;
  const [jobState, setJobstate] = useState(false);
  const [statusState, setStatusstate] = useState(true);
  let [loading, setLoading] = useState(true);

  const override: CSSProperties = {
    flex: 1,
    marginTop: 240,
    justifyContent: "center",
    alignItems: "center",
  };

 

  const data = async () => {
    setLoading(true)
    const jobdata = await getAppliedJobById(JobDetails.id);
    setJobDesc(jobdata);
    setJobstate(true);
    setLoading(false)
  };
  const onClickhandle = () => {
    setStatusstate(false);
  };
  useEffect(() => {
    data();
  }, []);

  // let job_detais_text = {
  //     'Not Shortlisted':'Hi',
  //     'Shortlisted But Not Placed':'Bye'
  // }
  return (
    <div className=" cursor-default">
      <Header />
      {jobState ? (
        <div>
          {jobDesc?.status == "Not Shortlisted" ||
          jobDesc?.status == "Shortlisted But Not Placed" ? (
            <div>
              {statusState ? (
                <div className=" bg-[#F9FCFF] py-40 min-h-screen">
                  <div
                    className={`block py-20 w-[40%] align-middle mx-auto text-center relative  justify-center p-6 bg-white border border-gray-200 rounded-lg shadow `}
                  >
                    <img
                      className="cursor-pointer  right-5  top-3 absolute mb-5 w-6 h-6 "
                      onClick={onClickhandle}
                      src={closeicon}
                      alt="closeicon"
                    />
                    <p className="text-[25px]  text-[#000] align-middle">
                      {jobDesc?.status == "Shortlisted But Not Placed" ? (
                        <p className="m-3 ">
                          Good try. Unfortunately, the company did not select
                          you in the Interview.
                        </p>
                      ) : (
                        <p className="m-3 ">
                          If you have not Reviewed your Resume with a trainer or
                          an expert, ensure it's up to date so that you have a
                          higher chance of getting shortlisted
                        </p>
                      )}
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <ApplyjobCardDesc
                    title={jobDesc?.job?.position}
                    subtitle={jobDesc?.job?.company}
                    location={jobDesc?.job?.location}
                    date={dateFormat(jobDesc?.job?.inserted_at, "DD MMM YYYY")}
                    opening={jobDesc?.job?.number_of_openings}
                    salary={jobDesc?.job?.salary}
                    status={JobDetails.status}
                  />
                  <div className=" w-7/12 mx-auto  max-laptop:w-7/12 max-tablet:w-10/12 max-mobile:w-10/12">
                    {jobDesc?.job?.about_company ? (
                      <div>
                        <p className="text-[26px] max-mobile:text-[20px] text-[#707070] text-left font-medium py-1">
                          About Company
                        </p>
                        <p className="text-18px] text-left text-[#707070] py-1">
                          {jobDesc?.job?.about_company}
                        </p>
                      </div>
                    ) : (
                      <p></p>
                    )}

                    {jobDesc?.job?.job_description != null ? (
                      <div>
                        <p className="text-[26px] max-mobile:text-[20px] text-[#707070] text-left font-medium py-1">
                          Job Description
                        </p>
                        <p className="text-18px] text-left text-[#707070] py-1">
                          {jobDesc?.job?.job_description}
                        </p>
                      </div>
                    ) : (
                      <p></p>
                    )}

                    <p className="text-[26px]  max-mobile:text-[20px] text-[#707070] text-left font-medium py-1">
                      Key Skills
                    </p>
                    {jobDesc?.job?.key_skills?.map((item: any) => {
                      return (
                        <ul className="text-left text-[18px]  text-[#707070] px-6 max-mobile:text-[14px] max-mobile:px-2 ">
                          <li className="flex items-center ">
                            {" "}
                            <img
                              className="w-3 h-3"
                              src={rightarrowhead}
                              alt="icon"
                            />{" "}
                            <span className="pl-4">{item}</span>
                          </li>
                        </ul>
                      );
                    })}
                    <p className="text-[26px]  max-mobile:text-[20px] text-[#707070] text-left font-medium py-1">
                      Eligibility
                    </p>
                    <p className="text-[18px] text-left text-[#707070] my-2">
                      {jobDesc?.job?.eligibility}
                    </p>
                    <div className=" shadow-3xl justify-between flex p-5 mt-2 items-center  mb-10 rounded-md max-mobile:block">
                      <p className=" text-[16px] font-medium text-[#000000]">
                        Last Date to Apply:{" "}
                        <span className=" text-[#036AD1]">
                          {dateFormat(
                            jobDesc?.job?.last_date_to_apply,
                            "DD MMM YYYY"
                          )}
                        </span>{" "}
                      </p>
                      <p className="text-[20px]  w-[102px] h-[32px] mt-2 max-mobile:mx-auto text-[#FFFFFF] bg-[#036AD1] rounded-full px-4">
                        Applied
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              <ApplyjobCardDesc
                title={jobDesc?.job?.position}
                subtitle={jobDesc?.job?.company}
                location={jobDesc?.job?.location}
                date={dateFormat(jobDesc?.job?.inserted_at, "DD MMM YYYY")}
                opening={jobDesc?.job?.number_of_openings}
                salary={jobDesc?.job?.salary}
                status={JobDetails.status}
              />
              <div className=" w-7/12 mx-auto  max-laptop:w-7/12 max-tablet:w-10/12 max-mobile:w-10/12">
                <p className="text-[26px] max-mobile:text-[20px] text-[#707070] text-left font-medium py-1">
                  About Company
                </p>
                <p className="text-18px] text-left text-[#707070] py-1">
                  {jobDesc?.job?.about_company}
                </p>
                <p className="text-[26px] max-mobile:text-[20px] text-[#707070] text-left font-medium py-1">
                  Job Description
                </p>
                <p className="text-18px] text-left text-[#707070] py-1">
                  {jobDesc?.job?.job_description}
                </p>
                <p className="text-[26px] max-mobile:text-[20px] text-[#707070] text-left font-medium py-1">
                  Key Skills
                </p>
                {jobDesc?.job?.key_skills?.map((item: any) => {
                  return (
                    <ul className="text-left text-[18px]  text-[#707070] px-6 max-mobile:text-[14px] max-mobile:px-2 ">
                      <li className="flex items-center ">
                        {" "}
                        <img
                          className="w-3 h-3"
                          src={rightarrowhead}
                          alt="icon"
                        />{" "}
                        <span className="pl-4">{item}</span>
                      </li>
                    </ul>
                  );
                })}
                <p className="text-[26px] max-mobile:text-[20px] text-[#707070] text-left font-medium py-1">
                  Eligibility
                </p>
                <p className="text-[18px] text-left text-[#707070] my-2">
                  {jobDesc?.job?.eligibility}
                </p>
                <div className=" shadow-3xl justify-between flex p-5 mt-2 items-center  mb-10 rounded-md max-mobile:block">
                  <p className=" text-[16px] font-medium text-[#000000]">
                    Last Date to Apply:{" "}
                    <span className=" text-[#036AD1]">
                      {dateFormat(
                        jobDesc?.job?.last_date_to_apply,
                        "DD MMM YYYY"
                      )}
                    </span>{" "}
                  </p>
                  <p className="text-[20px] max-mobile:text-[16px] max-mobile:pt-1 w-[102px] h-[32px] mt-2 max-mobile:mx-auto text-[#FFFFFF] bg-[#036AD1] rounded-full px-4">
                    Applied
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <ClipLoader
            color="#036AD1"
            loading={loading}
            cssOverride={override}
          />
        </div>
      )}

      <Footer/>
    </div>
  );
}

export default AppliedJobDesc;
