import { ClassNames } from '@emotion/react'
import React from 'react'

interface FitacertifcateDetails {
    name: string,
    course: string,
    branch?:string,
    city?:string,
    duration: number,
    completion: any,
    sNo: string,
    className:string
}

const Fitacertifcate = ({ name, course, branch,city, duration, completion, sNo, className }: FitacertifcateDetails) => {
    return (
        <div className={`${className} w-[595px] h-[835px] max-tablet:w-[400px] max-tablet:h-[590px] text-[#4d67b0] relative`}>
            <p className='absolute top-[26px] right-0 text-[11px] max-tablet:top-[16px] font-bold text-center w-[20%]'>{sNo}</p>
            <p className='absolute top-[290px] max-tablet:top-[190px] text-[19px] font-bold text-center w-[100%]'>{name}</p>
            <p className='absolute top-[380px] max-tablet:top-[250px] text-[19px] font-bold text-center w-[100%]'>{course}</p>
            <p className='absolute bottom-[54px] left-[185px] max-tablet:left-[125px] max-tablet:bottom-[65px] text-[13px] max-tablet:text-[10px] font-bold'>{completion}</p>
            <p className='absolute bottom-[78px] left-[160px] text-[13px]  max-tablet:left-[115px] max-tablet:bottom-[82px] max-tablet:text-[10px] font-bold'>
            {`${duration} ${duration > 1 ? 'Hours' : 'Hour'}`}
            </p>
           {branch == null ? '':<p className='absolute bottom-[103px] left-[90px] text-[13px]  max-tablet:left-[65px] max-tablet:bottom-[98px] max-tablet:text-[10px] font-bold'>{branch},{city}</p> } 
        </div>
    )
}

export default Fitacertifcate
