import React from 'react'
import Rarrow from '../../images/rightArrow.svg'
import Edit from '../../images/quickAssesIcon.svg'
import bullarrow from '../../images/cleargoalIcon.svg'
import { useNavigate } from 'react-router-dom'
function BeforeAssessment() {
    const navigate = useNavigate()

    const handleclickAssessment = () => {
        navigate('/beforeassessment1')
    }
    const handleclickcleargoal = () => {
        navigate('/home')
    }
    return (
        <div className="bg-[url('../public/images/frontBg.png')] bg-cover h-[100vh] ">
            <div className=' grid grid-cols-2 text-center justify-center pt-64  w-full max-desktop:pt-36  max-laptop:grid-cols-1 max-laptop:pt-32  max-mobile:grid-cols-1 max-mobile:pt-16'>
                <div className='my-3 relative  mx-auto p-5 flex border-solid shadow-md bg-[#fff] cursor-pointer w-9/12' onClick={() => handleclickAssessment()}>
                    <div className='m-3 '>
                        <img className='w-[80px]  h-[80px] mr-6 mt-1 max-monitor:mr-0 ' src={Edit} alt='icon' />
                    </div>
                    <div className='pb-10 font-[roboto] font-bold  text-left w-4/4'>
                        <p className='text-[31px] text-[#036AD1] flex pt-5 max-mobile:block max-mobile:text-[22px]'>Take the Quick Assessment to find your Goal</p>
                        <div className='text-[16px] pt-3 text-[#3E3E3E] w-3/4'>The Assessment takes about 3-5 Minutes to Complete</div>
                        <img className='w-6 absolute right-6 bottom-2 my-5  max-laptop:my-5' src={Rarrow} alt='arrow' />
                    </div>
                </div>
                <div className='my-3 relative mx-auto p-5 flex border-solid shadow-md bg-[#fff] cursor-pointer w-9/12' onClick={() => handleclickcleargoal()}>
                    <div className=' m-3 '>
                        <img className='w-[50px]  h-[50px] mr-6 mt-5 max-monitor:mr-0 ' src={bullarrow} alt='icon' />
                    </div>
                    <div className=' pb-10 font-[roboto]  font-bold   text-left w-4/4'>
                        <p className='text-[31px] text-[#036AD1] w-3/4 flex pt-5 max-mobile:block max-mobile:text-[22px]'>I am Clear with My Goal</p>
                        <div className='text-[16px] pt-3 text-[#3E3E3E] w-3/4'>Let me pick the Course</div>
                        <img className='w-6 absolute right-6 bottom-2 my-5  max-laptop:my-5' src={Rarrow} alt='arrow' />
                    </div>
                </div>
               
            </div>
        </div>
    )
}

export default BeforeAssessment