import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { errorNotifier } from '../../function/commonHelper';
import { TrainerLogin } from './pages/TrainerLogin';
import BatchInformation from './pages/BatchInformation';
import AddSession from './pages/AddSession';
import { ProgressModal } from './pages/ProgressModal';
import TrainerTransaction from './pages/TrainerTransaction';
import CourseProgress from './pages/CourseProgress';

const TrainerPages: Record<string, JSX.Element> = {
  'login': <TrainerLogin />,
  'course-progress':<CourseProgress />,
  'batch': <BatchInformation />,
  'add-session': <AddSession />,
  'session-bulk-update': <ProgressModal />,
  'transaction': <TrainerTransaction />,
};

export default function TrainerLayout() {
  const location = useLocation();
  const currentPage = location.pathname.split('/').pop()
  return (
    <>
      {TrainerPages[currentPage?currentPage:'login']}
    </>
  );
}
