// import React from 'react'
// import HeaderData from "../../content/header.json";
// import { NavLink, Link, useNavigate, Routes } from "react-router-dom";
// import { Iconbadge, JobIcon } from './header';

// export default function Footer() {
//   const navigate = useNavigate();
//     let jobs_list = ["/latestjob", "/appliedjob"];

//     const handleLink = (props: any) => {
//       navigate(props);
//     };

//   return (
//     <div className='h-[82px] relative bottom-0 max-mobile:flex max-desktop:hidden max-monitor:hidden max-tablet:hidden'>
//       <div className="fixed w-full py-0 justify-end  bottom-0   bg-[#FCFCFC] max-mobile:flex max-tablet:hidden hidden ">
//     <ul className="flex items-center text-center justify-between ">
//       {HeaderData?.map((item, index) => {
//         return (
//           <li
//             key={index}
//             className="text-[20px] basis-1/5 h-[70px] font-normal  max-laptop:text-[14px] max-tablet:text-[12px] max-mobile:text-[12px]"
//           >
//             <div className=" align-middle mx-3">
//               {item?.option === undefined ? (
//                 <NavLink
//                   to={item?.routeLink}
//                   className={({ isActive }) =>
//                     isActive
//                       ? "text-[#036AD1]  font-bold"
//                       : " font-normal  text-[#707070]"
//                   }
//                 >
//                     <p className='mx-auto flex justify-center mb-2'>
//                     <Iconbadge props={item} />
//                     </p>
                
//                   <p className=" max-desktop:text-[20px] block max-laptop:text-[16px] max-tablet:text-[14px] ">
//                     {" "}
//                     {item.navLink}{" "}
//                   </p>
//                 </NavLink>
//               ) : (
//                 <div className=" items-center">
//                   {/* <img className='w-[30px] h-[20px]' src={item.icon} alt='icons' /> */}
//                   <p className='mx-auto flex justify-center mb-2'>
//                      <JobIcon props={item} />
//                   </p>
//                   {jobs_list.includes(window.location.pathname) ? (
                    
//                     <select
            
//                       className="bg-[#FCFCFC] w-[75px] text-[#036AD1] text-[12px]  outline-none"
//                       onChange={(e) => handleLink(e.target.value)}
//                     >
//                       <option disabled>Jobs</option>
//                       {window.location.pathname == "/latestjob" ? (
//                         <option
//                           selected
//                           className=" text-[#036AD1] "
//                           value={item.routeLink1}
//                         >
//                           Latest Jobs
//                         </option>
//                       ) : (
//                         <option value={item.routeLink1}>
//                           Latest Jobs
//                         </option>
//                       )}
//                       {window.location.pathname == "/applied-job" ? (
//                         <option
//                           selected
//                           className=" text-[#036AD1]"
//                           value={item.routeLink}
//                         >
//                           Applied Jobs
//                         </option>
//                       ) : (
//                         <option value={item.routeLink}>
//                           Applied Jobs
//                         </option>
//                       )}
//                     </select>
                  
//                   ) : (
//                     <select
//                       className="bg-[#FCFCFC] w-[75px] text-[12px]   text-[#707070]  outline-none"
//                       onChange={(e) => handleLink(e.target.value)}
//                     >
//                       <option disabled selected>
//                         Jobs
//                       </option>
//                       {window.location.pathname == "/latestjob" ? (
//                         <option
//                           selected
//                           className=" text-[#036AD1]"
//                           value={item.routeLink1}
//                         >
//                           Latest Jobs
//                         </option>
//                       ) : (
//                         <option value={item.routeLink1}>
//                           Latest Jobs
//                         </option>
//                       )}
//                       {window.location.pathname == "/applied-job" ? (
//                         <option
//                           selected
//                           className=" text-[#036AD1]"
//                           value={item.routeLink}
//                         >
//                           Applied Jobs
//                         </option>
//                       ) : (
//                         <option value={item.routeLink}>
//                           Applied Jobs
//                         </option>
//                       )}
//                     </select>
                  
//                   )}
//                 </div>
//               )}
//             </div>
//           </li>
//         );
//       })}
//     </ul>
//   </div>
//     </div>

    
//   )
// }



import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import homeMenu from '../../content/homeMenu.json'
import Tooltip from '@mui/material/Tooltip';
import profileIcon from '../../images/profileIcon.svg'
import { Menu } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { NavLink, Link, useNavigate, Routes } from "react-router-dom";
// import MenuIcon from '@mui/icons-material/Menu';
import MenuIcon from '@mui/icons-material/Menu';


export default function Footer() {
 
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
      

  };

  async function signout() {
    window.localStorage.clear();
    localStorage.removeItem('uid')
    navigate('/')
}
  return (
    <div className='h-[42px] relative bottom-0 max-mobile:flex max-desktop:hidden max-monitor:hidden max-tablet:hidden'>
  
        <div className="fixed w-full py-2 justify-end  bottom-0   bg-[#FCFCFC] max-mobile:flex max-tablet:hidden hidden ">

    <React.Fragment>
    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
      <Tooltip title="">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{}}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <MenuIcon color='primary' />
          
          {/* <img className='w-7 ' src={profileIcon} alt='profileIcon' /> */}
        </IconButton>
      </Tooltip>
    </Box>
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {homeMenu.map(data => {
        return data.routeLink !== 'logout'? <NavLink to={`${data.path}`} state={{ myState: `${data.routeLink}` }}>
          <MenuItem onClick={handleClose}>
            {data.value}
          </MenuItem>
        </NavLink>:
        <MenuItem onClick={signout}>
            {data.value}
          </MenuItem>
      })}

    </Menu>
  </React.Fragment>
  </div>
  </div>
  )
}
