import * as yup from "yup";


export const basicSchema = yup.object().shape({
    firstname: yup.string()
    .min(3, 'Must be 3 characters or less')
    .required('Required')
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  lastname: yup.string()
    .min(1, 'Must be 1 characters or less')
    .required('Required')
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  email: yup.string().email("Please enter a valid email").required("Required"),
});
