import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { DropdownSingleInputWithCheckbox } from '../components/DropdownSingleInputWithCheckbox';
import { createSession, getStudentsByBatchid, mapFunction } from '../Functions/TrainerFunctions';
import { StudentDetails } from '../components/StudentDetails';
import { errorNotifier } from '../../../function/commonHelper';
import { PulseLoader } from 'react-spinners';

function BulkUpdate({selectedBatches,setBulk}:any) {
    interface Session {
        batch_id: string;
    }
    type FormDataType = {
        session_date: string;
        start_time: string;
        end_time: string;
        topic: string;
        subtopics: string[];
        attendees: { [key: string]: any[] };
    };

    const [batchData, setBatches] = useState<Session[]>([])
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { state } = useLocation();
    const getData = async()=>{
        setIsLoading(true)
        const data = Array.from(selectedBatches);
        let batchDict:any ={}
        const mapData = async () => {
          const mappedData = await Promise.all(
            data?.map(async (dt: any) => {
              const student_list = await getStudentsByBatchid(dt?.id);
              batchDict[dt.id] = []
              return {
                batch_id: dt?.id,
                trainer_id: dt?.trainer?.uid,
                course_name: dt?.course_name,
                batch_code: dt?.batch_code,
                mode: dt?.mode,
                student_list: student_list,
              };
            }) || []
          );

          return mappedData;
          
        
        };
        const result: any = await mapData();
        setBatches(result);
        setFormdata((prevState: any) => ({
            ...prevState,
            attendees: batchDict
        }));
        setIsLoading(false)
    }

    useEffect(()=>{
        getData()
    },[])

    // Initialize the state with the correct type
    const [formData, setFormdata] = useState<FormDataType>({
        session_date: '',
        start_time: '',
        end_time: '',
        topic: '',
        subtopics: [],
        attendees:{}
    });
    const Time = [
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
    ];

    const firstObject: any = formData.subtopics?.[0];

    const AddSubTopic = () => {
        const newList = [...formData.subtopics, '']
        setFormdata((prevState: any) => ({
            ...prevState,
            subtopics: newList
        }));
    };

    const deleteSubTopic = (index: number) => {
        const newArray = [...formData.subtopics];
        newArray.splice(index, 1);
        setFormdata((prevState: any) => ({
            ...prevState,
            subtopics: newArray
        }));
    };



    const onSubmit =async()=>{
        const sessionDataList = batchData.map((session:any)=>{
            return {
                title:session.course_name,
                trainer_id: session?.trainer_id,
                start_time: formData.start_time,
                end_time: formData.end_time,
                details: {
                  topic: formData.topic,
                  subtopics: formData.subtopics,
                },
                session_date: formData.session_date,
                attendees: formData.attendees[session.batch_id],
                batch_id: session?.batch_id,
                session_status: "Success",
            }
        })
        const filterAttendeesPresent: any =
          sessionDataList?.length &&
          Object.values(sessionDataList)?.filter(
            (fil: any) => fil?.attendees.length > 0
          );
        let allSessionsSuccessful = true;
    
        if (filterAttendeesPresent.length) {
            for (const session of filterAttendeesPresent) {
                const response = await createSession(session);
                if (!response) {
                    allSessionsSuccessful = false;
                    break;
                }
            }
    
            if (allSessionsSuccessful) {
                setBulk(false)
            } else alert('Something went wrong')
        }
        // if (formData.attendees.length===0) {
        //     alert('Please Select Attendees')
        //     // .error(`Please Select Attendees`)
        //   } else {
        //       const response = await createSession(formData)
        //     }
    }

    const handleChangeForSubTopics = (
        index: number,
        value: string,
    ) => {
        const newArray = [...formData.subtopics];
        newArray[index] = value
        setFormdata((prevState: any) => ({
            ...prevState,
            subtopics: newArray
        }));
    };

    if (isLoading) {
        return (
            <div className="bg-[#F9FCFF] h-screen flex items-center justify-center">
                <PulseLoader color="#065FEF" size={8} />
            </div>
        );
    }



    // console.log(state,'stt')
    return (
        <div className='bg-[#036AD1] text-left pb-2 h-full'>
            <div className='bg-[#e9e5f1] pb-2 rounded-md m-2 h-fulls'>
                <div className='bg-[#fff] rounded p-3  shadow-md'>
                    <p className='text-start text-[#a8a9a8] font-semibold pb-2'>Add Session</p>
                    <hr></hr>
                    <div className=' font-medium text-[14px]'>
                        {/* <div className='m-2'>
                            <label className='text-[#a8a9a8]' htmlFor="">Batch Code</label>
                            <p>{batch.batch_code}</p>
                        </div>
                        <div className='m-2'>

                            <label className='text-[#a8a9a8]' htmlFor="">Courses</label>
                            <p>{batch.course_name}</p>
                        </div> */}

                        <div className='m-2 w-[100%] flex justify-between text-[12px]'>
                            <div className='basis-[25%]'> <label className='text-[#a8a9a8]' htmlFor="">Session Date</label>
                                <input onChange={(e) => {
                                    setFormdata((prevState: any) => ({
                                        ...prevState,
                                        session_date: e.target.value
                                    }));
                                }}
                                    value={formData.session_date}
                                    type="date" className='border-[1px]  border-black p-1' />
                            </div>
                            <div className='basis-[25%] '>
                                {/* <label className='text-[#a8a9a8]' htmlFor="">Start Time</label> */}
                                <DropdownSingleInputWithCheckbox
                                    topDivStyle={"flex flex-col w-full"}
                                    classNameInput={"w-full"}
                                    label="Start Time"
                                    placeholder="Start Time"
                                    setFunction={setFormdata}
                                    options={
                                        Time
                                    }
                                    name={"start_time"}
                                    value={formData.start_time}
                                    // onBlur={formik.handleBlur}
                                    // error={formik.errors.start_time}
                                    // isTouched={formik.touched.start_time}
                                    mapThrough={"start_time"}
                                    // formik={formik}
                                    disabled={false}
                                    zIndex={"z-30"}
                                />
                            </div>
                            <div className='basis-[25%] '>
                                <DropdownSingleInputWithCheckbox
                                    topDivStyle={"flex flex-col w-full"}
                                    classNameInput={"w-full"}
                                    label="End Time"
                                    placeholder="End Time"
                                    setFunction={setFormdata}
                                    options={
                                        Time.includes(formData.start_time)
                                            ? mapFunction(Time, formData.start_time)
                                            : Time
                                    }
                                    name={"end_time"}
                                    value={formData.end_time}
                                    // onBlur={formik.handleBlur}
                                    // error={formik.errors.start_time}
                                    // isTouched={formik.touched.start_time}
                                    mapThrough={"end_time"}
                                    // formik={formik}
                                    disabled={false}
                                    zIndex={"z-30"}
                                />

                            </div>
                        </div>
                        <div className='py-2 shadow-md rounded'>
                            <div className='p-2 '>

                                <label className='text-[#a8a9a8]' htmlFor="">Topic</label>
                                <p>
                                    <input type="text" onChange={(e) => {
                                        setFormdata((prevState: any) => ({
                                            ...prevState,
                                            topic: e.target.value
                                        }));
                                    }}
                                        value={formData.topic} className='border-[1px] w-[100%] border-black p-1' />
                                </p>
                            </div>
                            <div className='m-2'>
                                <div className="flex items-center justify-between pb-2">
                                    <h1 className="text-[#707070] font-[500]">
                                        Sub Topics (Optional)
                                    </h1>
                                    <button
                                        onClick={AddSubTopic}
                                        disabled={false}
                                        className='bg-[#DF0952] rounded text-white px-3 py-1'
                                    >Add Sub Topic +</button>
                                </div>
                                <div className="space-y-2">
                                    <input
                                        placeholder="Topic 1"
                                        // topDivStyle={"flex flex-col w-full"}
                                        // classNameInput={"w-full"}
                                        // name={"details.subtopics"}
                                        value={firstObject}
                                        className='border-[0.5px] border-[#707070] px-2 py-2 w-[90%]'
                                        // onChange={handleChangeForSubTopics}
                                        onChange={(e) => handleChangeForSubTopics(0, e.target.value)}
                                    />
                                    {formData?.subtopics.map((sub: any, index: number) => {
                                        if (index !== 0) {
                                            return (
                                                <div className="flex items-center relative">
                                                    <input
                                                        placeholder={`Topic ${[index + 1]}`}
                                                        // topDivStyle={"flex flex-col w-full"}
                                                        // classNameInput={"w-full"}
                                                        // name={`details.subtopics`}
                                                        value={sub}
                                                        className='border-[0.5px] border-[#707070] px-2 py-2 w-[90%]'
                                                        onChange={(e) => handleChangeForSubTopics(index, e.target.value)}
                                                    // onBlur={formik.handleBlur}
                                                    // index={`${index}`}
                                                    // check={"details.subtopics"}
                                                    />
                                                    <div
                                                        onClick={() => deleteSubTopic(index)}
                                                        className="select-none bg-[#FF0000] px-2 cursor-pointer rounded-full text-white font-Roboto font-normal flex items-center justify-center absolute -right-0 bottom-2"
                                                    >
                                                        x
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                        {batchData.map((batch:any)=>{
                        return <StudentDetails  formData={formData} setFormdata={setFormdata} studentData={batch} />

                        })}
                        <div className='flex justify-center'>
                        <button onClick={()=>onSubmit()} type="submit" className="bg-[#036AD1] rounded-[27px] text-white p-2 px-6 font-Roboto font-[400]">Create Session</button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default BulkUpdate