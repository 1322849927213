import { SUPABASE_CLIENT } from "../Services/Supabase";

interface ConditionProps{
    conditionKey: string,
    conditionValue:string|number
}

export const FetchData = (table: string, requiredFields: string[] = []) => {
    return SUPABASE_CLIENT.from(table).select(requiredFields.length ?requiredFields.join(","): "*");
}

export const FetchDataById = (table: string, id: string|number) => {
    return FetchData(table).eq("id", id);
}

export const InsertData = async (table: string, data: any) => {
    return await SUPABASE_CLIENT.from(table).insert(data).select();
}

export const UpdateData = async (table: string, data: any, condition: ConditionProps) => {
    const { conditionKey, conditionValue} = condition;
    return await SUPABASE_CLIENT.from(table).update(data).eq(conditionKey, conditionValue).select();
}

export const DeleteData = async (table: string, condition: ConditionProps) => {
    const { conditionKey, conditionValue} = condition;
    return await SUPABASE_CLIENT.from(table).delete().eq(conditionKey, conditionValue);
}